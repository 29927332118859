import { InfoCircleFilled } from '@ant-design/icons';
import { CreateGroupInfoStep, CreateObserverStep, CreatePrePlacement, EnrollmentPreViewStep } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { Enrollment, EnrollmentStatus, EnrollmentTypes, PaginationQuery } from '@shared/models';
import { urlCleaner } from '@shared/utils';
import { Button, Popconfirm, Steps, Tooltip, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const TCreateGroupEnrollmentRequest = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSeachParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [enrollment, setEnrollment] = useState<Enrollment>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [isPreviewMod, setIsPreviewMod] = useState<boolean>(false);
  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [prePlacements, prePlacementsLoading, getPrePlacements] = API.ENROLLMENT.getPrePlacements(enrollment?.id!, { init: false });
  const [observers, observersLoading, getObservers] = API.ENROLLMENT.getObservers({ init: false });
  const [observersQuery, setObserversQuery] = useState<PaginationQuery>();

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('enrollment');
      if (id) {
        setIsLoading(true);
        getEnrollmentById(id);
      }
    }
  }, [searchParams]);

  const getEnrollmentById = async (id: string) => {
    try {
      const { data } = await API.ENROLLMENT.fetchById(id);
      setIsPreviewMod(data.status != EnrollmentStatus.INFO);
      setEnrollmentId(id);
      setIsEditMod(true);
      setEnrollment(data);
      setIsLoading(false);
    } catch (error) {}
  };

  //enrollment oluşturulunca yani sayfa 2'ye geçince öğrenci bilgilerini ve gözlemci bilgilerini çağırıyoruz queryleri set ederek
  //öğrenci listesi gelirken(preplacement) overflow scroll kullandığımız için burada pagination kullanmak tasarımı bozuyor kaldırıldı
  useEffect(() => {
    if (enrollment) {
      setEnrollmentId(enrollment.id);
      getPrePlacements();
      setObserversQuery({ page: 1, limit: 4, enrollmentId: enrollment.id });
    }
  }, [enrollment]);

  useEffect(() => {
    if (observersQuery) {
      getObservers(undefined, observersQuery);
    }
  }, [observersQuery]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onApproval = async () => {
    if (enrollment?.status != EnrollmentStatus.READY_FOR_PLANNING) {
      await API.ENROLLMENT.setAsReadyForPlanning(enrollment?.id!);
    }
    navigate('/crm/enrollments?' + urlCleaner(searchParams));
  };
  const steps = [
    {
      title: 'Ana Group Yöneticisi Oluştur',
      content:
        isLoading && enrollment != undefined && observers == undefined ? (
          <></>
        ) : (
          <CreateGroupInfoStep
            value={enrollment ? observers?.data?.find((o) => o.email == enrollment?.email) : undefined}
            isPreview={isPreviewMod}
            onSave={async (v) => {
              const _enrolment = {
                name: v.name + ' ' + v.surname,
                surname: v.surname,
                email: v.email,
                nationalId: v.nationalId,
                type: EnrollmentTypes.GROUP,
                phone: v.phone,
              } as Enrollment;
              const enrollmentResult = await API.ENROLLMENT.create(_enrolment);
              if (enrollmentResult.ok) {
                const { data } = enrollmentResult;
                setEnrollment(data);
                await API.ENROLLMENT.createObserver({ ...v, enrollmentId: data.id! });
                const layout = searchParams.get('layout');
                const brandId = searchParams.get('brandId');
                const branchId = searchParams.get('branchId');
                setSeachParams({ layout: layout!, brandId: brandId!, branchId: branchId!, enrollment: data.id }, { replace: false });
                next();
              } else {
                notification.error({ message: 'Kayıt oluşturulamadı.' });
              }
            }}
            onUpdate={async (v) => {
              const _enrolment = {
                id: enrollment?.id,
                name: v.name + ' ' + v.surname,
                surname: v.surname,
                email: v.email,
                nationalId: v.nationalId,
                type: EnrollmentTypes.GROUP,
                phone: v.phone,
              } as Enrollment;
              const enrollmentResult = await API.ENROLLMENT.update(_enrolment);
              if (enrollmentResult.ok) {
                const { data } = enrollmentResult;
                setEnrollment(_enrolment);
                await API.ENROLLMENT.updateObserver({ ...v, enrollmentId: _enrolment.id! });
                next();
              } else {
                notification.error({ message: 'Kayıt oluşturulamadı.' });
              }
            }}
          />
        ),
      description: '',
    },
    {
      title: 'Öğrenci Ekle',
      content:
        enrollment && prePlacements ? (
          <CreatePrePlacement
            enrollment={enrollment}
            prePlacements={prePlacements.data!}
            isPreview={isPreviewMod}
            onSave={async (v) => {
              const { ok } = await API.ENROLLMENT.createPrePlacment({ ...v, enrollmentId: enrollment.id! });
              getPrePlacements();
              getObservers(undefined, observersQuery);
              return ok;
            }}
            onDelete={async (id) => {
              const { ok } = await API.ENROLLMENT.deletePrePlacement(id);
              getPrePlacements();
              return ok;
            }}
            onUpdate={async (user) => {
              const { ok } = await API.ENROLLMENT.updatePrePlacement({ ...user, enrollmentId: enrollmentId! });
              getPrePlacements();
              getObservers(undefined, observersQuery);
              return ok;
            }}
          />
        ) : (
          <Spinner loading={true} />
        ),
      description: '',
    },
    {
      title: 'Gözetmen Ekle',
      content:
        enrollment && observers ? (
          <CreateObserverStep
            enrollment={enrollment}
            observers={observers}
            isPreview={isPreviewMod}
            onSave={async (v) => {
              await API.ENROLLMENT.createObserver({ ...v, enrollmentId: enrollment.id });
              getObservers(undefined, observersQuery);
            }}
            onUpdate={async (v) => {
              await API.ENROLLMENT.updateObserver({ ...v, enrollmentId: enrollment.id });
              getObservers(undefined, observersQuery);
            }}
            onDelete={async (id) => {
              await API.ENROLLMENT.deleteObserver(id);
              getObservers(undefined, { ...observersQuery, page: 1 });
            }}
            onPaginationChange={(page) => setObserversQuery((prev) => ({ ...prev, page }))}
          />
        ) : (
          <Spinner loading={true} />
        ),
      description: '',
    },
    {
      title: 'Önizleme/Onay',
      content:
        enrollment && prePlacements && observers ? (
          <EnrollmentPreViewStep
            enrollment={enrollment}
            prePlacements={prePlacements?.data!}
            observers={observers}
            onPaginationChange={(page) => setObserversQuery((prev) => ({ ...prev, page }))}
          />
        ) : (
          <Spinner loading={true} />
        ),
      description: '',
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));

  return (
    <>
      <Steps current={current} items={items} />
      <div className=" pt-3 pl-3 pr-3">
        <div className="flex justify-end">
          <Button
            onClick={() => {
              navigate('/crm/enrollments?' + urlCleaner(searchParams));
            }}
          >
            Kayıt Listesine Dön
          </Button>
        </div>
        {steps[current].content}
      </div>
      {current != 0 && (current != 1 || (enrollment && enrollment.type != EnrollmentTypes.INDIVIDUAL)) ? (
        <div className="flex justify-end mt-5">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Sonraki adım
            </Button>
          )}
          {current === steps.length - 1 && (
            <Tooltip title={prePlacements?.data?.length == 0 ? 'Enrollment must have at least one pre placement' : ''} color="red">
              <Popconfirm
                title={
                  <span className="text-red-500 font-bold">
                    <InfoCircleFilled className="mr-1" style={{ color: 'red' }} />
                    Bu işlem geri alınamaz!
                  </span>
                }
                placement="topRight"
                description={
                  <span>
                    Kaydı tamamladıktan sonra kayıtlı ilgili
                    <div> tekrar bir düzenleme yapamayacaksınız.</div>
                    <div>Onaylıyor musunuz?</div>
                  </span>
                }
                disabled={prePlacements?.data?.length == 0}
                onConfirm={onApproval}
                onCancel={() => {}}
                okText="Evet"
                cancelText="İptal"
                icon={''}
              >
                <Button disabled={prePlacements?.data?.length == 0} type="primary">Kaydı tamamla</Button>
              </Popconfirm>
            </Tooltip>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Geri Dön
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

TCreateGroupEnrollmentRequest.path = '/silinecek/crm/create-group-enrollment-request';
TCreateGroupEnrollmentRequest.title = 'Yeni Grup Kaydı';
//TCreateGroupEnrollmentRequest.group = 'temp-record';
TCreateGroupEnrollmentRequest.roles = [Role.ADMIN];
TCreateGroupEnrollmentRequest.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.CRM];
TCreateGroupEnrollmentRequest.permissions = ['branch.lms.enrollment.create'];
