import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { CascaderOption, EducationLevel, HomeworkTeacherDefinition } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, DatePicker, Drawer, notification, Select, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type AssignDraftDrawerProps = {
  assignDraftDrawerOpen: boolean;
  assignDraftDrawerOnClose: () => void;
  dataDraftCard: HomeworkTeacherDefinition;
};

//TODO düzelt zodları
const AssignDraftDrawerForm = z.object({
  name: z.string({ required_error: 'Ödev başlığı zorunlu alandır' }).min(1, 'Ödev başlığı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Ödev açıklaması zorunlu alandır' }).min(1, 'Ödev açıklaması 1 karekterden fazla olmalı'),
  files: z.array(z.any()).optional(),
});

export const AssignDraftDrawer = ({ assignDraftDrawerOpen, assignDraftDrawerOnClose, dataDraftCard }: AssignDraftDrawerProps) => {
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<string>();
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [levelId, setLevelId] = useState<string>();
  const { RangePicker } = DatePicker;
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getTeacherClassList({ init: true });
  const [classOptions, setClassOptions] = useState([]);
  const [classValue, setClassValue] = useState([]);
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    const filter = classDetail?.data?.map((t) => {
      return { label: t.name!, value: t.id! };
    });
    setClassOptions(filter as never[]);
  }, [classDetail]);

  const form = useForm<Partial<HomeworkTeacherDefinition>, HomeworkTeacherDefinition>(AssignDraftDrawerForm, {});

  useEffect(() => {
    if (dataDraftCard) {
      getTeacherLevel();
      setFiles(dataDraftCard.files.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue({ ...dataDraftCard });
      setAbilityLevelCascaderValue(renderLevelsAsString(dataDraftCard.level));
    }
  }, [dataDraftCard]);

  const getTeacherLevel = async () => {
    try {
      const teacherResult = await API.TEACHER.getTeacherLevels();
      const teacher = teacherResult.data as EducationLevel[];

      const _items: CascaderOption[] = [];

      teacher?.forEach((f) => {
        let fieldOption = _items.find((item) => item.value === f.theme?.type?.field?.id);

        if (!fieldOption) {
          fieldOption = {
            value: f.theme?.type?.field?.id!,
            label: f.theme?.type?.field?.name!,
            children: [],
          };
          _items.push(fieldOption);
        }

        let typeOption = fieldOption.children?.find((item) => item.value === f.theme?.type?.id);

        if (!typeOption) {
          typeOption = {
            value: f.theme?.type?.id!,
            label: f.theme?.type?.name!,
            children: [],
          };
          fieldOption.children?.push(typeOption);
        }

        let themeOption = typeOption.children?.find((item) => item.value === f.theme?.id);

        if (!themeOption) {
          themeOption = {
            value: f.theme?.id!,
            label: f.theme?.name!,
            children: [],
          };
          typeOption.children?.push(themeOption);
        }

        themeOption.children?.push({
          value: f.id!,
          label: f.name!,
        });
      });
    } catch (error) {}
  };

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();

    const response = await API.HOMEWORK.createHomeworkDefinition({ ...result.data, files: files, levelId: levelId! });
    if (response.ok) {
      notification.success({ message: 'Taslak oluşturuldu' });
      assignDraftDrawerOnClose();
    }

    startUpload(false);
  };

  const onAssignHomeworkClasses = async () => {
    const result = form.parse();
    if (result.data.startDate == undefined || result.data.dueDate == undefined) {
      return notification.info({ message: 'Ödev teslim tarihi zorunludur' });
    }
    const response = await API.HOMEWORK.createHomeworkTeacher({ ...result.data, definitionId: result.data.id });
    if (response.ok) {
      notification.success({ message: 'Ödev atama gerçekleştirildi' });
      setClassValue([]);
      setDateValue(undefined);
      assignDraftDrawerOnClose();
    }
  };

  const handleTimeChange = (value, valueFormat) => {
    setDateValue(value);
    form.setValue({
      ...form.value,
      startDate: valueFormat[0],
      dueDate: valueFormat[1],
    });
  };

  const handleChangeSelect = (value) => {
    setClassValue(value);
    form.setValue({ ...form.value, classIds: value });
  };

  return (
    <Drawer forceRender={true} open={assignDraftDrawerOpen} onClose={assignDraftDrawerOnClose} closable={true} title={'Ödev taslağını sınıflara ata'}>
      <div>
        <div className="mb-3 text-md font-bold">{abilityLevelCascaderValue}</div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Sınıf</h4>
        <div className="mt-1">
          <Select mode="multiple" value={classValue} allowClear style={{ width: '100%' }} placeholder="Sınıf seçiniz" onChange={handleChangeSelect} options={classOptions} />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="mt-3">Ödev Başlığı</h4>
        <div className="mt-1">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} />
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev açıklama</h4>
        <div className="mt-1">
          <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 2, maxRows: 5 }} {...form.input('description')} />
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev teslim tarihi</h4>
        <div className="mt-1">
          <RangePicker value={dateValue} className="w-full" onChange={(value, valueFormat) => handleTimeChange(value, valueFormat)} />
        </div>
      </div>

      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            onChangeLoading={(b) => {
              //TODO upload işlemlerinde loading çalışır olamlı
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>
      <Button className="w-full mt-5" type="primary" onClick={() => onAssignHomeworkClasses()}>
        Ödev ata
      </Button>
    </Drawer>
  );
};

AssignDraftDrawer.roles = [Role.ADMIN];
