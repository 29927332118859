import { PlusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown, DeleteAlert } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { Branch, CreateBranchModel, PaginationQuery } from '@shared/models';
import { sortFields } from '@shared/utils';
import { Button, Input, InputRef, Modal, Table, TableColumnType, TableProps, notification } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import { TbFilter } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { BranchCreateUser } from './BranchCreateUser';

export const Branchs = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, loading, getAllBranch] = API.BRANCH.getAllBranch({ init: false });
  const [open, setOpen] = useState(false);
  const [branchId, setBranchId] = useState('');
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, orders: ['+name'] });
  const searchInput = useRef<InputRef>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deletedBranch, setDeletedBranch] = useState<CreateBranchModel>();

  useEffect(() => {
    if (paginationQuery) {
      getAllBranch(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  const onCancelModal = () => {
    setOpen(false);
  };

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={() => navigate('/brand_manager/create_branch' + location.search)}>
        Yeni Şube Oluştur
      </Button>
    </div>
  );

  const getColumnSearchProps = (): TableColumnType<CreateBranchModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<TbFilter />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <TbFilter style={{ color: filtered ? '#1677ff' : undefined, fontSize: '120%' }} />,
  });

  const columns: TableProps<Branch>['columns'] = [
    {
      title: 'Şube',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      className: 'text-center',
      ...getColumnSearchProps(),
      render: (text, _branch) => {
        return (
          <a key={_branch.id} onClick={() => {}}>
            {text}
          </a>
        );
      },
    },
    {
      title: 'Tip',
      key: 'type',
      className: 'text-center',
      render: (_, { type }) => <span>{i18n.t('app.branchType.' + type)}</span>,
    },
    {
      title: 'Telefon numarası',
      key: 'phone',
      className: 'text-center',
      render: (_, { phone }) => (
        <span>
          {phone?.code}
          {phone?.number}
        </span>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      className: 'text-center',
      render: (_, { address }) => {
        return (
          <span>
            {address?.country?.name} / {address?.city?.name}
          </span>
        );
      },
    },
    {
      title: 'Aksiyon',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          isDelete={true}
          isEditUser={true}
          onEditUser={({ id }) => {
            setBranchId(id);
            setOpen(true);
          }}
          onEdit={({ id }) => {
            navigate('/brand_manager/create_branch' + location.search + '&branch=' + id);
          }}
          onDelete={async ({ id }) => {
            const selectedBranch = result?.data?.find((branch) => branch?.id === id);
            if (selectedBranch) {
              setDeletedBranch(selectedBranch);
              setIsModalOpen(true);
            }
          }}
        />
        /*TODO bu buttonlların işlevselliği olmalı
                <div className="flex justify-center align-middle  gap-3">
                    <FormOutlined style={{ fontSize: 16 }} />
                    <Popconfirm
                        title="Delete the brand"
                        description="Are you sure to delete this brand?"
                        onConfirm={async () => {
                            //TODO branch delete gelmeli
                            const result = await API.BRANCH.delete(record.id!);
                            if (result.ok) {
                                notification.success({message:"The branch was deleted"});
                            } else {
                                notification.error({message:"The branch wasn't deleted"})
                            }

                            getAllBranch();
                        }}
                        onCancel={() => { }}
                        okText="Evet"
                        cancelText="İptal"
                    >
                        <MinusCircleOutlined style={{ fontSize: 16 }} />
                    </Popconfirm>
                </div>*/
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={result?.data}
        rowKey={'id'}
        bordered
        title={() => title}
        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, total: result?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination, filters, sorter) => {
          const { current, pageSize } = pagination;
          const newQuery = {
            ...paginationQuery,
            page: current,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            limit: pageSize,
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });
          setPaginationQuery(newQuery);
        }}
      />

      <BranchCreateUser branchId={branchId} open={open} onCancelModal={onCancelModal} />
      <Modal
        width={900}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setDeletedBranch(undefined);
        }}
        footer={null}
        title={deletedBranch?.name + ' Şubesini Kalıcı Olarak Silmek İstiyorum'}
      >
        <DeleteAlert
          name={deletedBranch?.name}
          description={
            <>
              <div>
                - Silinen şube verileri tekrar yerine <u>getirilemez</u>.
              </div>
              <div>
                - Bu işlemi yapmanız gerektiğinden <u>emin olun</u>.
              </div>
            </>
          }
          onCancel={() => {
            setIsModalOpen(false);
            setDeletedBranch(undefined);
          }}
          onDelete={async () => {
            const result = await API.BRANCH.delete(deletedBranch?.id!);
            if (result.ok) {
              notification.success({ message: 'Şube başarıyla silindi' });
            }
            getAllBranch(undefined, { ...paginationQuery, page: 1 });
            setIsModalOpen(false);
            setDeletedBranch(undefined);
          }}
        />
      </Modal>
    </>
  );
};

Branchs.path = '/brand_manager/branchs';
Branchs.title = 'Şubeleri Yönet';
Branchs.group = 'brand_manager_branch';
Branchs.roles = [Role.USER];
Branchs.layouts = [LayoutEnums.BRANDMANAGER];
Branchs.permissions = ['branch.view'];
