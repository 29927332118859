import { DeleteTwoTone } from '@ant-design/icons';
import { BrandOrBranch } from '@shared/constants';
import { BranchCalendarModel, BrandCalendarModel } from '@shared/models';
import { Button, Popconfirm, Table, TableProps, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';

type HolidayCalendarProps = {
  holidayCalendar: BrandCalendarModel[] | BranchCalendarModel[];
  onDeleteHoliday: (id: string) => void;
  brandOrBranch: BrandOrBranch;
  loading: boolean;
};

export const HolidayTable = ({ holidayCalendar, onDeleteHoliday, brandOrBranch, loading }: HolidayCalendarProps) => {
  const columns: TableProps<BrandCalendarModel | BranchCalendarModel>['columns'] = [
    {
      key: 'date',
      title: 'Tarih',
      align: 'center',

      render: ({ date }) => dayjs(date).format('D MMMM YYYY dddd'),
    },
    {
      key: 'description',
      title: 'Açıklama',
      align: 'center',
      render: ({ name, description }) => (
        <Tooltip title={description} placement="top">
          <div className="h-full w-full">{name}</div>
        </Tooltip>
      ),
    },
    {
      key: 'isHoliday',
      title: 'Gün Tatil Mi',
      align: 'center',
      render: ({ isHoliday }) => (isHoliday ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>),
    },
    brandOrBranch === BrandOrBranch.BRAND
      ? {
          key: 'brand',
          align: 'center',
          render: ({ isSystemEntry }) => isSystemEntry && <Tag color="default">Sistem</Tag>,
        }
      : {
          key: 'brand',
          align: 'center',
          render: ({ isSystemEntry, isBrandEntry }) => (
            <>
              {isSystemEntry && <Tag color="default">Sistem</Tag>}
              {isBrandEntry && <Tag color="blue">Marka</Tag>}
            </>
          ),
        },
    {
      title: 'Eylemler',
      key: 'id',
      align: 'center',
      render: (record) => (
        <Popconfirm
          title="Tatili İptal Et"
          description="Bu tatili iptal etmek istediğinize emin misiniz?"
          okText="Evet"
          cancelText="İptal"
          onConfirm={() => onDeleteHoliday(record.id)}
        >
          <Button type="text" icon={<DeleteTwoTone />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <Table
      loading={loading}
      rowKey={(record) => record.id}
      dataSource={holidayCalendar}
      columns={columns}
      bordered
      pagination={{ showSizeChanger: true, showPrevNextJumpers: true, position: ['bottomCenter'] }}
    />
  );
};
