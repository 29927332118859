import { NoteType } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassCalendarModel, ClassDetailModel } from '@shared/models';
import { Dayjs } from 'dayjs';
import { ClassInfo, ClassTimeline } from '../class';

type TeacherClassDashboardProps = {
  classDetail: ClassDetailModel | undefined;
  createClassNote: (note: string, noteType: NoteType) => void;
  calendarData: ClassCalendarModel[] | undefined;
  getClassDetail: () => void;
  onRefresh: () => void;
  currentMonth: Dayjs;
  onChangeCurrentMonth: (date: Dayjs) => void;
  onRemoveStudent?: (classId: string, studentId: string) => void;
};

export const TeacherClassDashboard = ({
  classDetail,
  createClassNote,
  calendarData,
  getClassDetail,
  currentMonth,
  onChangeCurrentMonth,
  onRefresh,
  onRemoveStudent,
}: TeacherClassDashboardProps) => {
  useAccessableSeachParams();

  return (
    <div className="grid grid-cols-3 gap-x-2">
      <div className="col-span-2" style={{ height: 'calc(100vh - 227px)' }}>
        <ClassTimeline classDetail={classDetail} notes={classDetail?.notes} createClassNote={createClassNote} isTeacher={true} />
      </div>
      <div className="col-span-1 h-full" style={{ height: 'calc(100vh - 227px)' }}>
        <ClassInfo
          classDetail={classDetail}
          isSettings={false}
          calendarData={calendarData}
          getClassDetail={() => getClassDetail()}
          isTeacherLayout={true}
          onRefresh={onRefresh}
          currentMonth={currentMonth}
          onChangeCurrentMonth={onChangeCurrentMonth}
          onRemoveStudent={onRemoveStudent}
        />
      </div>
    </div>
  );
};