import { Button, Drawer, Form, Input, InputNumber, notification, Pagination, Popconfirm, Tooltip } from 'antd';
import { DatePicker } from 'antd';
import { LayoutEnums, Role } from '@shared/constants';
import TextArea from 'antd/lib/input/TextArea';
import { CalendarOutlined, PaperClipOutlined, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import Search, { SearchProps } from 'antd/es/input/Search';
import { ReactElement, useEffect, useState } from 'react';
import { KanbanCard, KanbanCardProps } from '@platform/platform_components/kanban_view/KanbanCard';
import { HomeworkTeacherDefinition, PaginationQuery } from '@shared/models';
import { CreateDraftViewDrawer } from './CreateDraftViewDrawer';
import { ActionDropdown } from '@shared/components';
import { convertDate } from '@shared/utils';
import { AssignDraftDrawer } from './AssignDraftDrawer';
import * as API from '@shared/api';
import { TeacherClassFilter } from './TeacherClassFilter';

type DraftViewDrawerProps = {
  draftDrawerOpen: boolean;
  draftDrawerOnClose: () => void;
  onRenderColumn: () => void;
};

export const DraftViewDrawer = ({ draftDrawerOpen, draftDrawerOnClose, onRenderColumn }: DraftViewDrawerProps) => {
  const [draftDrawerCreateOpen, setDraftDrawerCreateOpen] = useState<boolean>(false);
  const [assignDraftDrawerOpen, setAssignDraftDrawerOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dataDraftCard, setDataDraftCard] = useState<HomeworkTeacherDefinition>();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, levelIds: [], name: '' });
  const [dataDraftCards, classListLoading, getPaginationDefinitions] = API.HOMEWORK.getPaginationDefinition({ init: false });
  const [params, setParams] = useState<any>();

  useEffect(() => {
    if (draftDrawerOpen) {
      getPaginationDefinitions(undefined, paginationQuery);
    }
  }, [paginationQuery, draftDrawerOpen]);

  useEffect(() => {
    getPaginationDefinitions(undefined, params);
  }, [params]);

  const draftDrawerCreateOnClose = () => {
    getPaginationDefinitions(undefined, paginationQuery);
    setDraftDrawerCreateOpen(false);
  };
  const assignDraftDrawerOnClose = () => {
    getPaginationDefinitions(undefined, paginationQuery);
    onRenderColumn();
    setAssignDraftDrawerOpen(false);
    setDraftDrawerCreateOpen(false);
  };

  const onEditDraft = (draft) => {
    setDraftDrawerCreateOpen(true);
    setDataDraftCard(draft);
    setIsUpdate(true);
  };

  const onDeleteDraft = async (draft) => {
    const response = await API.HOMEWORK.deleteHomeworkDefinition(draft.id);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      setPaginationQuery((prev) => ({ ...prev, page: 1 }));
    }
  };

  const onAssignHomeworkClasses = (draft) => {
    setAssignDraftDrawerOpen(true);
    setDataDraftCard(draft);
  };

  const initialFilters = {
    name: '',
  };
  const [searchInput, setSearchInput] = useState(initialFilters.name);
  const [filters, setFilters] = useState(initialFilters);

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    updateFilter('name', value);
  };

  const updateFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const inputControl = () => {
    //TODO Semih abi input onChange'de her karakter değiştiğinde backende istek atmasını engelleyen düzenleme yaptığında burası kaldırılacak
    if (
      searchInput === '' &&
      Object.values(filters)
        .filter((filter) => Array.isArray(filter))
        .every((filter: any) => filter.length === 0)
    ) {
      setFilters(initialFilters);
    }
  };

  useEffect(() => {
    setParams(filters);
  }, [filters]);

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 4, name: '', levelIds: [] });
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
      limit: 4,
    }));
  };

  return (
    <div>
      <Drawer width={'450px'} forceRender={true} open={draftDrawerOpen} onClose={draftDrawerOnClose} closable={true} title={'Kütüphanem'}>
        <div className="flex flex-col" style={{ height: 'calc(100vh - 160px)' }}>
          <div>
            <Button
              className="w-full text-blue-500"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setDraftDrawerCreateOpen(true);
                setIsUpdate(false);
              }}
            >
              Yeni ödev taslağı oluştur
            </Button>
          </div>
          <div className="mt-4">
            <Search
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-full"
              placeholder="Ara"
              onSearch={onSearch}
              onBlur={inputControl}
              enterButton
            />
          </div>

          <div className="mt-3">
            <TeacherClassFilter onFilter={onFilter} filters={paginationQuery} />
          </div>
          <div className="mt-3">
            {dataDraftCards?.data?.map((draft) => (
              <div className="mt-2" key={draft.id}>
                <KanbanCard
                  key={draft.id}
                  title={draft.name}
                  content={<Tooltip title={draft.description}>{draft.description.length > 50 ? `${draft.description.slice(0, 50)}...` : draft.description}</Tooltip>}
                  action={
                    <ActionDropdown
                      onAssignHomeworkClasses={() => onAssignHomeworkClasses(draft)}
                      onEditDraft={() => onEditDraft(draft)}
                      onDeleteDraft={() => onDeleteDraft(draft)}
                      isAssignHomeworkClasses={true}
                      isDeleteDraft={true}
                      isEditDraft={true}
                      isDelete={false}
                      isEdit={false}
                      data={{}}
                    />
                  }
                  footer={
                    <div className="flex justify-between">
                      <div>
                        <CalendarOutlined />
                        <span className="ml-1">{convertDate(draft.createdAt)}</span>
                      </div>
                      <div>
                        <PaperClipOutlined />
                        <span className="ml-2">{draft.files.length}</span>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-auto">
          <Pagination
            current={paginationQuery.page}
            pageSize={paginationQuery.limit}
            total={dataDraftCards?.count ?? 0}
            className="mt-5 text-center"
            onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))}
          />
        </div>
      </Drawer>

      <CreateDraftViewDrawer isUpdate={isUpdate} dataDraftCard={dataDraftCard!} draftDrawerCreateOpen={draftDrawerCreateOpen} draftDrawerCreateOnClose={draftDrawerCreateOnClose} />
      <AssignDraftDrawer assignDraftDrawerOpen={assignDraftDrawerOpen} assignDraftDrawerOnClose={assignDraftDrawerOnClose} dataDraftCard={dataDraftCard!} />
    </div>
  );
};

DraftViewDrawer.roles = [Role.ADMIN];
