import { CheckCircleOutlined, DownloadOutlined, FileDoneOutlined, FileTextOutlined, ImportOutlined, StopOutlined } from '@ant-design/icons';
import { HomeworkEditDrawer, SubmitedStudentDrawerOpen } from '@platform/platform_components';
import * as API from '@shared/api';
import { ActionDropdown, FileDownload } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { HomeworkTeacherDetail, StudentHomeworkProps } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Card, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const HomeWorkSubmissionDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [homeworkDetail, setHomeworkDetail] = useState<HomeworkTeacherDetail>();
  const [submitedStudentDrawerOpen, setSubmitedStudentDrawerOpen] = useState<boolean>(false);
  const [submitedStudentData, setSubmitedStudentData] = useState<StudentHomeworkProps>();
  const [homeworkDrawerOpen, setHomeworkDrawerOpen] = useState<boolean>(false);

  const homeworkId = searchParams.get('class_homework_id');
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.size > 0 && homeworkId) {
      getHomeworkDetail(homeworkId);
    }
  }, [searchParams]);

  const getHomeworkDetail = async (classId) => {
    const response = await API.HOMEWORK.listClassHomeworkDetail(classId);
    if (response.ok) {
      setHomeworkDetail(response.data);
    }
  };

  const submitedStudentDrawerOnClose = () => {
    setSubmitedStudentDrawerOpen(false);
    getHomeworkDetail(homeworkId);
  };

  const homeworkDrawerOnClose = () => {
    getHomeworkDetail(homeworkId);
    setHomeworkDrawerOpen(false);
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return <FileTextOutlined style={{ color: '#52C41A' }} />;
      case 'WAITING':
        return <FileDoneOutlined style={{ color: '#faad14' }} />;
      case 'EXPIRED':
        return <FileDoneOutlined style={{ color: '#1677FF' }} />;
      default:
        return null;
    }
  };
  const onDeleteHomework = async (homeworkDetail) => {
    const response = await API.HOMEWORK.deleteHomeworkTeacher(homeworkDetail.classHomework.id);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      const trimmedUrl = location.search.split('&class_homework_id')[0];
      navigate('/' + LayoutEnums.TEACHER + '/class-homework' + trimmedUrl);
    }
  };

  return (
    <div style={{ margin: '10px 100px 100px' }}>
      <div>
        <Card className="p-4" bodyStyle={{ padding: '0' }}>
          <div className="text-lg font-bold flex justify-between">
            <div>{homeworkDetail?.classHomework?.class?.name}</div>
            <div className="flex items-center mt-auto">
              <ActionDropdown
                isEditAssignment={true}
                onEditAssignment={() => {
                  setHomeworkDrawerOpen(true);
                }}
                isDeleteAssignment={true}
                onDeleteAssignment={() => onDeleteHomework(homeworkDetail)}
                isDelete={false}
                isEdit={false}
                data={{}}
              />
            </div>
          </div>
          <div>{renderLevelsAsString(homeworkDetail?.classHomework?.class.level)}</div>
        </Card>
      </div>
      <div className="mt-2 ">
        <Card className="p-4" bodyStyle={{ padding: '0' }}>
          <div>
            <div>
              <span className="text-lg font-bold mr-2"> {homeworkDetail?.classHomework?.name}</span>

              {getStatusIcon(homeworkDetail?.classHomework?.status!)}
              <span className="ml-1">{i18n.t('app.classHomeworkStatus.' + homeworkDetail?.classHomework?.status)}</span>
            </div>
          </div>
          <div className="italic text-sm opacity-45">
            {convertDate(homeworkDetail?.classHomework?.startDate)} - {convertDate(homeworkDetail?.classHomework?.dueDate)}
          </div>
          <div className="mt-3 opacity-45">{homeworkDetail?.classHomework?.description}</div>
          <div>
            {homeworkDetail?.classHomework?.files.map((file, index) => (
              <div key={index}>
                <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
              </div>
            ))}
          </div>
        </Card>
      </div>
      <div className="flex mt-2">
        <div className="w-1/2 mr-1">
          <Card className="p-4 " bodyStyle={{ padding: '0' }}>
            <div className="flex justify-between">
              <div>
                <CheckCircleOutlined style={{ color: 'green' }} />
                <span className="ml-3">Ödevi Teslim Edenler</span>
              </div>
              <div>{homeworkDetail?.submitedStudents?.length}</div>
            </div>
          </Card>
          <div className="mt-3" style={{ height: '60vh', overflowY: 'auto' }}>
            {homeworkDetail?.submitedStudents?.map((submitedStudent, index) => (
              <Card className="p-4" bodyStyle={{ padding: '0' }}>
                <Card className="p-4 bg-gray-50" bodyStyle={{ padding: '0' }}>
                  <div className="flex justify-between">
                    <div key={index} className="flex justify-between">
                      <div>
                        <div>
                          {submitedStudent.student?.name} {submitedStudent.student?.surname}
                        </div>
                        <div className="opacity-45">{convertDate(submitedStudent.student?.createdAt)}</div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <span className="mr-2">{submitedStudent.isEvaluated ? <span>{submitedStudent.score}</span> : <span>Değerlendirilmedi</span>}</span>
                        <ImportOutlined
                          onClick={() => {
                            setSubmitedStudentDrawerOpen(true);
                            setSubmitedStudentData(submitedStudent);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </Card>
            ))}
          </div>
        </div>

        <div className="w-1/2 ml-1">
          <Card className="p-4" bodyStyle={{ padding: '0' }}>
            <div className="flex justify-between">
              <div>
                <StopOutlined style={{ color: 'red' }} /> <span className="ml-3">Ödevi Teslim Etmeyenler</span>
              </div>
              <div>{homeworkDetail?.notSubmitedStudents?.length}</div>
            </div>
          </Card>
          <div className="mt-3">
            <Card className="p-4" bodyStyle={{ padding: '0' }}>
              {homeworkDetail?.notSubmitedStudents?.map((student, index) => (
                <Card className="p-4 bg-gray-50" bodyStyle={{ padding: '0' }}>
                  <div key={index}>
                    <div className="flex justify-between">
                      {student.name} {student.surname}
                    </div>
                  </div>
                </Card>
              ))}
            </Card>
          </div>
        </div>
      </div>
      <SubmitedStudentDrawerOpen
        submitedStudentDrawerOpen={submitedStudentDrawerOpen}
        submitedStudentDrawerOnClose={submitedStudentDrawerOnClose}
        submitedStudentData={submitedStudentData!}
        homeworkDetail={homeworkDetail!}
      />
      <HomeworkEditDrawer homeworkDrawerOpen={homeworkDrawerOpen} homeworkDrawerOnClose={homeworkDrawerOnClose} homeworkData={homeworkDetail?.classHomework!} />
    </div>
  );
};
HomeWorkSubmissionDetail.roles = [Role.ADMIN];
HomeWorkSubmissionDetail.path = '/' + LayoutEnums.TEACHER + '/homework-detail';
HomeWorkSubmissionDetail.layouts = [LayoutEnums.TEACHER];
