import { DownOutlined, MinusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassStatus } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { CascaderOption, EducationLevel, HomeworkTeacherDefinition, PaginationQuery } from '@shared/models';
import { Badge, Button, Cascader, Space } from 'antd';
import { useEffect, useState } from 'react';
import { TbFilterX } from 'react-icons/tb';

type TeacherClassFilterProps = {
  onFilter: (key: any, value: any, reset?: boolean) => void;
  filters: PaginationQuery;
};

export const TeacherClassFilter = ({ onFilter, filters }: TeacherClassFilterProps) => {
  useAccessableSeachParams();
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [cascaderKey, setCascaderKey] = useState(Date.now());
  const anyFilterActive = Object.values(filters).some((filter) => Array.isArray(filter) && filter.length > 0);
  const [selectedKur, setSelectedKur] = useState();

  useEffect(() => {
    getAbilityLevelOptions();
  }, []);

  const getAbilityLevelOptions = async () => {
    const teacherResult = await API.TEACHER.getTeacherLevels();
    const teacher = teacherResult.data as EducationLevel[];
    const _items: CascaderOption[] = [];

    teacher?.forEach((f) => {
      let fieldOption = _items.find((item) => item.value === f.theme?.type?.field?.id);

      if (!fieldOption) {
        fieldOption = {
          value: f.theme?.type?.field?.id!,
          label: f.theme?.type?.field?.name!,
          children: [],
        };
        _items.push(fieldOption);
      }

      let typeOption = fieldOption.children?.find((item) => item.value === f.theme?.type?.id);

      if (!typeOption) {
        typeOption = {
          value: f.theme?.type?.id!,
          label: f.theme?.type?.name!,
          children: [],
        };
        fieldOption.children?.push(typeOption);
      }

      let themeOption = typeOption.children?.find((item) => item.value === f.theme?.id);

      if (!themeOption) {
        themeOption = {
          value: f.theme?.id!,
          label: f.theme?.name!,
          children: [],
        };
        typeOption.children?.push(themeOption);
      }

      themeOption.children?.push({
        value: f.id!,
        label: f.name!,
      });
    });

    setAbilityLevelOptions(_items);
  };

  const onKurChange = (value) => {
    //MultiCascader'dan Tüm ağacın(field,theme,type,level) id'leri geliyor, levelid 3.index olduğu için onları tutuyoruz.
    const selectedLevels = value.map((v) => v[3]);
    onFilter('levelIds', selectedLevels);
  };
  return (
    <div className="flex ml-1 mt-3 gap-4 w-1/3">
      <div className="flex mt-1 w-1/2 gap-2 justify-around">
        <Badge dot={filters.levelIds!.length > 0} className={filters.levelIds!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            showCheckedStrategy={Cascader.SHOW_CHILD}
            key={cascaderKey}
            value={selectedKur}
            options={abilityLevelOptions}
            expandTrigger="hover"
            multiple
            onChange={(value) => onKurChange(value)}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Kur
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>
      </div>

      {anyFilterActive && (
        <div className="ml-5">
          <Button
            onClick={() => {
              setCascaderKey(Date.now()); //Cascader'ı butonla resetlemek için key'ini değiştiriyoruz.
              onFilter(null, null, true);
            }}
            type="text"
            size="small"
            icon={<TbFilterX />}
          >
            Filtreyi Kaldır
          </Button>
        </div>
      )}
    </div>
  );
};
