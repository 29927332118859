import * as API from '@shared/api';
import { useForm } from '@shared/hooks';
import { Button, Card, Divider, Form, Image, Input, notification } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const ForgotPasswordFormZod = z.object({
  email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçerli email giriniz'),
});

export const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const form = useForm<Partial<{ email: string }>>(ForgotPasswordFormZod, {});

  useEffect(() => {
    if (searchParams.get('email')) {
      form.setInitialValue({ email: searchParams.get('email')!.toString() });
    }
  }, [searchParams]);
  const sendPasswordRequest = async () => {
    const result = form.parse();

    if (result.success) {
      const response = await API.AUTH.resetPasswordRequest(result.data);
      if (response.ok) {
        notification.success({ message: 'Aktivasyon kodu e-posta adresinize gönderildi!' });
        form.reset();
      }
    } else {
      form.setAllTouched();
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300">
      <Card className="flex justify-center items-center h-screen" style={{ height: '650px', width: '600px' }}>
        <div className="flex justify-center items-center">
          <Image preview={false} src={require('../../../statics/americanlife.png')} />
        </div>
        <div
          className="flex flex-col gap-0.5 mt-4"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendPasswordRequest();
            }
          }}
        >
          <div className="text-center m-3 flex flex-col justify-center items-center"></div>

          <Form layout="vertical">
            <Form.Item {...form.formItem('email')} label={'Parola sıfırlama talebi için e-posta adresini gir'}>
              <Input placeholder="example@domain.com" {...form.input('email')} />
            </Form.Item>
            <Button block type="primary" className="my-3" onClick={sendPasswordRequest}>
              Parola sıfırlama kodu gönder
            </Button>
          </Form>

          <Divider plain>
            <span className="text-[#999999]">ya da</span>
          </Divider>
          <Button type="link" block onClick={() => navigate('/')}>
            Giriş yap
          </Button>
        </div>
      </Card>
    </div>
  );
};

ForgotPassword.path = 'account/forgot-password';
