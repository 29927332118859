import { CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, FieldTimeOutlined, FileDoneOutlined, PaperClipOutlined, SyncOutlined } from '@ant-design/icons';
import { ActiveHomeworkDetailDrawer, EvulatedHomeworkDetailDrawer, LoadedHomeworkDetailDrawer, WaitingHomeworkDetailDrawer } from '@platform/platform_components';
import { KanbanColumn, KanbanColumnTitle } from '@platform/platform_components/kanban_view/KanbanColumn';
import * as API from '@shared/api';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { PaginationQuery, StudentHomeworkProps } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Button } from 'antd';
import { useEffect, useState } from 'react';

export const StudentHomeWork = () => {
  useAccessableSeachParams();

  const [title, setTitle] = useState<string>('');
  const [activeHomeoworkDetailDrawerOpen, setActiveHomeworkDetailDrawerOpen] = useState<boolean>(false);
  const [waitingHomeoworkDetailDrawerOpen, setWaitingHomeworkDetailDrawerOpen] = useState<boolean>(false);
  const [evulatedHomeoworkDetailDrawerOpen, setEvulatedHomeworkDetailDrawerOpen] = useState<boolean>(false);
  const [loadedHomeoworkDetailDrawerOpen, setLoadedHomeworkDetailDrawerOpen] = useState<boolean>(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 3, levelIds: [] });
  const [homework, setHomework] = useState<StudentHomeworkProps>();
  const [activeHomeworks, isActiveHomeworksLoading, getActiveHomeworks] = API.HOMEWORK.paginateClassHomeworkByRequestedStudent({
    init: true,
    initParams: { status: 'ACTIVE', ...paginationQuery },
  });
  const [plannedHomeworks, isPlannedHomeworksLoading, getPlannedHomeworks] = API.HOMEWORK.paginateClassHomeworkByRequestedStudent({
    init: true,
    initParams: { status: 'WAITING', ...paginationQuery },
  });
  const [submitedHomeworks, isSubmitedHomeworksLoading, getSubmitedHomeworks] = API.HOMEWORK.paginateSubmitedHomeworkByRequestedStudent({
    init: true,
    initParams: { isEvaluated: false, ...paginationQuery },
  });
  const [evaluatedHomeworks, isEvaluatedHomeworksLoading, getEvaluatedHomeworks] = API.HOMEWORK.paginateSubmitedHomeworkByRequestedStudent({
    init: true,
    initParams: { isEvaluated: true, ...paginationQuery },
  });

  const onCloseActiveHomeworkDetailDrawer = () => {
    onRenderColumn();
    setActiveHomeworkDetailDrawerOpen(false);
  };
  const onCloseWaitingHomeworkDetailDrawer = () => {
    onRenderColumn();
    setWaitingHomeworkDetailDrawerOpen(false);
  };
  const onCloseEvulatedHomeworkDetailDrawer = () => {
    onRenderColumn();
    setEvulatedHomeworkDetailDrawerOpen(false);
  };
  const onCloseLoadedHomeworkDetailDrawer = () => {
    onRenderColumn();
    setLoadedHomeworkDetailDrawerOpen(false);
  };

  const onRenderColumn = () => {
    getActiveHomeworks(undefined, { ...paginationQuery, status: 'ACTIVE' });
    getPlannedHomeworks(undefined, { ...paginationQuery, status: 'WAITING' });
    getSubmitedHomeworks(undefined, { ...paginationQuery, isEvaluated: false });
    getEvaluatedHomeworks(undefined, { ...paginationQuery, isEvaluated: true });
  };
  const onFilterLoadMore = (title, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 3, name: '', levelIds: [] });
      return;
    }
    setTitle(title);
    setPaginationQuery((prev) => ({
      ...prev,
      page: 1,
      limit: paginationQuery.limit! + 3,
    }));
  };

  const homeworkMap = {
    Aktif: { status: 'ACTIVE', fetch: getActiveHomeworks },
    Planlanmış: { status: 'WAITING', fetch: getPlannedHomeworks },
    Yüklendi: { status: 'EXPIRED', fetch: getEvaluatedHomeworks },
    Değerlendirildi: { status: 'EXPIRED', fetch: getSubmitedHomeworks },
  };

  useEffect(() => {
    const homeworkConfig = homeworkMap[title];
    if (homeworkConfig) {
      homeworkConfig.fetch(undefined, { ...paginationQuery, status: homeworkConfig.status });
    }
  }, [title]);

  return (
    <div className="container mx-auto pt-1">
      <div className="grid grid-cols-4 gap-3 mt-2">
        <KanbanColumn
          title={<KanbanColumnTitle title="Aktif" count={activeHomeworks?.count} icon={<SyncOutlined style={{ color: '#1677FF' }} />} />}
          isLoading={isActiveHomeworksLoading}
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          loadMore={
            <div>
              {paginationQuery.limit! < activeHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Aktif')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={activeHomeworks?.data?.map((homework) => {
            return {
              title: homework.name,
              content: (
                <>
                  <div>
                    <small className="text-gray-500">{renderLevelsAsString(homework?.class?.level)}</small>
                  </div>
                  <div>
                    <small className="text-gray-500">{homework?.name}</small>
                  </div>
                </>
              ),
              action: (
                <FileDoneOutlined
                  onClick={() => {
                    setHomework({
                      classHomework: homework,
                    });
                    setActiveHomeworkDetailDrawerOpen(true);
                  }}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework?.startDate)} - {convertDate(homework?.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-1">{homework?.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          title={<KanbanColumnTitle title="Yüklendi" count={submitedHomeworks?.count} icon={<FieldTimeOutlined style={{ color: '#FAAD14' }} />} />}
          icon={<FieldTimeOutlined style={{ color: '#FAAD14' }} />}
          isLoading={isSubmitedHomeworksLoading}
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          loadMore={
            <div>
              {paginationQuery.limit! < submitedHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Yüklendi')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={submitedHomeworks?.data?.map((homework) => {
            return {
              title: homework.classHomework.name,
              content: (
                <>
                  <div>
                    <small className="text-gray-500">{renderLevelsAsString(homework.classHomework.class?.level)}</small>
                  </div>
                  <div>
                    <small className="text-gray-500">{homework.classHomework.class?.name}</small>
                  </div>
                </>
              ),
              action: (
                <FileDoneOutlined
                  onClick={() => {
                    setHomework(homework);
                    setLoadedHomeworkDetailDrawerOpen(true);
                  }}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.classHomework.startDate)} - {convertDate(homework.classHomework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-1">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          title={<KanbanColumnTitle title="Değerlendirildi" count={evaluatedHomeworks?.count} icon={<CheckCircleOutlined style={{ color: '#52C41A' }} />} />}
          isLoading={isEvaluatedHomeworksLoading}
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          loadMore={
            <div>
              {paginationQuery.limit! < evaluatedHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Değerlendirildi')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={evaluatedHomeworks?.data?.map((homework) => {
            return {
              title: homework.classHomework.name,
              content: (
                <>
                  <div>
                    <small className="text-gray-500">{renderLevelsAsString(homework.classHomework.class?.level)}</small>
                  </div>
                  <div>
                    <small className="text-gray-500">{homework.classHomework.class?.name}</small>
                  </div>
                </>
              ),
              action: (
                <FileDoneOutlined
                  onClick={() => {
                    setHomework(homework);
                    setEvulatedHomeworkDetailDrawerOpen(true);
                  }}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.classHomework.startDate)} - {convertDate(homework.classHomework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-1">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          title={<KanbanColumnTitle title="Planlanmış" count={plannedHomeworks?.count} icon={<ClockCircleOutlined style={{ color: 'gray' }} />} />}
          isLoading={isPlannedHomeworksLoading}
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          loadMore={
            <div>
              {paginationQuery.limit! < plannedHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Planlanmış')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={plannedHomeworks?.data?.map((homework) => {
            return {
              title: homework.name,
              content: (
                <>
                  <div>
                    <small className="text-gray-500">{renderLevelsAsString(homework?.class?.level)}</small>
                  </div>
                  <div>
                    <small className="text-gray-500">{homework?.name}</small>
                  </div>
                </>
              ),
              action: (
                <FileDoneOutlined
                  onClick={() => {
                    setHomework({ classHomework: homework! });
                    setWaitingHomeworkDetailDrawerOpen(true);
                  }}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework?.startDate)} - {convertDate(homework?.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-1">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <ActiveHomeworkDetailDrawer open={activeHomeoworkDetailDrawerOpen} onCloseDrawer={onCloseActiveHomeworkDetailDrawer} studentHomework={homework!} />
        <WaitingHomeworkDetailDrawer open={waitingHomeoworkDetailDrawerOpen} onCloseDrawer={onCloseWaitingHomeworkDetailDrawer} homework={homework!} />
        <LoadedHomeworkDetailDrawer open={loadedHomeoworkDetailDrawerOpen} onCloseDrawer={onCloseLoadedHomeworkDetailDrawer} studentHomework={homework!} />
        <EvulatedHomeworkDetailDrawer open={evulatedHomeoworkDetailDrawerOpen} onCloseDrawer={onCloseEvulatedHomeworkDetailDrawer} studentHomework={homework!} />
      </div>
    </div>
  );
};

StudentHomeWork.path = '/' + LayoutEnums.STUDENT + '/homework_exam';
StudentHomeWork.title = 'Ödev İşlemleri';
StudentHomeWork.group = 'flat';
StudentHomeWork.roles = [Role.ADMIN];
StudentHomeWork.layouts = [LayoutEnums.STUDENT];
StudentHomeWork.icon = <FileDoneOutlined size={20} />;
