import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { CascaderOption, EducationLevel, HomeworkTeacherDefinition } from '@shared/models';
import { Button, Cascader, Drawer, notification, UploadFile } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type CreateDraftViewDrawerProps = {
  draftDrawerCreateOpen: boolean;
  draftDrawerCreateOnClose: () => void;
  dataDraftCard: HomeworkTeacherDefinition;
  isUpdate: boolean;
};

const CreateDraftViewDrawerForm = z.object({
  name: z.string({ required_error: 'Ödev Başlığı zorunlu alandır' }).min(1, 'Ödev Başlığı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Ödev Açıklaması zorunlu alandır' }).min(1, 'Ödev Açıklaması 1 karekterden fazla olmalı'),
  levelId: z.string({ required_error: 'Kur zorunlu alandır' }),
  files: z.array(z.any()).optional(),
});

export const CreateDraftViewDrawer = ({ draftDrawerCreateOpen, draftDrawerCreateOnClose, dataDraftCard, isUpdate }: CreateDraftViewDrawerProps) => {
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<(string | number)[][]>([]);
  const [levelId, setLevelId] = useState<string>();
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<Partial<HomeworkTeacherDefinition>, HomeworkTeacherDefinition>(CreateDraftViewDrawerForm, {});

  useEffect(() => {
    if (draftDrawerCreateOpen && !isUpdate) {
      getTeacherLevel();
      form.setValue({});
      setFiles([]);
      setAbilityLevelCascaderValue([]);
      form.reset();
    }
  }, [draftDrawerCreateOpen, isUpdate]);

  useEffect(() => {
    if (dataDraftCard && isUpdate) {
      getTeacherLevel();
      setFiles(dataDraftCard.files.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue({ ...dataDraftCard });
      const model: string[][] = [];
      const r: string[] = [dataDraftCard.level?.theme?.type?.field?.id!];
      r.push(dataDraftCard.level?.theme?.type?.id!);
      r.push(dataDraftCard.level?.theme?.id!);
      r.push(dataDraftCard.level?.id!);
      model.push(r);

      setAbilityLevelCascaderValue(model);
    }
  }, [dataDraftCard, isUpdate]);

  const getTeacherLevel = async () => {
    try {
      const teacherResult = await API.TEACHER.getTeacherLevels();
      const teacher = teacherResult.data as EducationLevel[];

      const _items: CascaderOption[] = [];

      teacher?.forEach((f) => {
        let fieldOption = _items.find((item) => item.value === f.theme?.type?.field?.id);

        if (!fieldOption) {
          fieldOption = {
            value: f.theme?.type?.field?.id!,
            label: f.theme?.type?.field?.name!,
            children: [],
          };
          _items.push(fieldOption);
        }

        let typeOption = fieldOption.children?.find((item) => item.value === f.theme?.type?.id);

        if (!typeOption) {
          typeOption = {
            value: f.theme?.type?.id!,
            label: f.theme?.type?.name!,
            children: [],
          };
          fieldOption.children?.push(typeOption);
        }

        let themeOption = typeOption.children?.find((item) => item.value === f.theme?.id);

        if (!themeOption) {
          themeOption = {
            value: f.theme?.id!,
            label: f.theme?.name!,
            children: [],
          };
          typeOption.children?.push(themeOption);
        }

        themeOption.children?.push({
          value: f.id!,
          label: f.name!,
        });
      });

      setAbilityLevelOptions(_items);
    } catch (error) {}
  };

  const onChangeAbilityLevel = (value) => {
    setAbilityLevelCascaderValue(value);
    setLevelId(value[3]);
    form.setValue({ ...form.value, levelId: value[3] });
  };

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();
    if (result.success) {
      if (!isUpdate) {
        const response = await API.HOMEWORK.createHomeworkDefinition({ ...result.data, files, levelId: levelId! });
        if (response.ok) {
          draftDrawerCreateOnClose();
          notification.success({ message: 'Taslak oluşturuldu' });
        }
      } else {
        const response = await API.HOMEWORK.updateHomeworkDefinition({ ...result.data, files: files!, levelId: levelId! }, result.data.id);
        if (response.ok) {
          draftDrawerCreateOnClose();
          notification.success({ message: 'Taslak Güncellendi' });
        }
      }
    } else {
      form.setAllTouched();
    }
    startUpload(false);
  };

  return (
    <Drawer
      forceRender={true}
      open={draftDrawerCreateOpen}
      onClose={draftDrawerCreateOnClose}
      closable={true}
      title={<div> {isUpdate ? <span>Taslağı düzenle</span> : <span>Yeni ödev taslağı oluştur</span>}</div>}
    >
      <div>
        <h4>Kur</h4>
        <div className="mt-1">
          <FormItem {...form.formItem('levelId')}>
            <Cascader expandTrigger="hover" className="w-full" options={abilityLevelOptions} value={abilityLevelCascaderValue} onChange={onChangeAbilityLevel} />
          </FormItem>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev Başlığı</h4>
        <div className="mt-1">
          <FormItem {...form.formItem('name')}>
            <TextInput formItem={form.formItem('name')} formInput={form.input('name')} maxLength={500} />
          </FormItem>
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev Açıklama</h4>
        <div className="mt-1">
          <FormItem {...form.formItem('description')}>
            <TextArea {...form.input('description')} maxLength={500} autoSize={{ minRows: 2, maxRows: 5 }} style={{ resize: 'none' }} showCount />
          </FormItem>
        </div>
      </div>

      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            onChangeLoading={(b) => {
              setLoading(b);
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>
      <Button loading={loading} className="w-full mt-5" type="primary" onClick={() => startUpload(true)}>
        {isUpdate ? <span> Ödev taslağı güncelle</span> : <span> Ödev taslağı oluştur</span>}
      </Button>
      <Button className="w-full mt-2" ghost type="primary" onClick={draftDrawerCreateOnClose}>
        Vazgeç
      </Button>
    </Drawer>
  );
};

CreateDraftViewDrawer.roles = [Role.ADMIN];
