import {
  BuildOutlined,
  DatabaseOutlined,
  FieldTimeOutlined,
  FolderOutlined,
  LeftOutlined,
  LoadingOutlined,
  ManOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SaveOutlined,
  StopOutlined,
  SyncOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import { ClassroomDrawer, CreateBranchCreditDrawer, CreateSessionDefinitions, PlanBlock, PlanCard } from '@platform/platform_components';
import * as API from '@shared/api';
import { AvatarFromName, Spinner } from '@shared/components';
import { Draggable } from '@shared/components/dragdrop';
import { LayoutEnums, Role } from '@shared/constants';
import { DragDropContextProvider } from '@shared/contexts';
import { useAccessableSeachParams, useWebSocket } from '@shared/hooks';
import { BranchUserModel, ClassRoomModel, PrePlacementDemand, SessionDefinitionsCreateModel } from '@shared/models';
import { calculateAge, renderLevelsAsString } from '@shared/utils';
import { Avatar, Button, Card, Collapse, Drawer, Empty, notification, Spin, Statistic, Tabs, Tag, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { LuBaby } from 'react-icons/lu';
import { TbFilterX } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { PiMedal } from 'react-icons/pi';
import { PiStudent } from 'react-icons/pi';

export interface PlanBlockProps extends HTMLAttributes<HTMLElement> {
  variant: 'drop' | 'drag';
  onDelete?: VoidFunction;
  hideInfo?: boolean;
}

export const PlanningStudio = () => {
  useAccessableSeachParams();
  const [currentBalance, isCurrentBalanceLoading, getCurrentBalance] = API.LMS_CREDIT.retriveCurrentBranchLmsCreditBalance({ init: true });
  const [currentBalanceBranch, setCurrentBalanceBranch] = useState<number>(0);
  const [listScrollElement, setListScrollElement] = useState<HTMLElement | null>();
  const [planStatusFilter, setPlanStatusFilter] = useState('ALL');
  const [kurFilter, setKurFilter] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>('1');

  const [drawerType, setDetailType] = useState<'student' | 'teacher' | 'seance' | 'classRoom' | undefined>();
  const [detailObject, setDetaiObject] = useState<any>(null);

  const [members, setMembers] = useState<BranchUserModel[]>([]);

  const [resultPlanList = [], loadingPlanList, getPlanList, err] = API.CLASS_PLANNING.useFetch({ init: true });
  const [planList, setPlanList] = useState<any[]>([]);

  const [teacherList] = API.TEACHER.useFetch({ init: true, initParams: { withDeleted: false } });
  const [classRoomList, isLoadingClassRoomList, getClassRoomList] = API.CLASS_ROOM.getClassroomList({ init: true });
  const [studentList, studentListLoading, getStudentList] = API.ENROLLMENT.getPrePlacementDemands({ init: true, initParams: { demandStatus: 'PLANNING' } });
  const [seanceList, isLoadingSeanceList, getSeanceList] = API.SESSION_DEFINITIONS.getSessionData({ init: true });

  const [classRoomDrawerOpen, setClassRoomDrawerOpen] = useState<boolean>(false);
  const [seanceDrawerOpen, setSeanceDrawerOpen] = useState<boolean>(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);
  const [changesSavedCompleted, setChangesSavedCompleted] = useState<boolean>(false);
  const [studentInput, setStudentInput] = useState<string>('');
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { Panel } = Collapse;

  useEffect(() => {
    if (resultPlanList.length > 0) {
      setPlanList(resultPlanList);
    }
  }, [resultPlanList]);

  useEffect(() => {
    if (currentBalance) {
      setCurrentBalanceBranch(currentBalance);
    }
  }, [currentBalance]);

  const [socket, isConnect, onEmit] = useWebSocket(
    'class-planning',
    useMemo(() => {
      return {
        'class.planning.updated': (data) => {
          const _ = planList.filter((p) => p.id != data.id);
          setPlanList([..._, data]);
        },
        'class.planning.created': (data) => {
          setPlanList([...planList, data]);
        },
        'class.planning.deleted': (data) => {
          const _ = planList.filter((p) => p.id != data);
          setPlanList([..._]);
        },
        'class.planning.collectives': (data) => {
          if (data.length > 0) {
            setMembers(data);
          }
        },
        'class.planning.collective.connected': (data: any) => {
          if (members.findIndex((m) => m.id == data.id) == -1) {
            setMembers([...members, data]);
          }
        },
        'class.planning.collective.disconnected': (data) => {
          setMembers(
            members!.filter((m) => {
              return m.id != data.toString();
            })
          );
        },
        'class.planning.lms.credit.balance.updated': (data) => {
          setCurrentBalanceBranch(data.balance);
        },
      };
    }, [resultPlanList, members, setMembers, planList, setPlanList, setCurrentBalanceBranch])
  );

  const createPlan = useCallback(async () => {
    await API.CLASS_PLANNING.create();
    //getPlanList();
  }, []);

  const onDrop = useCallback((type: string, dropItem: any, dragItem: any) => {
    if (type && dropItem && dragItem) {
      dropItem.setFn(dragItem.value);
    }
  }, []);

  const scrollPlan = useCallback(
    (direction: number) => {
      listScrollElement?.scrollBy({ left: 300 * direction, behavior: 'smooth' });
    },
    [listScrollElement]
  );

  const visiblePlanList = useMemo(() => {
    if (planStatusFilter === 'ALL') {
      return planList.sort((a, b) => a.order - b.order);
    } else if (planStatusFilter === 'PLANNING') {
      return planList.filter((item) => item.status === planStatusFilter || item.status === 'REJECTED').sort((a, b) => a.order - b.order);
    } else {
      return planList.filter((item) => item.status === planStatusFilter).sort((a, b) => a.order - b.order);
    }
  }, [planList, planStatusFilter]);

  const getButtonClass = (status) => {
    return planStatusFilter === status ? 'bg-gray-300' : '';
  };

  useEffect(() => {
    setChangesSaved(false);
    setChangesSavedCompleted(false);
    const timer = setTimeout(() => {
      setChangesSaved(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [visiblePlanList]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setChangesSavedCompleted(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [changesSaved]);

  const planCardaEklendiMi = (student: PrePlacementDemand): boolean => {
    let studentInPlanCard = false;
    //PlanCard'a sürüklenen öğrenci sağ tarafta disabled hale getirilir.
    planList.map((planCard) =>
      planCard.prePlacementDemands.map((s) => {
        if (s.id === student.id && s.level.id === student.level.id) {
          studentInPlanCard = true;
        }
      })
    );
    return studentInPlanCard;
  };

  const calculateAtamaBekleyenSayi = useCallback(() => {
    let planCarddakiOgrenci = 0;
    let toplamOgrenci = studentList?.length;

    if (toplamOgrenci == undefined) {
      return 0;
    }

    studentList?.map((student) => {
      //Plan Cardlarda bulunan öğrencilerin sayısını toplam öğrenci sayısından çıkarmak için plan cardlarındaki öğrenci sayısını buluyoruz
      planList.map((planCard) =>
        planCard.prePlacementDemands.map((s) => {
          if (s.prePlacement.id === student.prePlacement.id && s.level.id === student.level.id) {
            planCarddakiOgrenci += 1;
          }
        })
      );
    });

    return toplamOgrenci - planCarddakiOgrenci;
  }, [planList, studentList]);

  const updateKurFilter = (plan) => {
    setKurFilter(plan);
  };

  const saveOrUpdateClassroom = async (data: ClassRoomModel) => {
    const response = await API.CLASS_ROOM.save(data);
    if (response.ok) {
      setClassRoomDrawerOpen(false);
      notification.success({ message: 'Derslik oluşturuldu' });
      getClassRoomList();
    }
  };

  const createOrUpdateSeance = async (value: SessionDefinitionsCreateModel) => {
    const response = await API.SESSION_DEFINITIONS.create(value);
    if (response.ok) {
      notification.success({ message: 'Seans oluşturuldu' });
    }
    setSeanceDrawerOpen(false);
    getSeanceList();
  };

  const groupByLevel = (students) => {
    const sortedStudents = students.sort((a, b) => {
      const typeComparison = a.level.theme.type.id.localeCompare(b.level.theme.type.id);

      if (typeComparison === 0) {
        const themeComparison = a.level.theme.id.localeCompare(b.level.theme.id);

        if (themeComparison === 0) {
          return a.level.order - b.level.order;
        }
        return themeComparison;
      }
      return typeComparison;
    });

    return sortedStudents.reduce((groups, student) => {
      const level = student.level.id;
      if (!groups[level]) {
        groups[level] = [];
      }
      groups[level].push(student);
      return groups;
    }, {});
  };

  const renderStudents = (students) => {
    return students.map((student, index) => (
      <Draggable disabled={planCardaEklendiMi(student)} key={Math.random().toString(36).substring(7) + student?.id} id={student.id} type="STUDENT" value={student}>
        <PlanBlock
          key={Math.random().toString(36).substring(7) + student?.id}
          id={student.id}
          type="STUDENT"
          student={student}
          disabled={planCardaEklendiMi(student)}
          variant="drag"
        >
          {student.prePlacement.gender == 'MALE' && (
            <span className="mr-1">
              <Tooltip title="Erkek">
                <ManOutlined style={{ color: 'blue' }} />
              </Tooltip>
            </span>
          )}

          {student.prePlacement.gender == 'FEMALE' && (
            <span className="mr-1">
              <Tooltip title="Kadın">
                <WomanOutlined style={{ color: 'magenta' }} />
              </Tooltip>
            </span>
          )}
          {calculateAge(student.prePlacement.birthDate) < 18 && (
            <span className="mr-1">
              <Tooltip title="18 yaş altı">
                <LuBaby size={15} color="orange" />
              </Tooltip>
            </span>
          )}

          {student.prePlacement.name + ' ' + student.prePlacement.surname}
          <small className="text-black/50 ml-1">({student.waitingInDays} Gün)</small>
        </PlanBlock>
      </Draggable>
    ));
  };

  const groupByAgreementType = (teachers) => {
    const groups = {};

    teachers?.forEach((teacher) => {
      const agreementType = teacher.agreementType;
      if (!groups[agreementType]) {
        groups[agreementType] = [];
      }
      groups[agreementType].push(teacher);
    });

    return groups;
  };

  const renderTeachers = (teachers) => {
    return teachers.map((teacher) => (
      <Draggable key={teacher.id} id={teacher.id} type="TEACHER" value={teacher} className="pt-1">
        <PlanBlock key={Math.random().toString(36).substring(7) + teacher?.id} id={teacher.id} type="TEACHER" teacher={teacher} variant="drag">
          {teacher.gender == 'MALE' && (
            <span className="mr-1">
              <Tooltip title="Erkek">
                <ManOutlined style={{ color: 'blue' }} />
              </Tooltip>
            </span>
          )}
          {teacher.gender == 'FEMALE' && (
            <span className="mr-1">
              <Tooltip title="Kadın">
                <WomanOutlined style={{ color: 'magenta' }} />
              </Tooltip>
            </span>
          )}
          {teacher.name + ' ' + teacher.surname}
        </PlanBlock>
      </Draggable>
    ));
  };

  const filteredTeachers = teacherList?.data?.filter((teacher) => !kurFilter || teacher.abilityLevels?.some((level) => level.name === kurFilter?.level.name));

  const groupedTeachers = groupByAgreementType(filteredTeachers);

  const closeDrawer = () => {
    setOpen(false);
  };

  const saveBranchLmsCreditOrder = async (formValue) => {
    const response = await API.LMS_CREDIT.createBranchLmsCreditOrder(formValue as any);
    if (response.ok) {
      notification.success({ message: 'Kredi Talebi Oluşturuldu' });
      closeDrawer();
    }
  };

  return (
    <div className="h-full">
      <Spinner loading={loadingPlanList} />
      <DragDropContextProvider onDrop={onDrop}>
        <div className="h-full flex flex-col gap-[12px] overflow-hidden">
          <div className="grid grid-cols-[1fr_300px]">
            <div className="flex items-center gap-[22px]">
              <div className="flex items-center gap-[10px]">
                <Button type="text" icon={<MenuOutlined />} onClick={() => setPlanStatusFilter('ALL')} className={getButtonClass('ALL')}>
                  Tümü
                </Button>
                <Button type="text" icon={<SyncOutlined />} onClick={() => setPlanStatusFilter('PLANNING')} className={getButtonClass('PLANNING')}>
                  Planlanıyor
                </Button>
                <Button type="text" icon={<FieldTimeOutlined />} onClick={() => setPlanStatusFilter('IN_REVIEW')} className={getButtonClass('IN_REVIEW')}>
                  İnceleniyor
                </Button>
                <Button type="text" icon={<StopOutlined />} onClick={() => setPlanStatusFilter('REJECTED')} className={getButtonClass('REJECTED')}>
                  Reddedildi
                </Button>
              </div>

              <Avatar.Group maxCount={3} maxPopoverTrigger="click" size="large" maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}>
                {members?.map((m) => (
                  <AvatarFromName key={m.id} title={m.name!} subtitle={m.email!} />
                ))}
              </Avatar.Group>

              <div className="w-full flex items-center justify-end gap-1">
                <Button size="large" type="text" icon={<LeftOutlined className="text-[#1677FF]" />} onClick={() => scrollPlan(-1)}></Button>
                <Button size="large" type="text" icon={<RightOutlined className="text-[#1677FF]" />} onClick={() => scrollPlan(1)}></Button>
                {isConnect ? <Tag color="green">Çevrimiçi</Tag> : <Tag color="red">Çevrimdışı</Tag>}
              </div>
            </div>

            <div className="flex items-center justify-end">
              <div className="flex items-center gap-2.5 text-black/45">
                <span className={`pr-1 ${changesSaved ? 'fade-out' : ''}`}>
                  {!changesSavedCompleted ? (
                    changesSaved ? (
                      <span className="pr-1">
                        <SaveOutlined />
                        <span className="ml-1">Değişiklikler Kaydedildi </span>
                      </span>
                    ) : (
                      <span className="pr-1">
                        <Spin indicator={<LoadingOutlined spin />} size="small" />
                        <span className="ml-1">Değişiklikler Kaydediliyor </span>
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-[1fr_300px] h-full gap-4">
            <div ref={setListScrollElement} className="flex flex-row h-full gap-2.5  overflow-x-auto snap-mandatory snap-x">
              {visiblePlanList.length > 0 ? (
                visiblePlanList.map((plan) => (
                  <PlanCard
                    id={plan.id}
                    key={plan.id}
                    plan={plan}
                    currentBalanceBranch={currentBalanceBranch}
                    kurFilter={kurFilter}
                    updateKurFilter={updateKurFilter}
                    onUpdate={() => {
                      /*getPlanList()*/
                    }}
                  />
                ))
              ) : (
                <div className="flex flex-col space-y-2 h-full w-full content-center items-center justify-center text-center">
                  <DatabaseOutlined style={{ fontSize: '200%' }} />
                  <label>Lütfen ilk sınıfınızı oluşturunuz</label>
                  <Button type="dashed" onClick={createPlan} icon={<PlusCircleOutlined />}>
                    Yeni Sınıf Oluştur
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[5px]">
              <Button className="w-full" type="dashed" onClick={createPlan} icon={<PlusCircleOutlined />}>
                Yeni Sınıf Oluştur
              </Button>
              <div className="grid grid-cols-2">
                <Card className="planning-statistic-card flex items-center rounded-none	 ">
                  <div className="flex items-center">
                    <PiStudent size={20} />
                    <span className="ml-2 ">{studentList?.length}</span>
                  </div>
                </Card>
                <Card className="planning-statistic-card flex items-center rounded-none	">
                  <div className="flex items-center">
                    <PiMedal size={20} />
                    <span className="ml-2 ">{currentBalanceBranch}</span>
                    <Button type="text" className="ml-4 text-blue-500" onClick={() => setOpen(true)} icon={<PlusCircleOutlined />}></Button>
                  </div>
                </Card>
              </div>

              {kurFilter && (
                <div className="planning-filter flex items-center">
                  <Tag>{kurFilter?.level?.name}</Tag>
                  <Button type="text" size="small" icon={<TbFilterX />} onClick={() => setKurFilter(null)}>
                    Filtreyi Kaldır
                  </Button>
                </div>
              )}

              <Tabs
                className="plan-drag-tabs"
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
                items={[
                  {
                    key: '1',
                    label: (
                      <Tag color={activeKey === '1' ? '#1677FF' : 'blue'} className="rounded-[28px] px-2">
                        Öğrenci
                      </Tag>
                    ),
                    children: (
                      <>
                        <Search
                          value={studentInput}
                          onChange={(e) => setStudentInput(e.target.value)}
                          placeholder="Öğrenci ara..."
                          onSearch={() => getStudentList(undefined, { demandStatus: 'PLANNING', name: studentInput })}
                          enterButton
                          className="mb-1"
                        />

                        <div
                          style={{ height: 'calc(100vh - 400px)' }}
                          className={`overflow-y-scroll ${studentList && studentList.length > 0 ? 'bg-white' : 'flex flex-col bg-white text-center justify-center'}`}
                        >
                          {studentList && studentList.length > 0 ? (
                            <Collapse
                              defaultActiveKey={Object.keys(groupByLevel(studentList.filter((student) => !kurFilter || student.level.id === kurFilter?.level.id)))}
                              expandIconPosition={'end'}
                              style={{ background: '#E6F4FF', padding: '0' }}
                              accordion={true}
                            >
                              {Object.keys(groupByLevel(studentList.filter((student) => !kurFilter || student.level.id === kurFilter?.level.id))).map((level) => (
                                <Panel
                                  className="custom-panel"
                                  header={
                                    <div className="text-xs" style={{ color: '#1677FF' }}>
                                      <FolderOutlined />
                                      <span className="ml-1"> {renderLevelsAsString(studentList.find((student) => student.level.id === level)?.level)}</span>
                                    </div>
                                  }
                                  key={level}
                                >
                                  {renderStudents(groupByLevel(studentList.filter((student) => !kurFilter || student.level.name === kurFilter?.level.name))[level])}
                                </Panel>
                              ))}
                            </Collapse>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No data'}>
                              <Button type="primary" onClick={() => navigate('/crm/enrollments?' + location.search)}>
                                Create Student
                              </Button>
                            </Empty>
                          )}
                        </div>
                      </>
                    ),
                  },
                  {
                    key: '2',
                    label: (
                      <Tag color={activeKey === '2' ? '#1677FF' : 'blue'} className="rounded-[28px] px-2">
                        Öğretmen
                      </Tag>
                    ),
                    children: (
                      <div
                        style={{ height: 'calc(100vh - 370px)' }}
                        className={`overflow-y-scroll ${teacherList?.data && teacherList.data.length > 0 ? 'bg-white' : 'flex flex-col bg-white text-center justify-center'}`}
                      >
                        {teacherList?.data && teacherList.data.length > 0 ? (
                          <Collapse defaultActiveKey={Object.keys(groupedTeachers)} expandIconPosition={'end'} style={{ background: '#E6F4FF', padding: '0' }}>
                            {Object.keys(groupedTeachers).map((level) => (
                              <Panel
                                className="custom-panel"
                                header={
                                  <div style={{ color: '#1677FF' }}>
                                    <FolderOutlined />
                                    <span className="ml-3"> {level}</span>
                                  </div>
                                }
                                key={level}
                              >
                                {renderTeachers(groupedTeachers[level])}
                              </Panel>
                            ))}
                          </Collapse>
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No data'}>
                            <Button type="primary" onClick={() => navigate('/lms/create-teacher' + location.search)}>
                              Create Teacher
                            </Button>
                          </Empty>
                        )}
                      </div>
                    ),
                  },
                  {
                    key: '3',
                    label: (
                      <Tag color={activeKey === '3' ? '#1677FF' : 'blue'} className="rounded-[28px] px-2">
                        Derslik
                      </Tag>
                    ),
                    children: (
                      <div
                        style={{ height: 'calc(100vh - 370px)' }}
                        className={`overflow-y-scroll ${classRoomList?.data && classRoomList.data.length > 0 ? 'bg-white' : 'flex flex-col bg-white text-center'}`}
                      >
                        <Button className="w-full" type="dashed" icon={<PlusCircleOutlined />} onClick={() => setClassRoomDrawerOpen(true)}>
                          Yeni derslik oluştur
                        </Button>
                        {classRoomList?.data?.map((room) => (
                          <Draggable key={room.id} id={room.id} type="ROOM" value={room}>
                            <PlanBlock key={Math.random().toString(36).substring(7) + room?.id} id={room.id} variant="drag" type="ROOM" room={room}>
                              <small className="text-black/50 mr-1">
                                {room.quota}-{room.maxQuota}
                              </small>
                              {room.name}
                            </PlanBlock>
                          </Draggable>
                        ))}
                      </div>
                    ),
                  },
                  {
                    key: '4',
                    label: (
                      <Tag color={activeKey === '4' ? '#1677FF' : 'blue'} className="rounded-[28px] px-3">
                        Seans
                      </Tag>
                    ),
                    children: (
                      <div
                        style={{ height: 'calc(100vh - 370px)' }}
                        className={`overflow-y-scroll ${seanceList?.data && seanceList.data.length > 0 ? 'bg-white' : 'flex flex-col bg-white text-center'}`}
                      >
                        <Button className="w-full" type="dashed" icon={<PlusCircleOutlined />} onClick={() => setSeanceDrawerOpen(true)}>
                          Yeni seans oluştur
                        </Button>
                        {seanceList?.data?.map((seance) => (
                          <Draggable key={seance.id} id={seance.id} type="SEANCE" value={seance}>
                            <PlanBlock key={Math.random().toString(36).substring(7) + seance?.id} id={seance.id} variant="drag" type="SEANCE" seance={seance}>
                              {seance.name}
                            </PlanBlock>
                          </Draggable>
                        ))}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </DragDropContextProvider>

      <ClassroomDrawer
        selected={{}}
        open={classRoomDrawerOpen}
        isUpdate={false}
        saveOrUpdateClassroom={saveOrUpdateClassroom}
        onCloseDrawer={() => setClassRoomDrawerOpen(false)}
      />

      <CreateBranchCreditDrawer open={open} isUpdateMode={false} seledtedData={undefined!} saveBranchLmsCreditOrder={saveBranchLmsCreditOrder} closeDrawer={closeDrawer} />

      <Drawer title={'Yeni Seans Ekle'} onClose={() => setSeanceDrawerOpen(false)} open={seanceDrawerOpen}>
        <CreateSessionDefinitions
          isUpdate={false}
          selectData={{}}
          onSave={(v) => {
            createOrUpdateSeance(v);
          }}
        />
      </Drawer>
    </div>
  );
};

PlanningStudio.path = '/branch_manager/planning_studio';
PlanningStudio.title = 'Planlama Stüdyosu';
PlanningStudio.group = 'flat';
PlanningStudio.roles = [Role.ADMIN];
PlanningStudio.layouts = [LayoutEnums.BRANCHMANAGER];
PlanningStudio.icon = <BuildOutlined size={20} />;
PlanningStudio.permissions = ['branch.lms.class.planning.view'];
