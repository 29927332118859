import { AuditOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { BrandLmsCreditBalance, CreditBalanceType, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Table, Tag } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

export const BrandBalance = () => {
  useAccessableSeachParams();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, withDeleted: false, orders: ['-createdAt'] });

  const [brandCreditBalances, loading, getBrandCreditBalances] = API.LMS_CREDIT.paginateBrandLmsCreditBalance({ init: false });
  const [currentBalance, isCurrentBalanceLoading, getCurrentBalance] = API.LMS_CREDIT.retriveCurrentBrandLmsCreditBalance({ init: true });

  useEffect(() => {
    getBrandCreditBalances(undefined, paginationQuery);
  }, [paginationQuery]);

  const columns: TableProps<BrandLmsCreditBalance>['columns'] = [
    {
      title: 'Tür',
      dataIndex: 'type',
      align: 'center',
      sorter: { multiple: 2 },

      render: (_, { type }) => (type == CreditBalanceType.CREDIT ? <Tag color="blue">Giriş</Tag> : <Tag color="red">Çıkış</Tag>),
    },
    {
      title: 'Kredi Sayısı',
      dataIndex: 'count',
      align: 'center',
    },
    {
      title: 'Talep Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: { multiple: 1 },
      defaultSortOrder: 'descend',
      render: (_, { createdAt }) => convertDate(createdAt, 'DD.MM.YYYY HH:mm'),
    },

    {
      title: 'İşlemi Yapan Kişi',
      key: 'proceedBy',
      align: 'center',
      render: (_, { proceedBy }) => (proceedBy ? `${proceedBy.name}` : ''),
    },
  ];
  return (
    <div>
      <h3>Mevcut Bakiyeniz: {currentBalance}</h3>
      <Table
        loading={loading}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          showPrevNextJumpers: true,
          current: paginationQuery.page,
          total: brandCreditBalances?.count,
          pageSize: paginationQuery.limit,
          position: ['bottomCenter'],
        }}
        columns={columns}
        dataSource={brandCreditBalances?.data}
        bordered
        onChange={(pagination, filters, sorter) => {
          const { current, pageSize } = pagination;
          let newQuery = {
            ...paginationQuery,
            page: current,
            limit: pageSize,
            orders: sortFields(paginationQuery, sorter as SorterResult<any>),
            ...filters,
          };
          Object.keys(newQuery).forEach((key) => {
            if (key === 'orders') {
              return;
            }
            if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
              newQuery[key] = newQuery[key][0];
            }
          });

          setPaginationQuery(newQuery);
        }}
      />
    </div>
  );
};

BrandBalance.path = '/lms/brand-credit-balance';
BrandBalance.title = 'Kredi Bakiye Hareketleri';

BrandBalance.group = 'brand.lms.credit';
BrandBalance.roles = [Role.ADMIN];
BrandBalance.layouts = [LayoutEnums.BRANDMANAGER, LayoutEnums.LMS];
BrandBalance.permissions = ['brand.lms.credit.view', 'brand.lms.credit.manage'];
