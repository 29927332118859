import { PlusCircleOutlined } from '@ant-design/icons';
import { CreateEnrollmentObserverForm } from '@platform/platform_components/crm/create_enrollment_request/CreateEnrollmentObserverForm';
import { CreateEnrollmentStudentForm } from '@platform/platform_components/crm/create_enrollment_request/CreateEnrollmentStudentForm';
import { EnrollmentObserverList } from '@platform/platform_components/crm/create_enrollment_request/EnrollmentObserverList';
import { EnrollmentStudentList } from '@platform/platform_components/crm/create_enrollment_request/EnrollmentStudentList';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { AvailabilityCalendar, Enrollment, EnrollmentStatus, EnrollmentTypes, Observer, PaginationQuery, PrePlacement } from '@shared/models';
import { urlCleaner } from '@shared/utils';
import { Button, Drawer, Pagination, Popconfirm, Tooltip, notification } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as API from '@shared/api';
import i18n from '@shared/localization/i18n';
import Modal from 'antd/es/modal/Modal';
import { AvailabilityWeekCalendar } from '@shared/components';
import dayjs from 'dayjs';


export const CreateIndividualEnrollmentRequest = () => {
    useAccessableSeachParams();
    const navigate = useNavigate();
    const [searchParams, setSeachParams] = useSearchParams();

    const [isOpenStudentDrawer, setIsOpenStudentDrawer] = useState(false);
    const [isOpenObserverDrawer, setIsOpenObserverDrawer] = useState(false);
    const [isOpenPrePlacementCalendarModal, setIsOpenPrePlacementCalendarModal] = useState(false);
    const [enrollment, setEnrollment] = useState<Enrollment>();
    //const [enrollmentId, setEnrollmentId] = useState<string>();

    const [prePlacements, prePlacementsLoading, getPrePlacements] = API.ENROLLMENT.getPrePlacements({ init: false });
    const [observers, observersLoading, getObservers] = API.ENROLLMENT.getObservers({ init: false });
    const [observersQuery, setObserversQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
    const [prePlacementsQuery, setPrePlacementsQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
    const [selectedPrePlacement, setSelectedPrePlacement] = useState<PrePlacement>();
    const [selectedObserver, setSelectedObserver] = useState<Observer>();
    const [selectedPrePlacementCalendar, setSelectedPrePlacementCalendar] = useState<Partial<{ id: string, calendar: AvailabilityCalendar }>>({});


    useEffect(() => {
        if (searchParams.size > 0) {
            const id = searchParams.get('enrollment');
            if (id) {
                getEnrollmentById(id);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (enrollment) {
            setPrePlacementsQuery({ ...prePlacementsQuery, enrollmentId: enrollment.id });
            setObserversQuery({ ...observersQuery, enrollmentId: enrollment.id });
        }
    }, [enrollment?.id]);


    const getEnrollmentById = async (id: string) => {
        try {
            const { data } = await API.ENROLLMENT.fetchById(id);
            setEnrollment(data);

        } catch (error) { }
    };



    useEffect(() => {
        if (observersQuery.enrollmentId) {
            getObservers(undefined, observersQuery);
        }
    }, [observersQuery]);

    useEffect(() => {
        if (prePlacementsQuery.enrollmentId) {
            getPrePlacements(undefined, prePlacementsQuery);
        }
    }, [prePlacementsQuery]);

    useEffect(() => {
        if (selectedPrePlacement && selectedPrePlacement.id) {
            setIsOpenStudentDrawer(true);
        }

    }, [selectedPrePlacement]);

    useEffect(() => {
        if (selectedObserver && selectedObserver.id) {
            setIsOpenObserverDrawer(true);
        }

    }, [selectedObserver]);

    useEffect(() => {
        if (selectedPrePlacementCalendar && Object.keys(selectedPrePlacementCalendar).length > 0) {
            setIsOpenPrePlacementCalendarModal(true);
        } else {
            setIsOpenPrePlacementCalendarModal(false);
        }

    }, [selectedPrePlacementCalendar]);

    const goBack = () => {
        navigate('/crm/enrollments?' + urlCleaner(searchParams));
    }

    const onApproval = async () => {
        if (enrollment?.status != EnrollmentStatus.READY_FOR_PLANNING) {
            const { ok } = await API.ENROLLMENT.setAsReadyForPlanning(enrollment?.id!);
            if (ok) {
                goBack();
            }
        } else {
            notification.error({ message: 'Kayıt zaten onaylandı' });
        }
    };

    const onUpdatePrePlacementCalendar = useCallback(async () => {
        if (selectedPrePlacementCalendar && selectedPrePlacementCalendar.id) {
            const { ok } = await API.ENROLLMENT.updatePrePlacementCalendar(selectedPrePlacementCalendar.id, selectedPrePlacementCalendar.calendar!);
            if (ok) {
                setSelectedPrePlacementCalendar({});
                getPrePlacements(undefined, prePlacementsQuery);
                notification.success({ message: 'Takvimi güncellendi' });
            } else {
                notification.error({ message: 'Takvimi güncellenemedi' });
            }
        }

    }, [selectedPrePlacementCalendar]);

    const selectedCalendar: AvailabilityCalendar = useMemo(() => {
        return selectedPrePlacementCalendar?.calendar ? selectedPrePlacementCalendar.calendar : {};
    }, [selectedPrePlacementCalendar]);


    return (
        <>
            <Modal title="Öğrenci Takvimini Düzenle"
                open={isOpenPrePlacementCalendarModal}
                width={1000}
                okText="Takvimi Güncelle"
                cancelText="İptal"
                onOk={() => { onUpdatePrePlacementCalendar(); }}
                onCancel={() => { setSelectedPrePlacementCalendar({}); }}
                onClose={() => { setSelectedPrePlacementCalendar({}); }}
            >
                <AvailabilityWeekCalendar
                    value={selectedPrePlacementCalendar.calendar!}
                    disabled={/*isPreview || !isEmailSearch*/ false}
                    onChange={(availability) => {
                        setSelectedPrePlacementCalendar({ ...selectedPrePlacementCalendar, calendar: availability });
                    }}
                />
            </Modal>

            <Drawer
                title={"Öğrenci Ekle"}
                open={isOpenStudentDrawer}
                onClose={(e) => {
                    setIsOpenStudentDrawer(false);
                    setSelectedPrePlacement(undefined);

                }}
                width={690}>
                <CreateEnrollmentStudentForm
                    isDisabled={enrollment && enrollment.status != EnrollmentStatus.INFO}
                    prePlacement={selectedPrePlacement}
                    onUpdate={async (prePlacement: PrePlacement) => {
                        const _enrolment = {
                            ...enrollment,
                            name: prePlacement.name + ' ' + prePlacement.surname,
                            email: prePlacement.email,
                            nationalId: prePlacement.nationalId,
                            type: EnrollmentTypes.INDIVIDUAL,
                            phone: prePlacement.phone,
                        } as Enrollment;
                        const { ok: EnrollmentUpdateOk } = await API.ENROLLMENT.update(_enrolment);
                        setEnrollment(_enrolment);
                        if (EnrollmentUpdateOk) {
                            const { ok } = await API.ENROLLMENT.updatePrePlacement({ ...prePlacement, enrollmentId: enrollment?.id! });
                            setSelectedPrePlacement(undefined);
                            setIsOpenStudentDrawer(false);
                            return ok;
                        } else {
                            notification.error({ message: 'Kayıt oluşturulamadı.' });
                        }
                    }}
                    onSave={async (prePlacement: PrePlacement) => {
                        let enrollmentCreateOk = false;
                        let enrollmentData: Enrollment = {};
                        if (!(enrollment && enrollment.id)) {
                            const _enrolment = {
                                name: prePlacement.name + ' ' + prePlacement.surname,
                                email: prePlacement.email,
                                nationalId: prePlacement.nationalId,
                                type: EnrollmentTypes.INDIVIDUAL,
                                phone: prePlacement.phone,
                            } as Enrollment;
                            const { ok: EnrollmentCreateOk, data } = await API.ENROLLMENT.create(_enrolment);
                            enrollmentCreateOk = EnrollmentCreateOk;
                            setEnrollment(data);
                            enrollmentData = data;
                        } else {
                            enrollmentCreateOk = true;
                            enrollmentData = enrollment;
                        }

                        if (enrollmentCreateOk && enrollmentData) {

                            const { ok } = await API.ENROLLMENT.createPrePlacment({ ...prePlacement, enrollmentId: enrollmentData.id });
                            const layout = searchParams.get('layout');
                            const brandId = searchParams.get('brandId');
                            const branchId = searchParams.get('branchId');
                            setSeachParams({ layout: layout!, brandId: brandId!, branchId: branchId!, enrollment: enrollmentData.id! }, { replace: false });
                            setSelectedPrePlacement(undefined);
                            if (ok) {
                                setIsOpenStudentDrawer(false);
                                return false;
                            }
                            return false;
                        } else {
                            notification.error({ message: 'Kayıt oluşturulamadı.' });
                            return false;
                        }
                    }}
                />
            </Drawer>

            <Drawer title={"Gözetmen Ekle"} open={isOpenObserverDrawer} onClose={(e) => {
                setIsOpenObserverDrawer(false);
                setSelectedObserver(undefined);
            }}>
                <CreateEnrollmentObserverForm
                    observer={selectedObserver}
                    onSave={
                        async (observer: Observer) => {
                            const { ok } = await API.ENROLLMENT.createObserver({ ...observer, enrollmentId: enrollment?.id! });
                            if (ok) {
                                getObservers(undefined, observersQuery);
                                setIsOpenObserverDrawer(false);
                                return true;
                            }
                            return false;
                        }
                    }
                    onUpdate={
                        async (observer: Observer) => {
                            const { ok } = await API.ENROLLMENT.updateObserver({ ...observer, enrollmentId: enrollment?.id! });
                            if (ok) {
                                getObservers(undefined, observersQuery);
                                setIsOpenObserverDrawer(false);
                                return true;
                            }
                            return false;
                        }
                    }
                />
            </Drawer>
            <div className='flex flex-col space-y-10 mt-8'>
                {/* TODO: Enrollmetn bilgileri ekrani başlangıç */}

                {
                    enrollment && <div className='border-dashed border-gray-300 rounded-md p-4'>
                        <div className='flex flex-row w-full space-x-2 overflow-y-auto justify-around'>
                            <div className="flex flex-row space-x-1 items-center">
                                <span className='text-sm font-medium text-gray-400'>Kayıt No:</span>
                                <span className='text-sm font-medium text-gray-400'>{enrollment?.no}</span>
                            </div>
                            <div className="flex flex-row space-x-1 items-center">
                                <span className='text-sm font-medium text-gray-400'>Kayıt Durumu:</span>
                                <span className='text-sm font-medium text-gray-400'>{i18n.t('app.enrollmentStatus.' + enrollment?.status)}</span>
                            </div>
                            <div className="flex flex-row space-x-1 items-center">
                                <span className='text-sm font-medium text-gray-400'>Kayıt Tarihi:</span>
                                <span className='text-sm font-medium text-gray-400'>{dayjs(enrollment?.createdAt).format('DD.MM.YYYY')}</span>
                            </div>
                        </div>

                    </div>
                }

                {/* TODO: Enrollmetn bilgileri ekrani bitiş */}

                {/* TODO: Öğrenci ekleme ekrani başlangıç */}
                <div className='w-full flex flex-col space-y-2'>
                    <div className='flex flex-row justify-between items-center'>
                        <span className='text-lg font-medium'>Öğrenci Listesi</span>
                        <Tooltip title={prePlacements?.count == 1 ? 'Bireysel kayıt tipinde sadece bir öğrenci ekleyebilirsiniz.' : ''}>
                            <Button type='dashed' disabled={prePlacements?.count == 1} icon={<PlusCircleOutlined />} onClick={() => { setIsOpenStudentDrawer(true); }}>
                                Öğrenci Ekle
                            </Button>
                        </Tooltip>
                    </div>
                    <EnrollmentStudentList
                        prePlacements={prePlacementsLoading ? undefined : prePlacements?.data}
                        isEdit={true} isDelete={true}
                        onDelete={
                            async (user: PrePlacement) => {
                                if (user && user.id) {
                                    const { ok } = await API.ENROLLMENT.deletePrePlacement(user.id);
                                    if (ok) {
                                        setIsOpenStudentDrawer(false);
                                        getPrePlacements(undefined, prePlacementsQuery);
                                        setSelectedPrePlacement(undefined);
                                        notification.success({ message: 'Öğrenci silindi.' });
                                    }
                                }
                            }
                        }
                        onEdit={
                            (user: PrePlacement) => {
                                setSelectedPrePlacement(user);
                            }
                        }
                        onUpdateCalendar={enrollment && (enrollment.status == EnrollmentStatus.INFO || enrollment.status == EnrollmentStatus.READY_FOR_PLANNING || enrollment.status == EnrollmentStatus.STARTED) ?
                            (user: PrePlacement) => {
                                setSelectedPrePlacementCalendar({
                                    id: user.id!,
                                    calendar: user.availabilityCalendar!
                                });
                            } : undefined
                        }
                    />
                    <div className='flex w-full justify-center'>
                        <Pagination align='center'
                            total={prePlacements?.count}
                            onChange={(page) => {
                                setPrePlacementsQuery({ ...prePlacementsQuery, page: page });
                            }}
                            pageSize={prePlacements?.limit}
                            current={prePlacements?.page}
                        />
                    </div>
                </div>
                {/* TODO: Öğrenci ekleme ekrani bitiş */}
                {/* TODO: Veli ekleme ekrani başlangıç */}
                <div className='w-full flex flex-col space-y-2'>
                    <div className='flex flex-row justify-between items-center'>
                        <span className='text-lg font-medium'>Gözetmen Listesi</span>
                        <Tooltip title={!enrollment ? 'Öğrenci kaydı oluşturulmadı.' : ''}>
                            <Button type='dashed' disabled={!enrollment || enrollment.status == EnrollmentStatus.CANCELED || enrollment.status == EnrollmentStatus.DONE} icon={<PlusCircleOutlined />} onClick={() => { setIsOpenObserverDrawer(true); }}>
                                Gözetmen Ekle
                            </Button>
                        </Tooltip>
                    </div>
                    <EnrollmentObserverList
                        observers={observers?.data}
                        isDelete={true} isEdit={true}
                        onDelete={
                            async (user: Observer) => {
                                if (user && user.id) {
                                    const { ok } = await API.ENROLLMENT.deleteObserver(user.id);
                                    if (ok) {
                                        getObservers(undefined, observersQuery);
                                        setSelectedObserver(undefined);
                                        notification.success({ message: 'Gözetmen silindi.' });
                                    }
                                }
                            }
                        }
                        onEdit={(observer: Observer) => {
                            if (observer.id) {
                                setSelectedObserver(observer);
                            }
                        }}
                    />
                    <div className='flex w-full justify-center'>
                        <Pagination align='center'
                            total={observers?.count}
                            onChange={(page) => {
                                setObserversQuery({ ...observersQuery, page: page });
                            }}
                            pageSize={observers?.limit}
                            current={observers?.page}
                        />
                    </div>
                </div>
                {/* TODO: Veli ekleme ekrani bitiş */}

                <div className='flex flex-row space-x-2'>
                    <Button type='dashed' onClick={() => { goBack(); }}>Kayıt Listesine Dön</Button>
                    {
                        enrollment && enrollment.status == EnrollmentStatus.INFO &&

                        <Popconfirm
                            title={"Kayıt tamamlamak istediğinizden emin misiniz?"}
                            description={"Kayıt tamamlandıktan sonra, o kaydı iptal etmek mümkün olmayacaktır."}
                            onConfirm={() => { onApproval(); }}
                            okText="Evet"
                            cancelText="İptal"
                            onCancel={() => notification.error({ message: "Eklenmedi" })}>
                            <Button type='primary'>Kaydı Planlamaya Gönder</Button>
                        </Popconfirm>
                    }
                </div>

            </div>
        </>
    );
};

CreateIndividualEnrollmentRequest.path = '/crm/create-individual-enrollment-request';
CreateIndividualEnrollmentRequest.title = 'Yeni Bireysel Kayıt';
CreateIndividualEnrollmentRequest.group = 'temp-record';
CreateIndividualEnrollmentRequest.roles = [Role.ADMIN];
CreateIndividualEnrollmentRequest.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.CRM];
CreateIndividualEnrollmentRequest.permissions = ['branch.lms.enrollment.create'];
