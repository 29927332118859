import { DeleteOutlined, InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import * as API from '@shared/api';
import { AvailabilityWeekCalendar, BirthdayCalendar, EmailSearch, NationalIdInput, PhoneInput } from "@shared/components";
import { Gender } from "@shared/constants";
import { useForm } from "@shared/hooks";
import { AvailabilityCalendar, EducationLevel, PaginationQuery, PrePlacement } from "@shared/models";
import { Button, DatePicker, Form, Input, Modal, notification, Radio, Select, Table, TableColumnsType, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { TableProps } from "antd/lib/table/InternalTable";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { z } from "zod";


const CreatePrePlacementForm = z.object({
    name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
    surname: z.string({ required_error: 'Soyadı zorunlu alandır' }).min(1, 'Soyadı 1 karekterden fazla olmalı'),
    email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
    nationalId: z.string({ required_error: 'TC/VKN zorunlu alandır' }).min(1),
    'phone.number': z
        .string({ required_error: 'Telefon numarası zorunlu alandır' })
        .min(10, 'Lütfen telefon numarası giriniz')
        .refine((p) => {
            //TODO phone validasyon için kullanabilir
            return true;
        }),
    prePlacementDemands: z.array(z.object({ level: z.object({ id: z.string(), name: z.string() }) })).nonempty({ message: 'Eğitim tür ve seviyesini seçiniz.' }),
    gender: z.string({ required_error: 'Lütfen cinsiyet seçimi yapınız' }),
    birthDate: z.string({ required_error: 'Lütfen doğum günü seçimi yapınız' }).min(1),
    preferredStartDate: z.string({ required_error: 'En yakın başlangıç tarihini seçiniz' }),
});

type CreateEnrollmentStudentFormProps = {
    prePlacement?: PrePlacement;
    onSave: (prePlacement: PrePlacement) => Promise<boolean>;
    onUpdate: (prePlacement: PrePlacement) => Promise<boolean>;
    isDisabled?: boolean;
}

export const CreateEnrollmentStudentForm = ({ prePlacement, onSave, onUpdate, isDisabled = false }: CreateEnrollmentStudentFormProps) => {

    const form = useForm<
        Partial<{
            id: string;
            name: string;
            surname: string;
            email: string;
            nationalId: string;
            'phone.code': string;
            'phone.number': string;
            note: string;
            prePlacementDemands: any[];
            gender: string;
            birthDate: string;
            preferredStartDate: string;
        }>,
        PrePlacement
    >(CreatePrePlacementForm, {});

    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, orders: ['+theme.id', '+order'],active:true });
    const [educationLevel, loadingEducationLevel, getEducationLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: false, initParams: {} });
    const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.BRANCH.useFetchTheme({ init: true, initParams: { active: true, limit: 1000 } });
    const [filterOptions, setFilterOptions] = useState([]);

    const [availabilityCalendar, setAvailabilityCalendar] = useState<AvailabilityCalendar>({});

    const [selectedRowKeysForEducationLevelId, setSelectedRowKeysForEducationLevelId] = useState<React.Key[]>([]);
    const [selectedEducationLevels, setSelectedEducationLevels] = useState<EducationLevel[]>([]);


    const [isEmailSearch, setIsEmailSearch] = useState(false);
    const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);
    const [levelSearch, setLevelSearch] = useState<string>('');
    const [isOpenEducationLevelModal, setIsOpenEducationLevelModal] = useState(false);
    const [isEditMod, setIsEditMod] = useState<boolean>(false);

    useEffect(() => {
        if (prePlacement?.id) {
            setIsEditMod(true);
            const levelRowKeys: React.Key[] = [];
            const levels: EducationLevel[] = [];
            prePlacement.prePlacementDemands?.forEach(({ level }) => {
                levelRowKeys.push(level.id!);
                levels.push(level);
            });
            setSelectedRowKeysForEducationLevelId(levelRowKeys);
            setSelectedEducationLevels(levels);
            setAvailabilityCalendar({ ...prePlacement.availabilityCalendar! });
            setIsEmailSearch(true);
            setIsEmailSearchFound(true);
            form.setInitialValue({
                ...prePlacement,
            });
        } else {
            setIsEditMod(false);
            form.setInitialValue({});
            setSelectedRowKeysForEducationLevelId([]);
            setSelectedEducationLevels([]);
            setAvailabilityCalendar({});
            setIsEmailSearch(false);
            setIsEmailSearchFound(false);
        }
    }, [prePlacement?.id]);

    useEffect(() => {
        if (paginationQuery) {
            getEducationLevel(undefined, paginationQuery);
        }
    }, [paginationQuery]);

    useEffect(() => {
        if (theme?.data) {
            const filter = theme.data.map((t) => {
                return { label: t.name!, value: t.id! };
            });
            setFilterOptions(filter as never[]);
        }
    }, [theme]);


    const onSearchResult = (data?: PrePlacement) => {
        if (data) {
            form.setInitialValue({
                ...form.value,
                email: data.email,
                name: data.name,
                surname: data.surname,
                phone: data.phone,
                gender: data.gender,
                nationalId: data.nationalId,
                birthDate: data.birthDate,
            });

            setIsEmailSearchFound(true);
        } else {
            setIsEmailSearchFound(false);
            form.setInitialValue({
                ...form.value,
                name: undefined,
                surname: undefined,
                phone: {
                    code: undefined,
                    number: undefined,
                },
                gender: undefined,
                nationalId: undefined,
                birthDate: undefined,
            });
        }
        setIsEmailSearch(true);
    };

    const columnEducationLevel: TableColumnsType<EducationLevel> = [
        {
            title: 'Eğitim Türü',
            dataIndex: 'theme.type.field.name',
            key: 'field',
            align: 'center',
            render: (_, { theme }) => <>{theme?.type?.field?.name}</>,
        },
        {
            title: 'Kategori',
            dataIndex: 'theme.type.name',
            key: 'type',
            align: 'center',
            render: (_, { theme }) => <>{theme?.type?.name}</>,
        },
        {
            title: 'Tür',
            dataIndex: 'theme.name',
            key: 'theme',
            align: 'center',
            render: (_, { theme }) => <>{<Tag color="magenta">{theme?.name}</Tag>}</>,
        },
        {
            title: 'Kur Adı',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            sorter: (a, b) => a.name!.length - b.name!.length,
        },
        {
            title: 'Toplam ders süresi',
            key: 'durationInMinutes',
            align: 'center',
            render: (_, { durationInMinutes }) => <a>{durationInMinutes} dakika</a>,
        },
    ];

    const rowSelection: TableProps<EducationLevel>['rowSelection'] = {
        type: 'checkbox',
        selectedRowKeys: selectedRowKeysForEducationLevelId,
        onChange: (selectedRowKeys: React.Key[], selectedRows: EducationLevel[]) => {

            let _selectedRowKeysForEducationLevelId = selectedRowKeysForEducationLevelId;
            let _selectedEducationLevels = [...selectedEducationLevels];

            if (selectedRowKeys.length == educationLevel?.data?.length) {
                selectedRowKeys.forEach((key) => {
                    if (selectedRowKeysForEducationLevelId.indexOf(key) == -1) {
                        _selectedRowKeysForEducationLevelId.push(key);
                    }
                });
                selectedRows.forEach((row) => {
                    if (selectedEducationLevels.findIndex((l) => l.id == row.id) == -1) {
                        _selectedEducationLevels.push(row);
                    }
                });
            } else if (selectedRowKeys.length == 0) {
                _selectedRowKeysForEducationLevelId = selectedRowKeysForEducationLevelId.filter((key) => {
                    return educationLevel?.data?.findIndex((l) => l.id == key) == -1;
                });
                _selectedEducationLevels = selectedEducationLevels.filter((row) => {
                    return educationLevel?.data?.findIndex((l) => l.id == row.id) == -1;
                });
            } else {
                const unCheckList = educationLevel?.data?.filter((key) => {
                    return selectedRowKeys.indexOf(key.id!) == -1;
                });

                selectedRowKeys.forEach((key) => {
                    if (selectedRowKeysForEducationLevelId.indexOf(key) == -1) {
                        _selectedRowKeysForEducationLevelId.push(key);
                    }
                });

                selectedRows.forEach((row) => {
                    if (selectedEducationLevels.findIndex((l) => l.id == row.id) == -1) {
                        _selectedEducationLevels.push(row);
                    }
                });

                if (unCheckList && unCheckList.length > 0) {
                    let _rowKeyList = _selectedRowKeysForEducationLevelId;
                    let _rowItemList = _selectedEducationLevels;
                    _rowKeyList = _selectedRowKeysForEducationLevelId.filter((key) => {
                        return unCheckList.findIndex((l) => l.id == key) == -1;
                    });

                    _rowItemList = _selectedEducationLevels.filter((row) => {
                        return unCheckList.findIndex((l) => l.id == row.id) == -1;
                    });

                    _selectedRowKeysForEducationLevelId = _rowKeyList;
                    _selectedEducationLevels = _rowItemList;
                }

            }

            setSelectedRowKeysForEducationLevelId(_selectedRowKeysForEducationLevelId);
            setSelectedEducationLevels(_selectedEducationLevels);
        },
    };

    const sendData = () => {
        let isCalendarError = false;
        let isEmpty = Object.values(availabilityCalendar).every((x) => x?.length === 0);
        if (Object.keys(availabilityCalendar).length == 0 || isEmpty) {
            isCalendarError = true;
        }
        const result = form.parse();
        if (result.success) {
            if (!isCalendarError) {
                const student: PrePlacement = result.data;
                if (student.id) {
                    onUpdate({ ...student, availabilityCalendar });
                } else {
                    try {
                        onSave({
                            ...student,
                            availabilityCalendar: availabilityCalendar,
                        }).then((v) => {
                            if (v) {
                                form.reset();
                                setAvailabilityCalendar({});
                                setSelectedEducationLevels([]);
                                setSelectedRowKeysForEducationLevelId([]);
                                setIsOpenEducationLevelModal(false);
                                setIsEmailSearch(false);
                                setIsEmailSearchFound(false);
                                setIsEditMod(false);
                            }
                        });
                    } catch (error) {
                        notification.error({ message: "Beklenmeyen bir hata oluştu" });
                    }
                }
            } else {
                notification.warning({ message: 'Lütfen müsaitlik takvimini doldurunuz', });
            }
        } else {
            form.setAllTouched();
        }
    }

    const setPrePlacementDemands = (levels: EducationLevel[]) => {
        form.setValue({
            ...form.value,
            prePlacementDemands: levels.map((i) => ({ level: i })),
        });
    }


    return (
        <>
            <Modal
                title={"Kur Seçimi"}
                width={1000}

                open={isOpenEducationLevelModal}
                onCancel={() => {
                    setIsOpenEducationLevelModal(false);
                    setPrePlacementDemands(selectedEducationLevels);
                }}
                onClose={() => {
                    setPrePlacementDemands(selectedEducationLevels);
                    setIsOpenEducationLevelModal(false);
                }}
                okText={"Seçili kurları ekle"}
                cancelText={"Iptal"}
                onOk={() => {
                    setIsOpenEducationLevelModal(false);
                    setPrePlacementDemands(selectedEducationLevels);
                }}
            >
                <div className="flex flex-col w-full space-y-5">
                    <div className="flex flex-row space-x-2">
                        <Input.Search
                            type="primary"
                            placeholder="Kur ara"
                            value={levelSearch}
                            onChange={(e)=>{
                                setLevelSearch(e.target.value);
                            }}
                            onSearch={(e) => {
                                setPaginationQuery({
                                    ...paginationQuery,
                                    page: 1,
                                    name: e,
                                    orders: ['+theme.id', '+order'],
                                });
                            }} />
                        <Select
                            placeholder={'Türe göre filtrele'}
                            value={paginationQuery ? paginationQuery['theme'] : undefined}
                            style={{ width: '100%' }}
                            options={filterOptions}
                            onChange={(e) => {
                                setPaginationQuery({
                                    ...paginationQuery,
                                    page: 1,
                                    theme: e,
                                    orders: ['+theme.id', '+order'],
                                });
                            }}
                        />
                        {
                            (paginationQuery.theme || paginationQuery.name) && <Button type="primary" onClick={() => {
                                setPaginationQuery({ ...paginationQuery, page: 1, orders: ['+theme.id', '+order'], theme: undefined, name: undefined });
                                setLevelSearch('');
                            }}>Filtreleri temizle</Button>
                        }
                    </div>
                    <Table
                        bordered
                        key={(Math.random() + 18).toString(36).substring(7)}
                        rowKey="id"
                        columns={columnEducationLevel}
                        dataSource={educationLevel?.data}
                        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, current: educationLevel?.page, total: educationLevel?.count, pageSize: educationLevel?.limit, position: ['bottomCenter'] }}
                        onChange={(pagination) => {
                            setPaginationQuery({ ...paginationQuery, page: pagination.current!, limit: pagination.pageSize!, orders: ['+theme.id', '+order'] });
                        }}
                        rowSelection={{ ...rowSelection }}
                        onRow={(row) => ({
                            onClick: () => {
                                if (!selectedRowKeysForEducationLevelId.includes(row.id!)) {
                                    const _newLevels = [...selectedEducationLevels, row];
                                    setSelectedEducationLevels(_newLevels);
                                    setSelectedRowKeysForEducationLevelId([...selectedRowKeysForEducationLevelId, row.id!]);
                                    setPrePlacementDemands(_newLevels);
                                } else {
                                    const _newLevels = selectedEducationLevels.filter((i) => i.id != row.id);
                                    setSelectedEducationLevels(_newLevels);
                                    setPrePlacementDemands(_newLevels);
                                    setSelectedRowKeysForEducationLevelId(selectedRowKeysForEducationLevelId.filter((key) => key !== row.id!));
                                }
                            }
                        })}
                    />
                </div>
            </Modal>
            <div className='flex flex-col w-full' >
                <Form layout="vertical">
                    <div className=" grid grid-cols-2 gap-x-2 gap-y-1">
                        <div className="col-span-2">
                            <EmailSearch
                                form={form}
                                isSearchOtherUser={!isEditMod}
                                onSearchResult={onSearchResult}
                                onClear={() => {
                                    form.setInitialValue({
                                        ...form.value,
                                        name: undefined,
                                        email: undefined,
                                        surname: undefined,
                                        phone: {
                                            code: undefined,
                                            number: undefined,
                                        },
                                        gender: undefined,
                                        nationalId: undefined,
                                        birthDate: undefined,
                                    });
                                    setIsEmailSearchFound(false);
                                    setIsEmailSearch(false);
                                }}
                                label={<span className="truncate text-black/45 font-normal">Öğrenci E-posta</span>}
                                //disabled={isPreview || isEmailSearchFound}
                                disabled={isEmailSearchFound}
                            />
                        </div>
                        <Form.Item {...form.formItem('name')} label={<span className="truncate text-black/45 font-normal">Öğrenci Adı</span>}>
                            <Input {...form.input('name')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                        </Form.Item>
                        <Form.Item {...form.formItem('surname')} label={<span className="truncate text-black/45 font-normal">Öğrenci Soyadı</span>}>
                            <Input {...form.input('surname')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                        </Form.Item>
                        <div className="col-span-2">
                            <PhoneInput form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} label={<span className="truncate text-black/45 font-normal">Öğrenci Telefon</span>} />
                        </div>

                        <Form.Item {...form.formItem('birthDate')} label={<span className="truncate text-black/45 font-normal">Doğum Tarihi</span>}>
                            <BirthdayCalendar form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} />
                        </Form.Item>
                        <NationalIdInput form={form} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound} label={<span className="truncate text-black/45 font-normal">Öğrenci TC No:</span>} />

                        <Form.Item {...form.formItem('gender')} label={<span className="truncate text-black/45 font-normal">Cinsiyet</span>}>
                            <Radio.Group {...form.input('gender')} disabled={/*isPreview ||*/ !isEmailSearch || isEmailSearchFound}>
                                <Radio value={Gender.FEMALE}>Kadın</Radio>
                                <Radio value={Gender.MALE}>Erkek</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div className='flex flex-col space-y-2'>
                        <span className='text-base font-medium'> Eğitim Bilgileri</span>
                        <div className='flex flex-row justify-end'>
                            <Button size="small" type='dashed' disabled={isDisabled} icon={<PlusCircleOutlined />} onClick={() => { setIsOpenEducationLevelModal(true); }}>Kur Ekle</Button>
                        </div>
                        <Form.Item {...form.formItem('prePlacementDemands')}>
                            <div className='flex flex-col space-y-1'>
                                {
                                    selectedEducationLevels.length > 0
                                        ?
                                        (
                                            <>
                                                {selectedEducationLevels.map((level) => (
                                                    <div key={level.id} className='border-dashed border-gray-300 rounded-md p-1'>
                                                        <div className='flex flex-row items-center justify-between'>
                                                            <div>
                                                                <span className='text-sm font-medium'>{level.theme?.type?.field?.name} / {level.theme?.type?.name} / {level.theme?.name} / {level.name}</span>
                                                            </div>
                                                            <Button type="default" icon={<DeleteOutlined />} disabled={isDisabled} onClick={() => {
                                                                const _newLevels = selectedEducationLevels.filter((i) => i.id != level.id);
                                                                setSelectedEducationLevels(_newLevels);
                                                                setSelectedRowKeysForEducationLevelId(selectedRowKeysForEducationLevelId.filter((key) => key !== level.id!));
                                                                setPrePlacementDemands(_newLevels);
                                                            }} />

                                                        </div>
                                                    </div>
                                                ))}

                                            </>
                                        ) : (
                                            <div className='border-dashed border-gray-300 rounded-md p-1'>
                                                <div className='flex flex-row items-center'>
                                                    <InboxOutlined className='text-3xl text-gray-400' />
                                                    <span className='text-sm font-medium ml-2 text-gray-400'>Henüz kur seçimi yapılmadı</span>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </Form.Item>

                        <Form.Item {...form.formItem('preferredStartDate')} label={<span className="truncate text-black/45 font-normal">Başlangıç Tarihi</span>}>
                            <DatePicker placeholder="Başlangıç tarihi seçiniz" {...form.inputDate('preferredStartDate')} style={{ width: '100%' }} minDate={dayjs()} disabled={/*isPreview*/ isDisabled} />
                        </Form.Item>
                        <div className="col-span-2">
                            <Form.Item {...form.formItem('note')} label={<span className="truncate text-black/45 font-normal">Öğrenci Notları</span>}>
                                <TextArea rows={4} {...form.input('note')} disabled={/*isPreview*/ isDisabled} />
                            </Form.Item>
                        </div>

                        <div className="col-span-2 space-y-3">
                            <label className="text-black/45 font-normal">Öğrencinin Müsaitlik Takvimi</label>
                            <AvailabilityWeekCalendar
                                value={availabilityCalendar}
                                disabled={/*isPreview || !isEmailSearch*/ isDisabled}
                                onChange={(availability) => {
                                    /*if (!isPreview) {
                                        setAvailabilityCalendar(availability);
                                    }*/
                                    setAvailabilityCalendar(availability);
                                }}
                            />
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <Button type='primary' onClick={sendData}>{isEditMod ? "Kaydı Güncelle" : "Kaydı Oluştur"}</Button>
                            {
                                !isEditMod && <Button type='default' onClick={() => { 
                                    form.reset(); 
                                    setAvailabilityCalendar({});
                                    setSelectedEducationLevels([]);
                                    setSelectedRowKeysForEducationLevelId([]);

                                 }}>Temizle</Button>
                            }

                        </div>

                    </div>
                </Form>
            </div>
        </>
    );
}