import { BookOutlined, CaretRightOutlined, CheckOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useWindowSize } from '@shared/hooks';
import {
  ChoiceAnswerResponse,
  ContentTopicMeta,
  ContentTopicTree,
  ContentUnitMeta,
  ContentUnitTree,
  ContentVersionNavigation,
  ExerciseAnswerRequestModel,
  MediaOrderAnswerResponse,
  OrderAnswerResponse,
} from '@shared/models';
import { Button, Card, Divider, Dropdown, Progress, Select, Spin, Tag } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useCallback, useEffect, useState } from 'react';
import * as API from '@shared/api';
import { ExerciseOrPageHandler } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { IoReturnDownBack } from 'react-icons/io5';

export const StudentCourseContent = () => {
  useAccessableSeachParams();
  const { width, height, sm, md, lg } = useWindowSize();
  const [levels, levelsLoading, getStudentLevels] = API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentLevels({ init: true });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [levelOptions, setLevelOptions] = useState<DefaultOptionType[]>([]);
  const [selectedLevelId, setSelectedLevelId] = useState<string>();
  const [units, setUnits] = useState<ContentUnitTree[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<ContentUnitMeta>();
  const [unitsLoading, setUnitsLoading] = useState<boolean>(false);
  const [topics, setTopics] = useState<ContentTopicTree[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<ContentTopicMeta>();
  const [topicsLoading, setTopicsLoading] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<ContentVersionNavigation>();
  const [isExerciseMode, setIsExerciseMode] = useState<boolean>(false);
  const [selectedPageLoading, setSelectedPageLoading] = useState<boolean>(false);

  const [exercisePage, setExercisePage] = useState<number>(0);

  const [answerRequestModel, setAnswerRequestModel] = useState<ExerciseAnswerRequestModel>();
  const [answerResponseModel, setAnswerResponseModel] = useState<ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse>();

  //Mobil
  const [isTopicListOpen, setIsTopicListOpen] = useState<boolean>(false);
  const [isLessonOpen, setIsLessonOpen] = useState<boolean>(false);

  useEffect(() => {
    if (levels) {
      const options = levels.map((l) => ({
        label: l.theme?.type?.field?.name + '/' + l.theme?.type?.name + '/' + l.theme?.name + '/' + l.name,
        value: l.id,
      }));

      setLevelOptions(options);
      setIsLoading(false);
      if (options.length == 1) {
        setSelectedLevelId(options[0].value);
      }
    }
  }, [levels]);



  useEffect(() => {
    if (selectedLevelId) {
      getContentUnits();
    }
  }, [selectedLevelId]);

  useEffect(() => {
    if (selectedUnit) {
      getContentTopics();
    }
  }, [selectedUnit?.id]);

  useEffect(() => {
    if (selectedTopic) {
      getContentPages();
    }
  }, [selectedTopic?.id]);

  const getContentUnits = async () => {
    if (selectedLevelId) {
      setUnitsLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentUnits(selectedLevelId);
      if (ok) {
        setUnits(data);
        if (!selectedUnit) {
          setSelectedUnit(data[0]);
        }
        setUnitsLoading(false);
      }

    }
  };

  const getContentTopics = async () => {
    if (selectedUnit && selectedLevelId) {
      setTopicsLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentTopics(selectedLevelId, selectedUnit.id);
      if (ok) {
        setTopics(data);
        setTopicsLoading(false);
      }

    }
  };

  const getContentPages = async () => {
    if (selectedLevelId && selectedUnit && selectedTopic) {
      setIsExerciseMode(true);
      setSelectedPageLoading(true);
      const { ok, data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.getContentPages(selectedLevelId, selectedUnit.id, selectedTopic.id);
      if (ok) {
        setSelectedPage(data);
        setSelectedPageLoading(false);
      }
    }
  };


  const goToNextPage = useCallback(async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToNextPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setExercisePage(0);
      setSelectedPage(data);

    }
  }, [exercisePage, selectedPage, selectedLevelId, selectedPage, selectedUnit, selectedTopic]);

  /*const goToNextPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToNextPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      
      setSelectedPage(data);
    }
  };*/

  const goToPrevPage = async () => {
    if (selectedLevelId && selectedPage && selectedUnit && selectedTopic) {
      const { data } = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.goToPrevPage(selectedLevelId, selectedUnit.id, selectedTopic.id, selectedPage.page.id!);
      setSelectedPage(data);
    }
  };

  const progressCircle = (percent: number) => {
    return (
      <Progress
        status="success"
        type="circle"
        size={25}
        percent={percent}
        strokeWidth={5}
        showInfo={true}
        format={(v, s) => {
          if (v == 100) {
            return <CheckOutlined className="text-[#722ED1] text-lg font-extrabold" />;
          } else {
            return <CaretRightOutlined className="text-[#722ED1] text-lg font-extrabold" />;
          }
        }}
        trailColor="#D3ADF7"
        strokeColor={'#722ED1'}
      />
    );
  };

  const onCheckAnswer = useCallback(async () => {
    try {
      const response = await API.CURRICULUM.STUDENT_LEARNING_CONTENT.studentExerciseAnswer(
        selectedPage?.page.exercise?.id!,
        selectedPage?.page.exercise?.exercises![exercisePage].id!,
        answerRequestModel!
      );
      if (response.ok) {
        setAnswerResponseModel(response.data);
      }
    } catch (error) {
      console.log("Student Course Content Error: " + error);
    }
  }, [selectedPage, selectedPage?.page, answerRequestModel]);

  //Webten açıldıysa
  if (lg) {
    return (
      <>
        {
          isLoading ? (
            <div className='flex flex-col h-96 w-full text-center items-center justify-center content-center'>
              <Spin size="default" />
              <span className='text-purple-400'>Öğrenci İçerikleri Getiriliyor...</span>
            </div>
          ) : (<div className="grid grid-cols-12">
            <div className="flex flex-col items-start col-span-3">
              {levelOptions.length == 1 ? (
                <span>{levelOptions[0].label}</span>
              ) : (
                <Select
                  size="large"
                  value={selectedLevelId}
                  placeholder={'Lütfen bir seçim yapınız'}
                  loading={levelsLoading}
                  style={{ width: '15vw' }}
                  onChange={(value) => {
                    setSelectedLevelId(value);
                  }}
                  options={levelOptions}
                />
              )}

              <span className="text-lg font-sans p-2">Üniteler</span>
              <div className="w-full p-1 overflow-y-auto scrollbar-hide " style={{ height: 'calc(100vh - 185px)' }}>
                {unitsLoading ? (
                  <div className='flex flex-col justify-center items-center h-full w-full'>
                    <Spin />
                    <span className='text-purple-400'>Üniteler Listeleniyor...</span>
                  </div>
                ) : (
                  <>
                    {units.map((unit) => {
                      return (
                        <Card
                          size="small"
                          key={unit.id}
                          className={selectedUnit?.id == unit.id ? 'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg' : 'bg-[#FFFEFE] mb-2 shadow-md rounded-lg'}
                          hoverable
                          onClick={() => {
                            if (selectedPage) {
                              setSelectedPage(undefined);
                              setIsExerciseMode(false);
                              setAnswerRequestModel(undefined);
                              setAnswerResponseModel(undefined);
                            }
                            setSelectedUnit(unit);
                            setExercisePage(0);
                          }}
                        >
                          <div className="flex flex-row justify-between  ">
                            <span className={selectedUnit && (selectedUnit.id == unit.id) ? "text-[#722ED1]" : ""}>
                              {unit.name}
                            </span>
                            {progressCircle(unit.percent)}
                          </div>
                        </Card>
                      );
                    })}
                  </>
                )}

              </div>
            </div>

            <div className="w-full flex col-span-9 content-center justify-center items-center scrollbar-hide " style={{ height: 'calc(100vh - 185px)' }}>

              <div className={selectedPage ? 'h-full w-[30rem]' : 'w-[30rem] h-full'}>
                {
                  isExerciseMode ? (
                    <div className="w-full" style={{ height: 'calc(100vh - 110px)' }}>
                      {
                        selectedPageLoading ? (
                          <div className='flex flex-col justify-center items-center h-full w-full'>
                            <Spin />
                            <span className='text-purple-400'>Egzersiz Getiriliyor...</span>
                          </div>
                        ) : (
                          <>
                            {
                              selectedPage ? (
                                <ExerciseOrPageHandler
                                  exercisePage={exercisePage}
                                  page={selectedPage.page}
                                  cdnScope={CdnScope.BRANCH}
                                  isInClass={false}
                                  isPreview={false}
                                  selectedTopic={selectedPage.meta.topic}
                                  onNext={() => {
                                    if (selectedPage && selectedPage.meta.hasNext) {
                                      goToNextPage();
                                    } else {
                                      setSelectedPage(undefined);
                                      setExercisePage(0);
                                    }
                                  }}
                                  onPrev={() => {
                                    if (selectedPage && selectedPage.meta.hasPrev) {
                                      goToPrevPage();
                                    } else {
                                      setSelectedPage(undefined);
                                      setExercisePage(0);
                                    }
                                  }}
                                  onChangeAnswerRequestModel={(model) => {
                                    if (!model) {
                                      setAnswerResponseModel(undefined);
                                    }

                                    setAnswerRequestModel(model);
                                  }}
                                  onChangeExercisePage={setExercisePage}
                                  onSendDialogResult={onCheckAnswer}
                                  answerRequestModel={answerRequestModel}
                                  onCheckAnswer={onCheckAnswer}
                                  answerResponseModel={answerResponseModel}
                                  onResponseModelVisualBase={(success: boolean) => setAnswerResponseModel({ success })}
                                />
                              ) : (
                                <div>Egzersiz bulunumadı</div>
                              )
                            }
                          </>
                        )
                      }
                    </div>
                  ) : (
                    <>
                      {
                        topicsLoading ? (
                          <div className='flex flex-col justify-center items-center h-full w-full'>
                            <Spin />
                            <span className='text-purple-400'>Konular Listeleniyor...</span>
                          </div>
                        ) : (
                          topics.map((topic) => {
                            return (
                              <Card
                                key={topic.id}
                                className="mb-2 bg-[#F9F0FF] p-0 border-[#F0DBFF] hover:border-[#bb76ec]"
                                hoverable
                                size="small"
                                onClick={() => {
                                  if (selectedTopic && (selectedTopic.id == topic.id)) {
                                    getContentPages();
                                  } else {
                                    setSelectedTopic(topic);
                                  }
                                  setExercisePage(0);
                                }}
                                title={
                                  <div className="flex flex-row justify-between">
                                    <span className="text-[#722ED1] text-sm font-normal">{topic.name}</span>
                                    {progressCircle(topic.percent)}
                                  </div>
                                }
                              >
                                <div className="bg-[#F4E5FF] p-2 rounded-md">
                                  <Tag color="#F9F0FF">
                                    <span className="text-[#722ED1] text-xs">{topic.type}</span>
                                  </Tag>
                                  <div className="flex flex-row justify-between content-center items-center space-x-2 mt-1">
                                    <Progress
                                      percent={topic.percent}
                                      showInfo={false}
                                      className="w-full"
                                      strokeColor={{
                                        '0%': '#F9E681',
                                        '48%': '#F69D35',
                                        '100%': '#F60000',
                                      }}
                                    />
                                    <span className="text-sm text-[#722ED1]">{'%' + topic.percent}</span>
                                  </div>
                                </div>
                              </Card>
                            );
                          })
                        )
                      }
                    </>
                  )}
              </div>
            </div>
          </div>)
        }

      </>
    );
  }

  //Mobil ise
  return (
    <>
      <div className="">
        {!isTopicListOpen ? (
          <div className="flex flex-col mt-5 w-11/12 mx-auto">
            {levelOptions.length == 1 ? (
              <span>{levelOptions[0].label}</span>
            ) : (
              <Select
                size="large"
                value={selectedLevelId}
                placeholder={'Lütfen bir seçim yapınız'}
                loading={levelsLoading}
                style={{ width: '15vw' }}
                onChange={(value) => {
                  setSelectedLevelId(value);
                }}
                options={levelOptions}
              />
            )}
            <span className="text-lg font-sans p-2 mt-2">Üniteler</span>
            <div className="w-full p-1 overflow-y-auto scrollbar-hide " style={{ height: 'calc(100vh - 185px)' }}>
              {units.map((unit) => {
                return (
                  <Card
                    size="small"
                    key={unit.id}
                    className={selectedUnit?.id == unit.id ? 'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg' : 'bg-[#FFFEFE] mb-2 shadow-md rounded-lg'}
                    hoverable
                    onClick={() => {
                      setSelectedUnit(unit);
                      setIsTopicListOpen(true);
                      setExercisePage(0);
                    }}
                  >
                    <div className="flex flex-row justify-between  ">
                      <span className={selectedUnit && (selectedUnit.id == unit.id) ? "text-[#722ED1]" : ""}>
                        {unit.name}
                      </span>
                      {progressCircle(unit.percent)}
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        ) : !isLessonOpen || !selectedPage ? (
          <div className="mt-5 w-11/12 mx-auto">
            <Card
              size="small"
              className={'bg-[#f3e2ff] border-[#F0DBFF] border-2 mb-2 shadow-md rounded-lg'}
              onClick={() => {
                setIsTopicListOpen(false);
              }}
            >
              <div className="flex flex-row items-center">
                <Button type='link' icon={<IoReturnDownBack style={{ color: '#3F0A70' }} />} />
                <span className="text-[#3F0A70]">{selectedUnit?.name}</span>
              </div>
            </Card>
            <div className="mt-5">
              {topics.map((topic) => {
                return (
                  <Card
                    key={topic.id}
                    className="mb-2 bg-[#F9F0FF] p-0 border-[#F0DBFF] hover:border-[#bb76ec]"
                    hoverable
                    size="small"
                    onClick={() => {
                      setIsLessonOpen(true);
                      setSelectedTopic(topic);
                      setExercisePage(0);
                    }}
                    title={
                      <div className="flex flex-row justify-between">
                        <span className="text-[#722ED1] text-sm font-normal">{topic.name}</span>
                        {progressCircle(topic.percent)}
                      </div>
                    }
                  >
                    <div className="bg-[#F4E5FF] p-2 rounded-md">
                      <Tag color="#F9F0FF">
                        <span className="text-[#722ED1] text-xs">{topic.type}</span>
                      </Tag>
                      <div className="flex flex-row justify-between content-center items-center space-x-2 mt-1">
                        <Progress
                          percent={topic.percent}
                          showInfo={false}
                          className="w-full"
                          strokeColor={{
                            '0%': '#F9E681',
                            '48%': '#F69D35',
                            '100%': '#F60000',
                          }}
                        />
                        <span className="text-sm text-[#722ED1]">{'%' + topic.percent}</span>
                      </div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="w-full h-full items-center mx-auto mt-5">
            <ExerciseOrPageHandler
              exercisePage={exercisePage}
              page={selectedPage.page}
              cdnScope={CdnScope.BRANCH}
              isInClass={false}
              isPreview={false}
              selectedTopic={selectedPage.meta.topic}
              onNext={() => {
                if (selectedPage && selectedPage.meta.hasNext) {
                  goToNextPage();
                } else {
                  setSelectedPage(undefined);
                }
              }}
              onPrev={() => {
                if (selectedPage && selectedPage.meta.hasPrev) {
                  goToPrevPage();
                } else {
                  setSelectedPage(undefined);
                }
              }}
              onMobileGoBack={() => {
                setSelectedPage(undefined);
                setIsLessonOpen(false);
              }}
              onChangeAnswerRequestModel={(model) => {
                if (!model) {
                  setAnswerResponseModel(undefined);
                }

                setAnswerRequestModel(model);
              }}
              onChangeExercisePage={setExercisePage}
              onSendDialogResult={onCheckAnswer}
              answerRequestModel={answerRequestModel}
              onCheckAnswer={onCheckAnswer}
              answerResponseModel={answerResponseModel}
              onResponseModelVisualBase={(success: boolean) =>{
                setAnswerResponseModel({ success });}}
            />
          </div>
        )}
      </div>
    </>
  );
};

const SuccessPage = () => {
  return (
    <div></div>
  );
}

StudentCourseContent.path = '/' + LayoutEnums.STUDENT + '/course_content';
StudentCourseContent.title = 'Ders İçerikleri';
StudentCourseContent.group = 'flat';
StudentCourseContent.roles = [Role.ADMIN];
StudentCourseContent.layouts = [LayoutEnums.STUDENT];
StudentCourseContent.icon = <BookOutlined size={20} />;
