import { CalendarOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { Calendar } from '@shared/components';
import { LayoutEnums } from '@shared/constants';
import { getMonthRange } from '@shared/utils';
import { Card } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { StudentCalendarModel, StudentCalendarRenderer } from '../calendar/CalenderRenderers';

type StudetHeaderProps = {
  layout: LayoutEnums;
  name: string;
  surname: string;
  phone: string;
  email: string;
  accountId?: string;
};

export const StudentHeader = ({ layout, name, surname, phone, email, accountId }: StudetHeaderProps) => {
  const [calendarData, setCalendarData] = useState<StudentCalendarModel[]>();

  useEffect(() => {
    if (accountId) {
      fetchCalendar(dayjs());
    }
  }, [accountId]);

  //Hangi layouttan geldiysek (Öğrenci, Gözetmen, Şube Yöneticisi), Calendar verilerini o layouta göre alıyoruz.
  const fetchCalendar = async (date: Dayjs) => {
    const { startOfMonth, endOfMonth } = getMonthRange(date);

    let response: any;
    switch (layout) {
      case LayoutEnums.STUDENT:
        response = await API.STUDENT.getStudentCalendar(startOfMonth, endOfMonth);
        break;
      case LayoutEnums.OBSERVER:
        response = await API.OBSERVER.getObserverStudentCalendar(accountId!, startOfMonth, endOfMonth);
        break;
      case LayoutEnums.BRANCHMANAGER:
        if (accountId) {
          response = await API.ENROLLMENT.getBranchStudentCalendar(accountId, startOfMonth, endOfMonth);
        }
        break;
    }
    if (response && response.ok) {
      setCalendarData(response.data);
    }
  };

  return (
    <>
      <Card
        className="rounded-xl"
        style={{
          backgroundImage: 'url(' + require('../../../../statics/Hello.jpeg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex gap-5 w-full">
          <Card className="bg-white/40 backdrop-blur-md w-1/2">
            <div className="text-xl text-black">
              {name} {surname}
            </div>
            <div className="text-[#1677FF] mt-2 space-x-1 cursor-pointer">
              <Calendar
                events={calendarData}
                onDateChange={fetchCalendar}
                cellRenderer={StudentCalendarRenderer()}
                minimized={
                  <>
                    <CalendarOutlined /> <span>Öğrenci takvimini görüntüle</span>
                  </>
                }
              />
            </div>
          </Card>
          <Card className="bg-white/40 backdrop-blur-md w-1/2">
            <div className="text-black">İletişim Bilgileri</div>
            <div className="opacity-45 text-black mt-2 space-x-1">
              <MailOutlined /> <span>{email}</span>
            </div>
            <div className="opacity-45 text-black mt-2 space-x-1">
              <PhoneOutlined /> <span>{phone}</span>
            </div>
          </Card>
        </div>
      </Card>
    </>
  );
};
