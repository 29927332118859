import { CheckSquareOutlined, CheckSquareTwoTone, DeleteTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import { DayOfWeek } from '@shared/constants';
import { AddSeanceToClassModel, ClassDetailModel, ClassSeance, ClassSeanceItem, SessionDefinitionsModel } from '@shared/models';
import { Button, DatePicker, Form, Modal, notification, Popconfirm, Select } from 'antd';
import * as API from '@shared/api';
import { useState } from 'react';
import { TimeFormatter } from '@shared/components';
import dayjs, { Dayjs } from 'dayjs';

type ClassSettingsProps = {
  classDetail: ClassDetailModel | undefined;
  getClassDetail: CallableFunction;
};

export const ClassSettings = ({ classDetail, getClassDetail }: ClassSettingsProps) => {
  const [openAddSeanceModal, setOpenAddSeanceModal] = useState<boolean>(false);
  const [newSeanceTeacher, setNewSeanceTeacher] = useState<object>({});
  const [newSeanceClassroom, setNewSeanceClassroom] = useState<object>({});
  const [newSeanceDays, setNewSeanceDays] = useState<DayOfWeek[]>();
  const [selectedSeance, setSelectedSeance] = useState<SessionDefinitionsModel>();
  const [updatedClassroom, setUpdatedClassroom] = useState({});
  const [updatedTeacher, setUpdatedTeacher] = useState({});
  const [updatedDayOfWeek, setUpdatedDayOfWeek] = useState<DayOfWeek[]>();
  const [updatedEndDate, setUpdatedEndDate] = useState<Dayjs>();

  const [seanceList, seanceLoading] = API.SESSION_DEFINITIONS.getSessionData({ init: true });
  const [classroomList, classroomLoading] = API.CLASS_ROOM.getClassroomList({ init: true });
  const [teacherList, teacherLoading] = API.TEACHER.useFetch({ init: true, initParams: { withDeleted: false, levelId: classDetail?.level.id } });

  const onUpdateClassroom = async (classSeance: ClassSeance, classSeanceItem: ClassSeanceItem, index: number) => {
    const response = await API.CLASS.updateClasroom(classDetail?.id!, classSeance.id!, classSeanceItem.id!, updatedClassroom[index]);
    if (response.ok) {
      notification.success({ message: 'Derslik güncellenmiştir!' });
      getClassDetail();
    }
  };

  const onUpdateTeacher = async (classSeance: ClassSeance, classSeanceItem: ClassSeanceItem, index: number) => {
    const response = await API.CLASS.updateTeacher(classDetail?.id!, classSeance.id!, classSeanceItem.id!, updatedTeacher[index]);
    if (response.ok) {
      notification.success({ message: 'Öğretmen güncellenmiştir!' });
      getClassDetail();
    }
  };

  const onUpdateDays = async (classSeance: ClassSeance) => {
    const response = await API.CLASS.updateDayOfWeeks(classDetail?.id!, classSeance.id!, updatedDayOfWeek!);
    if (response.ok) {
      notification.success({ message: 'Günler güncellenmiştir!' });
      getClassDetail();
      setUpdatedDayOfWeek(undefined);
    }
  };

  const onUpdateEndDate = async () => {
    const response = await API.CLASS.updateClassEndDate(classDetail?.id!, updatedEndDate?.toDate());
    if (response.ok) {
      notification.success({ message: 'Sınıf Bitiş Tarihi Güncellenmiştir!' });
      getClassDetail();
    }
  };

  const onDeleteSeance = async (seance: ClassSeance) => {
    const response = await API.CLASS.removeSeanceFromClass(classDetail?.id!, seance.id!);
    if (response.ok) {
      notification.success({ message: 'Seans başarılı şekilde silinmiştir' });
      getClassDetail();
    }
  };

  const resetNewSeance = () => {
    setSelectedSeance(undefined);
    setNewSeanceTeacher({});
    setNewSeanceClassroom({});
    setNewSeanceDays([]);
  };

  const createNewSeance = async () => {
    const combinedSeanceItems = selectedSeance?.seanceItems?.map((item, index) => ({
      beginHour: item.begin?.hour,
      beginMinute: item.begin?.minute,
      endHour: item.end?.hour,
      endMinute: item.end?.minute,
      classRoomId: newSeanceClassroom[index],
      teacherId: newSeanceTeacher[index],
    }));

    const newSeance: AddSeanceToClassModel = {
      seanceId: selectedSeance?.id!,
      name: selectedSeance?.name,
      dayOfWeeks: newSeanceDays!,
      durationInMinutes: selectedSeance?.durationInMinutes,
      classSeanceItems: combinedSeanceItems as any,
      order: 5,
    };

    const response = await API.CLASS.addSeanceToClass(classDetail?.id!, newSeance);
    if (response.status !== 404 && response.status !== 409 && response.status !== 422 && response.status !== 500) {
      getClassDetail();
      resetNewSeance();
      notification.success({ message: 'Seans başarıyla oluşturuldu' });
      setOpenAddSeanceModal(false);
    }
  };

  return (
    <>
      <div>
        <h3 className="text-xl">{classDetail?.name}</h3>
        <div className="flex justify-between">
          <DatePicker.RangePicker
            allowClear={false}
            disabled={[true, false]}
            defaultValue={[dayjs(classDetail?.calculatedStartDate), updatedEndDate ?? dayjs(classDetail?.calculatedEndDate)]}
            format="DD/MM/YYYY"
            onChange={([startDate, endDate]) => setUpdatedEndDate(endDate!)}
          />
          <Button
            className={`${updatedEndDate && !updatedEndDate?.isSame(dayjs(classDetail?.calculatedEndDate), 'day') ? 'visible' : 'invisible'}`}
            onClick={() => onUpdateEndDate()}
            type="dashed"
          >
            Bitiş Tarihini Güncelle
          </Button>
        </div>
        <div className="min-w-[270px] max-h-[400px] overflow-y-scroll">
          {classDetail?.classSeances?.map((seance) => (
            <div key={seance.id} className="mt-4 border border-solid border-[#f5f5f5] rounded p-2">
              <div className="flex justify-between px-2">
                <div>Seans</div>
                <Popconfirm placement="bottom" title="Seansı silmek istediğinize emin misiniz?" okText="Evet" onConfirm={() => onDeleteSeance(seance)} cancelText="İptal">
                  <DeleteTwoTone className="text-lg cursor-pointer" />
                </Popconfirm>
              </div>
              <div className="mt-3 py-2 px-2 bg-[#E6F4FF] rounded-lg">{seance.name}</div>
              {seance.classSeanceItems?.map((item, index) => (
                <div key={item.id}>
                  <div className="border border-solid border-[#91CAFF] rounded-md mt-3 p-1 text-sm">
                    <TimeFormatter startHour={item.beginHour!} startMinute={item.beginMinute!} endHour={item.endHour!} endMinute={item.endMinute!} />
                  </div>
                  <div className="grid grid-cols-5 mt-2 gap-x-5">
                    <div className="flex flex-col col-span-2">
                      <div>Derslik</div>
                      <div className="flex flex-row">
                        <Select
                          defaultValue={updatedClassroom[index] ?? item.classRoom?.id}
                          className="w-full"
                          options={classroomList?.data?.map((clasroom) => ({ label: clasroom.name!, value: clasroom.id! }))}
                          onChange={(newClassRoomId) => setUpdatedClassroom((prevState) => ({ ...prevState, [index]: newClassRoomId }))}
                        />
                        <CheckSquareOutlined
                          onClick={() => onUpdateClassroom(seance, item, index)}
                          className={`cursor-pointer text-3xl ml-1 text-success ${
                            updatedClassroom[index] && updatedClassroom[index] !== item.classRoom?.id ? 'visible' : 'invisible'
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col col-span-3">
                      <div>Öğretmen</div>
                      <div className="flex flex-row">
                        <Select
                          defaultValue={updatedTeacher[index] ?? item.teacher?.id}
                          className="w-full"
                          options={teacherList?.data?.map((teacher) => ({ label: `${teacher.name} ${teacher.surname}`, value: teacher.id! }))}
                          onChange={(newTeacherId) => setUpdatedTeacher((prevState) => ({ ...prevState, [index]: newTeacherId }))}
                        />
                        <CheckSquareOutlined
                          onClick={() => onUpdateTeacher(seance, item, index)}
                          className={`cursor-pointer text-3xl ml-1 text-success ${updatedTeacher[index] && updatedTeacher[index] !== item.teacher?.id ? '' : 'hidden'}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mt-2">Günler</div>
              <div className="flex flex-row mt-1">
                <Select
                  placement="bottomRight"
                  className="w-full"
                  mode="multiple"
                  allowClear
                  placeholder="Haftanın Günleri"
                  defaultValue={updatedDayOfWeek ?? seance.dayOfWeeks}
                  onChange={(days) => setUpdatedDayOfWeek(days)}
                  options={[
                    { label: 'Pazartesi', value: 0 },
                    { label: 'Salı', value: 1 },
                    { label: 'Çarşamba', value: 2 },
                    { label: 'Perşembe', value: 3 },
                    { label: 'Cuma', value: 4 },
                    { label: 'Cumartesi', value: 5 },
                    { label: 'Pazar', value: 6 },
                  ]}
                />
                <CheckSquareOutlined
                  onClick={() => onUpdateDays(seance)}
                  className={`cursor-pointer text-3xl ml-1 text-success ${
                    updatedDayOfWeek && updatedDayOfWeek.length > 0 && JSON.stringify(updatedDayOfWeek) != JSON.stringify(seance.dayOfWeeks) ? '' : 'hidden'
                  }`}
                />
              </div>
            </div>
          ))}
        </div>

        <Button className="w-full mt-5" icon={<PlusCircleOutlined />} type="primary" onClick={() => setOpenAddSeanceModal(true)}>
          Yeni Seans Oluştur
        </Button>
      </div>

      <Modal
        title={<span className="font-normal">Ayarlar</span>}
        open={openAddSeanceModal}
        footer={null}
        onCancel={() => {
          setOpenAddSeanceModal(false);
          resetNewSeance();
        }}
      >
        <>
          <h3 className="text-xl">{classDetail?.name}</h3>

          {selectedSeance ? (
            <>
              <div className="mt-4 border border-solid border-[#f5f5f5] rounded p-2">
                <div>Seans</div>
                <div className="mt-3 py-2 px-2 bg-[#E6F4FF] rounded-lg">{selectedSeance.name}</div>
                {selectedSeance.seanceItems?.map((item, index) => (
                  <div key={index}>
                    <div className="border border-solid border-[#91CAFF] rounded-md mt-3 p-1 text-sm">
                      <TimeFormatter startHour={item.begin?.hour!} startMinute={item.begin?.minute!} endHour={item.end?.hour!} endMinute={item.end?.minute!} />
                    </div>
                    <div className="grid grid-cols-5 mt-2 gap-x-3">
                      <div className="flex flex-col col-span-2">
                        <div>Derslik</div>
                        <Select
                          value={newSeanceClassroom[index]}
                          className="w-full"
                          options={classroomList?.data?.map((clasroom) => ({ label: clasroom.name!, value: clasroom.id! }))}
                          onChange={(newClassRoomId) => setNewSeanceClassroom((prevState) => ({ ...prevState, [index]: newClassRoomId }))}
                        />
                      </div>
                      <div className="flex flex-col col-span-3">
                        <div>Öğretmen</div>
                        <Select
                          value={newSeanceTeacher[index]}
                          className="w-full"
                          options={teacherList?.data?.map((teacher) => ({ label: `${teacher.name} ${teacher.surname}`, value: teacher.id! }))}
                          onChange={(newTeacherId) => setNewSeanceTeacher((prevState) => ({ ...prevState, [index]: newTeacherId }))}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="mt-2">Günler</div>
                <Select
                  placement="bottomRight"
                  className="w-full mt-1"
                  mode="multiple"
                  allowClear
                  placeholder="Haftanın Günleri"
                  value={newSeanceDays}
                  onChange={(days) => setNewSeanceDays(days)}
                  options={[
                    { label: 'Pazartesi', value: 0 },
                    { label: 'Salı', value: 1 },
                    { label: 'Çarşamba', value: 2 },
                    { label: 'Perşembe', value: 3 },
                    { label: 'Cuma', value: 4 },
                    { label: 'Cumartesi', value: 5 },
                    { label: 'Pazar', value: 6 },
                  ]}
                />
              </div>

              <div className="mt-5">
                <Button type="primary" onClick={createNewSeance}>
                  Seans Ekle
                </Button>
                <Button className="ml-3" onClick={resetNewSeance}>
                  Geri Dön
                </Button>
              </div>
            </>
          ) : (
            <Form.Item label="Seans">
              <Select
                value={selectedSeance}
                options={seanceList?.data?.map((seance) => ({ value: seance.id, label: seance.name }))}
                onChange={(e) => {
                  const seance = seanceList?.data?.find((s) => s.id === e);
                  setSelectedSeance(seance);
                }}
              />
            </Form.Item>
          )}
        </>
      </Modal>
    </>
  );
};
