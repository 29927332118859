import { DownloadOutlined } from '@ant-design/icons';
import { FileDownload } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { Role } from '@shared/constants';
import { StudentHomeworkProps } from '@shared/models';
import { convertDate } from '@shared/utils';
import { Divider } from 'antd';

type HomeworkDetailContentsDrawerProps = {
  studentHomework: StudentHomeworkProps;
};

export const HomeworkDetailContentsDrawer = ({ studentHomework }: HomeworkDetailContentsDrawerProps) => {
  return (
    <div>
      <Divider />
      <h3>Ödev İçeriği</h3>
      <div className="mt-4">
        <h4 className="mb-2">Ödev Tarihi</h4>
        <div className="opacity-45">
          {convertDate(studentHomework?.classHomework?.startDate)} - {convertDate(studentHomework?.classHomework?.dueDate)}
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mb-2">Ödev Açıklaması</h4>
        <div className="opacity-45">{studentHomework?.classHomework?.description}</div>
      </div>
      <div className="mt-4">
        <h4>Ödev Dosyaları</h4>
        <div className="mt-2">
          {studentHomework?.classHomework?.files?.map((file) => (
            <div className="mt-1">
              <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
