import { EyeTwoTone } from '@ant-design/icons';
import { EnrollmentUserCard } from '@platform/platform_components';
import * as API from '@shared/api';
import { AvailabilityWeekCalendar, BirthdayCalendar, EmailSearch, MultipleCascader, NationalIdInput, PhoneInput } from '@shared/components';
import { Gender } from '@shared/constants';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { AvailabilityCalendar, CascaderOption, EducationLevel, Enrollment, EnrollmentTypes, PrePlacement } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, Collapse, DatePicker, Form, Input, List, notification, Popover, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { z } from 'zod';

type CreatePrePlacementProp = {
  enrollment: Enrollment;
  prePlacements: PrePlacement[];
  value?: PrePlacement;
  onSave: (prePlacement: PrePlacement) => Promise<boolean>;
  onDelete: (id: string) => Promise<boolean>;
  onUpdate: (prePlacement: PrePlacement) => Promise<boolean>;
  isPreview?: boolean;
};

const CreatePrePlacementForm = z.object({
  name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyadı zorunlu alandır' }).min(1, 'Soyadı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  nationalId: z.string({ required_error: 'TC/VKN zorunlu alandır' }).min(1),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  prePlacementDemands: z.array(z.object({ level: z.object({ id: z.string(), name: z.string() }) })).nonempty({ message: 'Eğitim tür ve seviyesini seçiniz.' }),
  gender: z.string({ required_error: 'Lütfen cinsiyet seçimi yapınız' }),
  birthDate: z.string({ required_error: 'Lütfen doğum günü seçimi yapınız' }).min(1),
  preferredStartDate: z.string({ required_error: 'En yakın başlangıç tarihini seçiniz' }),
});
export const CreatePrePlacement = ({ enrollment, prePlacements, value, onSave, onDelete, onUpdate, isPreview = false }: CreatePrePlacementProp) => {
  useAccessableSeachParams();
  const form = useForm<
    Partial<{
      id: string;
      name: string;
      surname: string;
      email: string;
      nationalId: string;
      'phone.code': string;
      'phone.number': string;
      note: string;
      prePlacementDemands: any[];
      gender: string;
      birthDate: string;
      preferredStartDate: string;
    }>,
    PrePlacement
  >(CreatePrePlacementForm, {});

  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRANCH.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRANCH.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRANCH.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [levelOptions, setLevelOptions] = useState<CascaderOption[]>([]);
  const [levelCascaderValue, setLevelCascaderValue] = useState<(string | number)[][]>([]);
  const [enrollmentType, setEnrollmentType] = useState<string>();
  const [availabilityCalendar, setAvailabilityCalendar] = useState<AvailabilityCalendar>({});
  const [selectedLevels, setSelectedLevels] = useState<EducationLevel[]>();
  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);

  useEffect(() => {
    if (fields && types && themes && levels) {
      const filterItems = (items: CascaderOption[]): CascaderOption[] => {
        return items.reduce((acc: CascaderOption[], item) => {
          if (item.children && item.children.length > 0) {
            const filteredChildren = filterItems(item.children);
            if (filteredChildren.length > 0) {
              acc.push({ ...item, children: filteredChildren });
            }
          } else if (item.active == true) {
            acc.push(item);
          }
          return acc;
        }, []);
      };

      const _items: CascaderOption[] = fields.data?.map((f) => ({
        value: f.id!,
        label: f.name!,
        children: types.data
          ?.filter((t) => t.field?.id == f.id)
          .map((t) => ({
            value: t.id!,
            label: t.name,
            children: themes.data
              ?.filter((m) => m.type?.id == t.id)
              .map((m) => ({
                value: m.id,
                label: m.name,
                children: levels.data
                  ?.filter((l) => l.theme?.id == m.id)
                  .map((l) => ({
                    value: l.id,
                    label: l.name,
                    active: l.active,
                  })) as CascaderOption[],
              })) as CascaderOption[],
          })) as CascaderOption[],
      })) as CascaderOption[];

      const filteredItems = filterItems(_items);
      setLevelOptions(filteredItems);
    }
  }, [fields, types, themes, levels]);

  useEffect(() => {
    if (enrollment) {
      if (enrollment.type == EnrollmentTypes.INDIVIDUAL) {
        form.setValue({
          ...enrollment,
        });
      }
      setEnrollmentType(enrollment.type);
    }
  }, [enrollment.id]);

  useEffect(() => {
    if (enrollment.type == EnrollmentTypes.INDIVIDUAL && prePlacements[0]) {
      const prePlacement = prePlacements[0];
      form.setInitialValue(prePlacement);
      const model: string[][] = [];
      prePlacement.prePlacementDemands?.forEach(({ level }) => {
        const r: string[] = [level.theme?.type?.field?.id!];
        r.push(level.theme?.type?.id!);
        r.push(level.theme?.id!);
        r.push(level.id!);
        model.push(r);
      });
      setAvailabilityCalendar({ ...prePlacement.availabilityCalendar! });
      setLevelCascaderValue(model);
    } else if (enrollment.type != EnrollmentTypes.INDIVIDUAL) {
      form.setInitialValue({});
    }
  }, [prePlacements, enrollment.id]);

  useEffect(() => {
    if (form.value.id) {
      setIsEmailSearch(true);
      setIsEmailSearchFound(true);
    }
  }, [form.value.id]);

  useEffect(() => {
    //Talep Edilen Kurlar Göz Ikon Doldurulması START
    if (levels) {
      let selectedLevels: EducationLevel[] = [];

      levelCascaderValue.forEach((v) => {
        if (v.length == 1) {
          const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.field?.id == v[0]);
          selectedLevels = [...selectedLevels, ..._selectedLevels!];
        }
        if (v.length == 2) {
          const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.id == v[1]);
          selectedLevels = [...selectedLevels, ..._selectedLevels!];
        }
        if (v.length == 3) {
          const _selectedLevels = levels?.data?.filter((level) => level.theme?.id == v[2]);
          selectedLevels = [...selectedLevels, ..._selectedLevels!];
        }
        if (v.length == 4) {
          const _selectedLevels = levels?.data?.filter((level) => level?.id == v[3]);
          selectedLevels = [...selectedLevels, ..._selectedLevels!];
        }
      });
      setSelectedLevels(selectedLevels);
      //Talep Edilen Kurlar Göz Ikon Doldurulması END
    }
  }, [levels]);

  const onChangeLevel = (value) => {
    setLevelCascaderValue(value);
    let selectedLevels: EducationLevel[] = [];
    value.forEach((v) => {
      if (v.length == 1) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.field?.id == v[0]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 2) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.id == v[1]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 3) {
        const _selectedLevels = levels?.data?.filter((level) => level.theme?.id == v[2]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
      if (v.length == 4) {
        const _selectedLevels = levels?.data?.filter((level) => level?.id == v[3]);
        selectedLevels = [...selectedLevels, ..._selectedLevels!];
      }
    });
    setSelectedLevels(selectedLevels);

    form.setValue({
      ...form.value,
      prePlacementDemands: selectedLevels.map((i) => ({ level: i })),
    });
  };

  const sendData = useCallback(async () => {
    let isCalendarError = false;
    let isEmpty = Object.values(availabilityCalendar).every((x) => x?.length === 0);
    if (Object.keys(availabilityCalendar).length == 0 || isEmpty) {
      isCalendarError = true;
    }
    const result = form.parse();
    if (result.success) {
      if (!isCalendarError) {
        const student: PrePlacement = result.data;
        if (student.id) {
          onUpdate({ ...student, availabilityCalendar });
        } else {
          try {
            onSave({
              ...student,
              availabilityCalendar: availabilityCalendar,
            }).then((v) => {
              if (v) {
                form.reset();
                setAvailabilityCalendar({});
                setLevelCascaderValue([]);
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        notification.warning({ message: 'Lütfen müsaitlik takvimini doldurunuz' });
      }
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const onSearchResult = (data?: PrePlacement) => {
    if (data) {
      form.setInitialValue({
        ...form.value,
        email: data.email,
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        gender: data.gender,
        nationalId: data.nationalId,
        birthDate: data.birthDate,
      });

      setIsEmailSearchFound(true);
    } else {
      setIsEmailSearchFound(false);
      form.setInitialValue({
        ...form.value,
        name: undefined,
        surname: undefined,
        phone: {
          code: undefined,
          number: undefined,
        },
        gender: undefined,
        nationalId: undefined,
        birthDate: undefined,
      });
    }
    setIsEmailSearch(true);
  };

  return (
    <div>
      <div className="grid grid-cols-3 gap-x-2">
        <Form layout="vertical">
          <div className=" grid grid-cols-2 gap-x-2 gap-y-1">
            <div className="col-span-2">
              <EmailSearch
                form={form}
                onSearchResult={onSearchResult}
                onClear={() => {
                  form.setInitialValue({
                    ...form.value,
                    name: undefined,
                    email: undefined,
                    surname: undefined,
                    phone: {
                      code: undefined,
                      number: undefined,
                    },
                    gender: undefined,
                    nationalId: undefined,
                    birthDate: undefined,
                  });
                  setIsEmailSearchFound(false);
                  setIsEmailSearch(false);
                }}
                label={'Öğrenci E-posta'}
                disabled={isPreview || isEmailSearchFound}
              />
            </div>
            <Form.Item {...form.formItem('name')} label={<span className="truncate">Öğrenci Adı</span>}>
              <Input {...form.input('name')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </Form.Item>
            <Form.Item {...form.formItem('surname')} label={<span className="truncate">Öğrenci Soyadı</span>}>
              <Input {...form.input('surname')} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </Form.Item>
            <div className="col-span-2">
              <PhoneInput form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </div>

            <Form.Item {...form.formItem('birthDate')} label={<span className="truncate">Doğum Tarihi</span>}>
              <BirthdayCalendar form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />
            </Form.Item>
            <NationalIdInput form={form} disabled={isPreview || !isEmailSearch || isEmailSearchFound} />

            <Form.Item {...form.formItem('gender')} label={'Cinsiyet'}>
              <Radio.Group {...form.input('gender')} disabled={isPreview || !isEmailSearch || isEmailSearchFound}>
                <Radio value={Gender.FEMALE}>Kadın</Radio>
                <Radio value={Gender.MALE}>Erkek</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...form.formItem('preferredStartDate')} label={<span className="truncate">Başlangıç Tarihi</span>}>
              <DatePicker placeholder="Başlangıç tarihi seçiniz" {...form.inputDate('preferredStartDate')} style={{ width: '100%' }} minDate={dayjs()} disabled={isPreview} />
            </Form.Item>

            <div className="flex col-span-2 justify-between items-center">
              <div>Öğrencinin Talep Ettiği Kurlar</div>
              {levelCascaderValue.length > 0 && (
                <Popover
                  content={<List size="small" dataSource={selectedLevels} renderItem={(item) => <List.Item key={item.id}>{renderLevelsAsString(item)}</List.Item>} />}
                  placement="left"
                  trigger="hover"
                  className="px-2"
                >
                  <EyeTwoTone className="text-lg" />
                </Popover>
              )}
            </div>
            <div className="grid col-span-2">
              <Form.Item {...form.formItem('prePlacementDemands')}>
                <MultipleCascader onChange={onChangeLevel} loading={false} value={levelCascaderValue} items={levelOptions} isShowChild={true} disabled={isPreview} />
              </Form.Item>
            </div>

            <div className="col-span-2">
              <Form.Item {...form.formItem('note')} label={<span className="truncate">Öğrenci Notları</span>}>
                <TextArea rows={4} {...form.input('note')} disabled={isPreview} />
              </Form.Item>
            </div>
          </div>
        </Form>
        {enrollmentType != EnrollmentTypes.INDIVIDUAL ? (
          <div className="col-span-2 ">
            <label className="text-black/45 font-normal">Eklenen Öğrenciler</label>
            {prePlacements.length == 0 ? (
              <div className="border pt-9 border-dashed border-mid-gray rounded p-5 text-center">Lütfen öğrenci ekleyiniz</div>
            ) : (
              <div
                key={(Math.random() + 1).toString(36).substring(7)}
                className="border border-dashed border-mid-gray rounded p-5 grid grid-cols-2 gap-x-4 gap-y-4 max-h-96 overflow-y-scroll"
              >
                {prePlacements.map((placment) => (
                  <EnrollmentUserCard
                    key={placment.id}
                    user={placment}
                    disabled={isPreview}
                    onDelete={(user) => {
                      if (!isPreview) {
                        onDelete(user.id!);
                      }
                    }}
                    onEdit={(user) => {
                      form.setInitialValue(user);
                      const levelCascaderValue: string[][] = [];
                      placment.prePlacementDemands?.forEach(({ level }) => {
                        const r: string[] = [level.theme?.type?.field?.id!];
                        r.push(level.theme?.type?.id!);
                        r.push(level.theme?.id!);
                        r.push(level.id!);
                        levelCascaderValue.push(r);
                      });
                      setLevelCascaderValue(levelCascaderValue);
                      setAvailabilityCalendar({ ...placment.availabilityCalendar! });
                      //Talep Edilen Kurlar Collapse Doldurulması START
                      let selectedLevels: EducationLevel[] = [];
                      levelCascaderValue.forEach((v) => {
                        if (v.length == 1) {
                          const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.field?.id == v[0]);
                          selectedLevels = [...selectedLevels, ..._selectedLevels!];
                        }
                        if (v.length == 2) {
                          const _selectedLevels = levels?.data?.filter((level) => level.theme?.type?.id == v[1]);
                          selectedLevels = [...selectedLevels, ..._selectedLevels!];
                        }
                        if (v.length == 3) {
                          const _selectedLevels = levels?.data?.filter((level) => level.theme?.id == v[2]);
                          selectedLevels = [...selectedLevels, ..._selectedLevels!];
                        }
                        if (v.length == 4) {
                          const _selectedLevels = levels?.data?.filter((level) => level?.id == v[3]);
                          selectedLevels = [...selectedLevels, ..._selectedLevels!];
                        }
                      });
                      setSelectedLevels(selectedLevels);
                      //Talep Edilen Kurlar Collapse Doldurulması END
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="col-span-2">
            <label className="text-black/45 font-normal">Öğrencinin Müsaitlik Takvimi</label>
            <AvailabilityWeekCalendar
              value={availabilityCalendar}
              disabled={isPreview || !isEmailSearch}
              onChange={(availability) => {
                if (!isPreview) {
                  setAvailabilityCalendar(availability);
                }
              }}
            />
          </div>
        )}
      </div>
      {enrollment.type != EnrollmentTypes.INDIVIDUAL ? (
        <div className="grid grid-rows-2 gap-y-2">
          <Collapse
            items={[
              {
                key: '1',
                label: 'Öğrencinin Müsaitlik Takvimi',
                children: (
                  <AvailabilityWeekCalendar
                    value={availabilityCalendar}
                    disabled={isPreview || !isEmailSearch}
                    onChange={(availability) => {
                      if (!isPreview) {
                        setAvailabilityCalendar(availability);
                      }
                    }}
                  />
                ),
              },
            ]}
          />
          <div className=" flex flex-row space-x-2">
            <Button
              type="primary"
              className="w-48"
              disabled={isPreview || !isEmailSearch}
              onClick={() => {
                sendData();
              }}
            >
              + Öğrenci Ekle
            </Button>
            {Object.keys(form.value).length > 0 && (
              <Button
                type="dashed"
                onClick={() => {
                  form.reset();
                  setAvailabilityCalendar({});
                  setLevelCascaderValue([]);
                  setIsEmailSearch(false);
                  setIsEmailSearchFound(false);
                }}
              >
                Formu Sıfırla
              </Button>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {enrollment.type == EnrollmentTypes.INDIVIDUAL ? (
        <div className="col-span-2 flex justify-end mt-5">
          <Button type="primary" onClick={sendData}>
            Sonraki adım
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
