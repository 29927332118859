import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { ClassHomeworkProps } from '@shared/models';
import { Button, DatePicker, Drawer, notification, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type HomeworkEditDrawerProps = {
  homeworkDrawerOpen: boolean;
  homeworkDrawerOnClose: () => void;
  homeworkData: ClassHomeworkProps;
};

//TODO düzelt zodları
const HomeworkEditDrawerForm = z.object({
  score: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
  evaluateNote: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
});

export const HomeworkEditDrawer = ({ homeworkDrawerOpen, homeworkDrawerOnClose, homeworkData }: HomeworkEditDrawerProps) => {
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [dateValue, setDateValue] = useState<any>();
  const { RangePicker } = DatePicker;

  const form = useForm<Partial<ClassHomeworkProps>, ClassHomeworkProps>(HomeworkEditDrawerForm, { ...homeworkData });

  useEffect(() => {
    if (homeworkData) {
      setFiles(homeworkData.files.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue({
        ...homeworkData,
        startDate: homeworkData.startDate,
        dueDate: homeworkData.dueDate,
      });

      const dueDateFormatted = new Date(homeworkData.dueDate);
      dueDateFormatted.setUTCHours(0, 0, 0, 0);
      const startDate = dayjs(homeworkData?.startDate);
      const dueDate = dayjs(dueDateFormatted.toISOString());

      setDateValue([startDate, dueDate]);
    }
  }, [homeworkData]);

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();
    const response = await API.HOMEWORK.updateHomeworkTeacher(homeworkData.id!, {
      ...result.data,
      files,
      startDate: result.data.startDate,
      dueDate: result.data.dueDate,
    });
    if (response.ok) {
      homeworkDrawerOnClose();
      notification.success({ message: 'Ödev güncellendi' });
    }

    startUpload(false);
  };

  const handleTimeChange = (value, valueFormat) => {
    setDateValue(value);
    form.setValue({
      ...form.value,
      startDate: valueFormat[0],
      dueDate: valueFormat[1],
      files: files,
    });
  };

  return (
    <Drawer forceRender={true} open={homeworkDrawerOpen} onClose={homeworkDrawerOnClose} closable={true} title={<div>Ödev detayı</div>}>
      <div>
        <h4 className="mb-2">Ödev başlığı</h4>
        <TextInput formItem={form.formItem('name')} formInput={form.input('name')} />
      </div>
      <div className="mt-4">
        <h4 className="mb-2">Ödev açıklaması</h4>
        <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 2, maxRows: 5 }} {...form.input('description')} />
      </div>
      <div className="mt-4">
        <h4 className="mb-2">Ödev teslim tarihi</h4>
        <RangePicker value={dateValue} className="w-full" onChange={(value, valueFormat) => handleTimeChange(value, valueFormat)} />
      </div>

      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            onChangeLoading={(b) => {
              setLoading(b);
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>
      <Button className="w-full" loading={loading} type="primary" onClick={() => startUpload(true)}>
        Değişiklikleri kaydet
      </Button>
    </Drawer>
  );
};

HomeworkEditDrawer.roles = [Role.ADMIN];
