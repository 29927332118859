import { CalendarOutlined, ExportOutlined, InsertRowAboveOutlined, TeamOutlined } from '@ant-design/icons';
import { ClassFilter, ClassTableInfo } from '@platform/platform_components';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { ClassStatus, LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassModel, PaginationQuery } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Card, Empty, Pagination, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { BiMedal } from 'react-icons/bi';
import { PiChalkboardTeacherLight } from 'react-icons/pi';
import { SiGoogleclassroom } from 'react-icons/si';
import { useLocation, useNavigate } from 'react-router-dom';

export const Class = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [classList, classListLoading, getClassList] = API.CLASS.getClassList({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 6, levelIds: [], statuses: [[ClassStatus.STARTED], [ClassStatus.NOT_STARTED]] });

  //Header Aktif ve Planlanmış Sınıf Sayısı için
  const [plannedClass] = API.CLASS.getClassList({ init: true, initParams: { statuses: [ClassStatus.NOT_STARTED], page: 1, limit: 1 } });
  const [startedClass] = API.CLASS.getClassList({ init: true, initParams: { statuses: [ClassStatus.STARTED], page: 1, limit: 1 } });

  useEffect(() => {
    getClassList(undefined, paginationQuery);
  }, [paginationQuery]);

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 6, levelIds: [], statuses: [] });
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const uniqueTeachers = useCallback(
    (c: ClassModel) => {
      const teacherList: { id: string; name: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.teacher) {
              teacherList.push({
                id: item?.teacher?.id!,
                name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
              });
            }
          });
        });
      }
      return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const uniqueClassrooms = useCallback(
    (c: ClassModel) => {
      const classroomList: { id: string; name: string }[] = [];
      if (c) {
        c!.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.classRoom) {
              classroomList.push({
                id: item?.classRoom?.id!,
                name: item?.classRoom?.name!,
              });
            }
          });
        });
      }
      return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [classList]
  );

  const statusStyles = (status): React.CSSProperties => {
    switch (status) {
      case ClassStatus.CANCELED:
        return { backgroundColor: '#F5F5F5', color: '#00000040' };
      case ClassStatus.COMPLETED:
        return { backgroundColor: '#E6F4FF', color: '#1677FF' };
      case ClassStatus.STARTED:
        return { backgroundColor: '#F6FFED', color: '#52C41A', borderBottomColor: '#B7EB8F' };
      case ClassStatus.NOT_STARTED:
        return { backgroundColor: '#FFFBE6', color: '#FAAD14', borderBottomColor: '#FFE58F' };
      default:
        return {};
    }
  };

  return (
    <>
      <Spinner loading={classListLoading} />

      <ClassFilter onFilter={onFilter} filters={paginationQuery} />
      <ClassTableInfo />
      {classList?.data?.length === 0 ? (
        <Empty className="mt-10" description="Sınıf mevcut değil" />
      ) : (
        <>
          <div className="grid grid-cols-3 gap-3 mt-5">
            {classList?.data?.map((c) => (
              <Card
                key={c.id}
                title={
                  <Tooltip title={`${c.level.theme.type.field.name} ${c.level.theme.type.name} ${c.level.theme.name} | ${c.name}`}>
                    <span>{`${c.level.theme.type.field.name} ${c.level.theme.type.name} ${c.level.theme.name} | ${c.name}`}</span>
                  </Tooltip>
                }
                extra={
                  <>
                    <Tooltip className="mr-2" placement="top" title={'Sınıf detayına git'}>
                      <ExportOutlined
                        className="cursor-pointer"
                        style={{ color: statusStyles(c.status).color }}
                        onClick={() => navigate('/lms/class_detail' + location.search + '&class_id=' + c.id)}
                      />
                    </Tooltip>
                  </>
                }
                styles={{ header: { ...statusStyles(c.status), fontWeight: 'normal' } }}
                size="small"
                className="rounded-xl"
              >
                <div className="flex flex-col gap-y-2">
                  <div className="font-bold flex items-center">
                    <BiMedal className="text-gray-500 mr-1" />
                    {renderLevelsAsString(c.level)}
                  </div>
                  <div>
                    <CalendarOutlined className="text-gray-500" /> {dayjs(c.calculatedStartDate).format('DD.MM.YYYY') + ' - ' + dayjs(c.calculatedEndDate).format('DD.MM.YYYY')}
                  </div>
                  <div>
                    <TeamOutlined className="text-gray-500" /> <span>{c.studentCount} </span>
                  </div>
                  <div className="flex place-items-center">
                    <SiGoogleclassroom className="text-gray-500 mr-1" />
                    {uniqueClassrooms(c)
                      .map((classroom) => classroom.name)
                      .join(', ')}
                  </div>
                  <div className="flex items-center">
                    <PiChalkboardTeacherLight className="text-gray-500 mr-1" />
                    {uniqueTeachers(c)
                      .map((teacher) => teacher.name)
                      .join(', ')}
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <Pagination
            current={paginationQuery.page}
            pageSize={classList?.limit ?? 1}
            total={classList?.count ?? 0}
            className="mt-5 text-center"
            onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))}
          />
        </>
      )}
    </>
  );
};

Class.path = '/lms/class';
Class.title = 'Sınıflar';
Class.icon = <InsertRowAboveOutlined size={20} />;
Class.group = 'flat';
Class.roles = [Role.ADMIN];
Class.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
Class.permissions = ['branch.lms.class.view'];
