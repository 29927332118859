import { PlusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown, MultipleCascader } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { CascaderOption, ConditionCourse, EducationLevel, PaginationQuery } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, Form, Modal, Table, TableProps, notification } from 'antd';
import { useEffect, useState } from 'react';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';

export const ConditionsCompletedCourse = () => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [result, loading, getLevelRequirement] = API.EDUCATION_LEVEL.BRAND.getLevelSuccessRequirementAll({ init: false });
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.BRAND.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.BRAND.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.BRAND.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRAND.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<(string | number)[][]>([]);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedLevels, setSelectedLevels] = useState<EducationLevel[]>([]);
  const [selectedCourseRecord, setSelectedCourseRecord] = useState<ConditionCourse>();

  useEffect(() => {
    if (paginationQuery) {
      getLevelRequirement(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  useEffect(() => {
    if (fields && types && themes && levels) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });

      setAbilityLevelOptions(_items);
    }
  }, [fields, types, themes, levels]);

  const onChangeAbilityLevel = (value) => {
    setAbilityLevelCascaderValue(value);
    let selectedLevelValues: EducationLevel[] = [];
    value.forEach((v) => {
      const _selectedLevels = levels?.data?.filter((level) => level?.id == v[3]);
      selectedLevelValues = [...selectedLevelValues, ..._selectedLevels!];
    });
    setSelectedLevels(selectedLevelValues);
  };

  const duplicateRequirements = async () => {
    const results = await Promise.all(
      selectedLevels.map((level) => {
        const value: ConditionCourse = { ...selectedCourseRecord, id: undefined, levelId: level.id };
        return API.EDUCATION_LEVEL.BRAND.upsertlevelSuccessRequirement(value);
      })
    );
    getLevelRequirement(undefined, paginationQuery);
    onModalClose();
  };

  const onModalClose = () => {
    setAbilityLevelCascaderValue([]);
    setIsModalOpen(false);
    setSelectedCourseRecord(undefined);
  };

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={() => navigate('/brand/conditions-completed-course-create' + location.search)}>
        Yeni Kur Geçme Şartı
      </Button>
    </div>
  );

  const columns: TableProps<ConditionCourse>['columns'] = [
    {
      title: 'Kur Bilgisi',
      dataIndex: 'id',
      key: 'id',
      className: 'text-center',
      render: (_, record) => {
        return <div>{renderLevelsAsString(record.level)}</div>;
      },
    },
    {
      title: 'Aksiyon',
      key: 'action',
      width: '10%',
      className: 'text-center',
      render: (_, record) => (
        <ActionDropdown
          data={record}
          onEdit={({ id }) => {
            navigate('/brand/conditions-completed-course-create' + location.search + '&levelId=' + id);
          }}
          onDelete={async ({ id }) => {
            const result = await API.EDUCATION_LEVEL.BRAND.deletelevelSuccessRequirement(id);
            if (result.ok) {
              notification.success({ message: 'Kur geçme şartı silindi' });
            }
            getLevelRequirement(undefined, { ...paginationQuery, page: 1 });
          }}
          items={[
            {
              key: 'duplicate',
              label: 'Geçme Bilgilerini Çoğalt',
              icon: <HiOutlineDocumentDuplicate size={15} />,
              onClick: () => {
                setSelectedCourseRecord(record);
                setIsModalOpen(true);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={result?.data}
        rowKey={'id'}
        bordered
        title={() => title}
        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, total: result?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination) => setPaginationQuery({ ...paginationQuery, page: pagination.current, limit: pagination.pageSize })}
      />

      <Modal title="Kopyalamak istediğiniz kurları seçiniz" open={isModalOpen} onCancel={() => onModalClose()} footer={null}>
        <Form.Item>
          <MultipleCascader placeholder="Çoğaltılacak Kurlar" items={abilityLevelOptions} value={abilityLevelCascaderValue} onChange={onChangeAbilityLevel} isShowChild={true} />
        </Form.Item>
        <div className="flex justify-end gap-4">
          <Form.Item>
            <Button
              type="default"
              onClick={() => {
                onModalClose();
              }}
            >
              Vazgeç
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" disabled={abilityLevelCascaderValue.length == 0} onClick={() => duplicateRequirements()}>
              Çoğalt
            </Button>
          </Form.Item>
        </div>
      </Modal>
    </>
  );
};

ConditionsCompletedCourse.path = '/brand/conditions-completed-course';
ConditionsCompletedCourse.title = 'Kur Geçme Şartları';
ConditionsCompletedCourse.group = 'definitions';
ConditionsCompletedCourse.roles = [Role.USER];
ConditionsCompletedCourse.layouts = [LayoutEnums.BRANDMANAGER];
ConditionsCompletedCourse.permissions = ['brand.lms.definition.view'];
