import { ClassInfo, ClassSuccessSummary, ClassTimeline } from '@platform/platform_components';
import * as API from '@shared/api';
import { Calendar, Spinner } from '@shared/components';
import { Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { convertDate, getMonthRange, renderLevelsAsString } from '@shared/utils';
import { Card, notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClassCalendarRenderer } from '../../../platform_components/calendar/CalenderRenderers';

export const ClassDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [classId, setClassId] = useState<string>();
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getClassDetail(classId, { init: false });
  const [availableStudentList, availableListLoading, getAvailableStudentList] = API.CLASS.getAvailableStudentsToAdd(classId, { init: false });
  const [calendarData, calendarLoading, getCalendarData] = API.CLASS.getClassCalendar(classId, { init: false });

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('class_id');
      if (!id) return;
      setClassId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (classId) {
      getClassDetail();
      getAvailableStudentList();
    }
  }, [classId]);

  const onAddStudent = async (classId: string, demandId: string) => {
    const response = await API.CLASS.addStudentToClass(classId, demandId);
    if (response.ok) {
      await getClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrenci başarıyla sınıfa eklendi' });
    }
  };

  const onRemoveStudent = async (classId: string, studentId: string) => {
    const response = await API.CLASS.removeStudentFromClass(classId, studentId);
    if (response.ok) {
      await getClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrencinin kaydı sınıftan silindi' });
    }
  };

  useEffect(() => {
    if (classId) {
      const { startOfMonth, endOfMonth } = getMonthRange(dayjs());
      getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
    }
  }, [classId]);

  const classClandarRefreshForDate = (date: Dayjs) => {
    const { startOfMonth, endOfMonth } = getMonthRange(date);
    getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
  };

  const classCalendar = <Calendar events={calendarData} onDateChange={classClandarRefreshForDate} cellRenderer={ClassCalendarRenderer(classId, classClandarRefreshForDate)} />;

  return (
    <>
      <Spinner loading={classDetailLoading && availableListLoading} />
      <div className="grid grid-cols-3">
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Sınıf Adı
              <div className="text-black font-bold">{classDetail?.name}</div>
            </div>
          </div>
        </Card>
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Kur Bilgisi
              <div className="text-black font-bold">{renderLevelsAsString(classDetail?.level)}</div>
            </div>
          </div>
        </Card>
        <Card type="inner">
          <div className="flex text-[#00000073]">
            <div>
              Kur Tarihleri
              <div className="text-black font-bold">
                {convertDate(classDetail?.calculatedStartDate)} - {convertDate(classDetail?.calculatedEndDate)}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-9">
          <div className="mt-3">
            <ClassTimeline notes={classDetail?.notes} isTeacher={false} classDetail={classDetail} />
          </div>
        </div>
        <div className="col-span-3 justify-center ml-3  ">
          <ClassInfo
            calendar={classCalendar}
            classDetail={classDetail}
            getClassDetail={() => getClassDetail()}
            availableStudentList={availableStudentList}
            onAddStudent={onAddStudent}
            onRemoveStudent={onRemoveStudent}
          />
        </div>
      </div>
    </>
  );
};

ClassDetail.path = '/lms/class_detail';
ClassDetail.roles = [Role.ADMIN];
