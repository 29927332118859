import { DownloadOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { FileDownload } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { Role } from '@shared/constants';
import { StudentHomeworkProps } from '@shared/models';
import { Button, Drawer, notification, Popconfirm } from 'antd';
import { HomeworkDetailContentsDrawer } from './HomeworkDetailContentsDrawer';

type LoadedHomeworkDetailDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
  studentHomework: StudentHomeworkProps;
};

export const LoadedHomeworkDetailDrawer = ({ open, onCloseDrawer, studentHomework }: LoadedHomeworkDetailDrawerProps) => {
  const onDeleteHomework = async () => {
    const response = await API.HOMEWORK.deleteStudentHomework(studentHomework?.id!);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      onCloseDrawer();
    }
  };

  return (
    <Drawer forceRender={true} open={open} onClose={onCloseDrawer} closable={true} title={'Hatırlatıcı Oluştur'}>
      <div className="text-lg font-bold">{studentHomework?.classHomework?.name}</div>
      <div className="italic opacity-45">{studentHomework?.classHomework?.name}</div>
      <div className="mt-4">
        <h4>Yüklediğiniz dosyalar</h4>
        <div className="mt-2">
          {studentHomework?.files?.map((file) => (
            <div className="mt-1">
              <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-2 text-xs">
        <span className="text-red-500">*</span> Desteklenen formatlar: .docx, .word, .pdf, .mp4, .zip
      </div>
      <div className="mt-2">
        <h4 className="mb-2">Notunuz</h4>
        {studentHomework?.note}
      </div>
      <div className="mt-3">
        <Popconfirm title="Ödevi sil" description="Ödevi silmek istediğine emin misin?" onConfirm={onDeleteHomework} okText="Evet" cancelText="İptal">
          <Button className="w-full" danger type="primary">
            Ödevi Sil
          </Button>
        </Popconfirm>
      </div>
      <div>
        <HomeworkDetailContentsDrawer studentHomework={studentHomework!} />
      </div>
    </Drawer>
  );
};

LoadedHomeworkDetailDrawer.roles = [Role.ADMIN];
