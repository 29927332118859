import * as API from '@shared/api';
import { CalendarModel } from '@shared/components';
import { ClassCalendarEntryStatus, ClassCalendarEntryType, StudentCalendarType } from '@shared/constants';
import { convertDate } from '@shared/utils';
import { notification, Popconfirm, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { LuCalendarClock } from 'react-icons/lu';

export type ClassCalendarModel = CalendarModel & {
  type: ClassCalendarEntryType;
  status: ClassCalendarEntryStatus;
};

export type StudentCalendarModel = CalendarModel & {
  type: StudentCalendarType;
  status: ClassCalendarEntryStatus;
  className: string;
};

export const ClassCalendarRenderer = (classId, classClandarRefreshForDate: (date: Dayjs) => void) => {
  return (dayEvents: ClassCalendarModel[]) => {
    return (
      <div>
        {dayEvents?.map((event) => (
          <>
            {event.type == ClassCalendarEntryType.IN_CLASS &&
              CalendarInClassEntryRenderer(event, async () => {
                await API.CLASS.classCalendarPostpone(classId, event.id);
                classClandarRefreshForDate?.(dayjs(event.date));
                notification.success({ message: convertDate(event.date) + ' tarih ertelendi' });
              })}

            {event.type == ClassCalendarEntryType.EVENT && CalendarEventEntryRenderer(event)}
            {event.type == ClassCalendarEntryType.HOMEWORK && CalendarHomeworkEntryRenderer(event)}
          </>
        ))}
      </div>
    );
  };
};

export const StudentCalendarRenderer = () => {
  return (dayEvents: StudentCalendarModel[]) => {
    return (
      <div>
        {dayEvents?.map((event) => (
          <>
            {event.type == StudentCalendarType.IN_CLASS && CalendarInClassEntryForNonClassLayoutRenderer(event)}
            {event.type == StudentCalendarType.BRANCH_ITEM && CalendarEventEntryRenderer(event)}
            {event.type == StudentCalendarType.HOMEWORK && CalendarHomeworkEntryRenderer(event)}
          </>
        ))}
      </div>
    );
  };
};

//

export const CalendarPostponedInClassEntryRenderer = (event: CalendarModel) => {
  return (
    <div>
      <div className="flex items-center">
        <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color="gray" key={event.id}>
          {event.title}
        </Tag>
      </div>
    </div>
  );
};

export const CalendarEventEntryRenderer = (event: CalendarModel) => {
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.title}>
          <Tag className="whitespace-nowrap overflow-hidden text-ellipsis" color="blue" key={event.id}>
            {event.isHoliday ? 'Tatil-' : ''}
            {event.title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};

export const CalendarHomeworkEntryRenderer = (event: CalendarModel & { className?: string }) => {
  const title = (event.className ? event.className + ' / ' : '') + event.title;
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={title}>
          <Tag className="whitespace-nowrap overflow-hidden text-ellipsis" color="gold" key={event.id}>
            {title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};
export const CalendarInClassEntryRenderer = (event: CalendarModel, onPostponed?: () => Promise<void>) => {
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.title}>
          <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color={event.status == ClassCalendarEntryStatus.POSTPONED ? 'gray' : 'magenta'} key={event.id}>
            {event.title}
          </Tag>
        </Tooltip>

        {onPostponed && event.status == ClassCalendarEntryStatus.WAITING && (
          <Popconfirm title={<span>{convertDate(event.date)} tarihi ertelemek istediğinize emin misiniz? </span>} onConfirm={onPostponed} okText="Evet" cancelText="İptal">
            <Tooltip title="Bugün Ders İşlenmeyecek Olarak İşaretle">
              <LuCalendarClock color="magenta" />
            </Tooltip>
          </Popconfirm>
        )}
      </div>
    </div>
  );
};

export const CalendarInClassEntryForNonClassLayoutRenderer = (event: StudentCalendarModel) => {
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.className + ' / ' + event.title}>
          <Tag className="overflow-hidden text-ellipsis" color={event.status == ClassCalendarEntryStatus.POSTPONED ? 'gray' : 'magenta'} key={event.id}>
            {event.className + ' / ' + event.title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};
