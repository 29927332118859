import { CalendarOutlined } from '@ant-design/icons';
import { ActionDropdown, AvatarFromName } from '@shared/components';
import { Observer, PrePlacement } from '@shared/models';
import { Card, MenuProps, Tag } from 'antd';
import { useEffect, useState } from 'react';

type EnrollmentUserCardProp = {
  user: Observer | PrePlacement;
  isDelete?: boolean;
  isEdit?: boolean;
  onDelete: (user: Observer | PrePlacement) => void;
  onEdit: (user: Observer | PrePlacement) => void;
  onUpdateCalendar?: (user: Observer | PrePlacement) => void;
  disabled?: boolean;
  mainUserText?: string;
  isSmall?: boolean;
  className?: string;
};
export const EnrollmentUserCard = ({ user, isDelete = true, isEdit = true, onDelete, onEdit, disabled = false, mainUserText, isSmall = false, className ,onUpdateCalendar}: EnrollmentUserCardProp) => {
  //const menu: MenuProps['items'] = [];
  const [menu, setMenu] = useState<MenuProps['items']>([]);
  useEffect(() => {
    const _menu:MenuProps['items'] = [];
    if(onUpdateCalendar){
      _menu.push({
        key: 'onUpdateCalendar',
        icon:<CalendarOutlined/>,
        label: 'Takvimi Güncelle',
        onClick: () => {
          onUpdateCalendar(user);
        }
      });
      setMenu(_menu);
    }
    
  },[onUpdateCalendar]);
  return (
    <Card
      key={user.id}
      size={isSmall ? 'small' : 'default'}
      className={className}
      title={
        <Card.Meta
          className="flex items-center"
          avatar={
            <div className="pt-2 pb-2">
              <AvatarFromName title={user.name! + " " + user.surname} subtitle={user.email!} bgColor="#E6F4FF" txColor="#1677FF" />
            </div>
          }
          title={[user.name, user.surname].join(' ')}
        />
      }
      extra={
        <div className="flex gap-2 items-center">
          {(isDelete || isEdit || (menu && menu.length>0)) && <ActionDropdown
            disabled={disabled}
            data={user}
            isEdit={isEdit}
            isDelete={isDelete}
            items={menu }
            onDelete={(data) => {
              onDelete(data);
            }}
            onEdit={(data) => {
              onEdit(data);
            }}
          />
          }
        </div>
      }
    >
      <div className='flex flex-col space-y-2'>
        <div className="grid grid-cols-[60px_1fr] items-center">
          <label className="font-medium">Email:</label>
          <span className="text-dark-gray text-xs ">{user.email}</span>

          <label className="font-medium ">Telefon:</label>
          <span className="text-dark-gray text-xs ">
            {user.phone?.code}
            {user.phone?.number}
          </span>
        </div>
        {
          mainUserText && <Tag className="w-full" color="cyan">{mainUserText}</Tag>
        }

      </div>
    </Card>
  );
};
