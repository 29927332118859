import { ClassSuccessSummary, TeacherClassDashboard } from '@platform/platform_components';
import { TeacherInspection } from '@platform/platform_components/teacher_class/homework_components/TeacherInspection';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { LayoutEnums, NoteType, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { convertDate, getMonthRange, renderLevelsAsString } from '@shared/utils';
import { Card, notification, Tabs, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TeacherClassHomeWork } from './TeacherClassHomeWork';

export const TeacherClassDetail = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [classId, setClassId] = useState<string>();
  const [activeKey, setActiveKey] = useState<string>('dashboard');
  const [classDetail, classDetailLoading, getClassDetail] = API.CLASS.getClassDetail(classId, { init: false });
  const [calendarData, calendarLoading, getCalendarData] = API.CLASS.getClassCalendar(classId, { init: false });
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());

  //const id = searchParams.get('class_id');
  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('class_id');
      if (id) {
        setClassId(id);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (classId) {
      getClassDetail();
    }
  }, [classId]);

  //Bulunduğumuz ayın veya takvimde seçilen ayda bulunan veriyi çekiyoruz.
  useEffect(() => {
    if (currentMonth && classId) {
      const { startOfMonth, endOfMonth } = getMonthRange(currentMonth);
      getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
    }
  }, [classId, currentMonth]);

  const onRefresh = () => {
    const { startOfMonth, endOfMonth } = getMonthRange(currentMonth);
    getCalendarData(undefined, { begin: startOfMonth, end: endOfMonth });
  };

  const createClassNote = async (note: string, noteType: NoteType) => {
    const response = await API.CLASS.createClassNote(classDetail?.id!, note, noteType);
    if (response.ok) {
      await getClassDetail();
      notification.success({ message: 'Sınıf notu oluşturuldu!' });
    }
  };

  const onRemoveStudent = async (classId: string, studentId: string) => {
    const {ok} = await API.CLASS.removeStudentFromClass(classId, studentId);
    if (ok) {
      notification.success({ message: 'Öğrencinin kaydı sınıftan silindi' });
    }else{
      notification.error({ message: 'Öğrencinin kaydı sınıftan silinemedi' });
    }
  }

  return (
    <>
      <Spinner loading={classDetailLoading} />
      <div style={{ margin: '30px 100px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
        <div className="grid grid-cols-3">
          <Card type="inner">
            <div className="flex text-[#00000073]">
              <div>
                Sınıf Adı
                <div className="text-black font-bold">{classDetail?.name}</div>
              </div>
            </div>
          </Card>
          <Card type="inner">
            <div className="flex text-[#00000073]">
              <div>
                Kur Bilgisi
                <div className="text-black font-bold">{renderLevelsAsString(classDetail?.level)}</div>
              </div>
            </div>
          </Card>
          <Card type="inner">
            <div className="flex text-[#00000073]">
              <div>
                Kur Tarihleri
                <div className="text-black font-bold">
                  {convertDate(classDetail?.calculatedStartDate)} - {convertDate(classDetail?.calculatedEndDate)}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Tabs
          className="teacher-border"
          onChange={(key) => setActiveKey(key)}
          items={[
            {
              key: 'dashboard',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'dashboard' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'dashboard' ? '#f0501b' : '' }}>Sınıf Bilgileri</span>
                </Tag>
              ),
              children: <TeacherClassDashboard
                createClassNote={createClassNote}
                classDetail={classDetail}
                calendarData={calendarData}
                getClassDetail={() => getClassDetail()}
                onRemoveStudent={onRemoveStudent}
                onChangeCurrentMonth={(date: Dayjs) => setCurrentMonth(date)}
                currentMonth={currentMonth}
                onRefresh={onRefresh}
              />,
              
            },
            {
              key: 'Öğrenci Durumu',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'Öğrenci Durumu' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'Öğrenci Durumu' ? '#f0501b' : '' }}>Öğrenci Durumu</span>
                </Tag>
              ),
              children: <ClassSuccessSummary classDetail={classDetail} />,
            },
            //TODO Kullanılmayan alanların kaldırılması
            {
              key: 'homework',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'homework' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-3">
                  <span style={{ color: activeKey !== 'homework' ? '#f0501b' : '' }}>Ödev</span>
                </Tag>
              ),
              children: <TeacherClassHomeWork isDetail={true} classId={classId} />,
            },
            {
              key: 'attendance',
              label: (
                <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'attendance' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
                  <span style={{ color: activeKey !== 'attendance' ? '#f0501b' : '' }}>Yoklama</span>
                </Tag>
              ),
              children: <TeacherInspection />,
            },
            //TODO Kullanılmayan alanların kaldırılması
            // {
            //   key: 'performance',
            //   label: (
            //     <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'performance' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
            //       <span style={{ color: activeKey !== 'performance' ? '#f0501b' : '' }}>Sınıf Performansı</span>
            //     </Tag>
            //   ),
            //   children: 'Soon',
            // },
            //TODO Kullanılmayan alanların kaldırılması
            // {
            //   key: 'agenda',
            //   label: (
            //     <Tag style={{ border: '1px solid #FFBB96' }} color={activeKey === 'agenda' ? '#FA541C' : '#FFF2E8'} className="rounded-[28px] px-2">
            //       <span style={{ color: activeKey !== 'agenda' ? '#f0501b' : '' }}>Ajanda</span>
            //     </Tag>
            //   ),
            //   children: 'Soon',
            // },
          ]}
        />
      </div>
    </>
  );
};

TeacherClassDetail.path = '/' + LayoutEnums.TEACHER + '/class_detail';
TeacherClassDetail.roles = [Role.ADMIN];
TeacherClassDetail.layouts = [LayoutEnums.TEACHER];
