import { BookOutlined, CalendarOutlined, FileDoneOutlined, FileTextOutlined, PaperClipOutlined, PlusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { KanbanColumn, KanbanColumnTitle } from '@platform/platform_components/kanban_view/KanbanColumn';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassHomeworkProps, PaginationQuery } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Button, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DraftViewDrawer, HomeworkEditDrawer, TeacherClassFilter } from '@platform/platform_components';
import { IoDocumentTextOutline } from 'react-icons/io5';

const TaskCardContent = ({ homework }) => {
  return (
    <>
      {homework.class ? (
        <>
          <div>
            <small className="text-gray-500">{renderLevelsAsString(homework.class?.level)}</small>
          </div>
          <div>
            <small className="text-gray-500">{homework.class?.name}</small>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

type TeacherClassHomeWorkProps = {
  isDetail: boolean;
  classId?: string;
};

export const TeacherClassHomeWork = ({ isDetail = false, classId }: TeacherClassHomeWorkProps) => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState<string>('');
  const [homeworkData, setHomeworkData] = useState<ClassHomeworkProps>();
  const [homeworkDrawerOpen, setHomeworkDrawerOpen] = useState<boolean>(false);
  const [draftDrawerOpen, setDraftDrawerOpen] = useState<boolean>(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 3, levelIds: [] });

  const getHomeworks = (status: string) => {
    if (!isDetail) {
      return API.HOMEWORK.paginateClassHomeworkByRequestedTeacher({
        init: true,
        initParams: { status, ...paginationQuery },
      });
    } else {
      return API.HOMEWORK.paginateClassHomeworkByRequestedTeacherClass(
        {
          init: true,
          initParams: { status, ...paginationQuery },
        },
        classId!
      );
    }
  };

  const [activeHomeworks, isActiveHomeworksLoading, getActiveHomeworks] = getHomeworks('ACTIVE');
  const [waitingHomeworks, isWaitingHomeworksLoading, getWaitingHomeworks] = getHomeworks('WAITING');
  const [expiredHomeworks, isExpiredHomeworksLoading, getExpiredHomeworks] = getHomeworks('EXPIRED');

  const homeworkDrawerOnClose = () => {
    onRenderColumn();
    setHomeworkDrawerOpen(false);
  };

  const draftDrawerOnClose = () => {
    setDraftDrawerOpen(false);
  };

  const onRenderColumn = () => {
    getActiveHomeworks(undefined, { ...paginationQuery, status: 'ACTIVE' });
    getWaitingHomeworks(undefined, { ...paginationQuery, status: 'WAITING' });
    getExpiredHomeworks(undefined, { ...paginationQuery, status: 'EXPIRED' });
  };

  useEffect(() => {
    if (paginationQuery) {
      onRenderColumn();
    }
  }, [paginationQuery]);

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 3, name: '', levelIds: [] });
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const homeworkMap = {
    Aktif: { status: 'ACTIVE', fetch: getActiveHomeworks },
    Planlanmış: { status: 'WAITING', fetch: getWaitingHomeworks },
    Tamamlandı: { status: 'EXPIRED', fetch: getExpiredHomeworks },
  };

  useEffect(() => {
    const homeworkConfig = homeworkMap[title];
    if (homeworkConfig) {
      homeworkConfig.fetch(undefined, { ...paginationQuery, status: homeworkConfig.status });
    }
  }, [title]);

  const onFilterLoadMore = (title, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ page: 1, limit: 3, name: '', levelIds: [] });
      return;
    }
    setTitle(title);
    setPaginationQuery((prev) => ({
      ...prev,
      page: 1,
      limit: paginationQuery.limit! + 3,
    }));
  };

  const onDeleteHomework = async (homework) => {
    const response = await API.HOMEWORK.deleteHomeworkTeacher(homework.id);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      onRenderColumn();
    }
  };

  return (
    <div className="container mx-auto pt-1">
      <div className="flex justify-between">
        <div>
          <TeacherClassFilter onFilter={onFilter} filters={paginationQuery} />
        </div>
        <Button type="link" icon={<BookOutlined />} onClick={() => setDraftDrawerOpen(true)}>
          Taslakları görüntüle
        </Button>
      </div>

      <div className="grid grid-cols-3 gap-3 mt-2">
        <KanbanColumn
          title={<KanbanColumnTitle title="Aktif" count={activeHomeworks?.count} icon={<FileTextOutlined style={{ color: '#52C41A' }} />} />}
          isLoading={isActiveHomeworksLoading}
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          loadMore={
            <div>
              {paginationQuery.limit! < activeHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Aktif')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={activeHomeworks?.data?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          title={<KanbanColumnTitle title="Planlanmış" count={waitingHomeworks?.count} icon={<FileDoneOutlined style={{ color: '#faad14' }} />} />}
          isLoading={isWaitingHomeworksLoading}
          loadMore={
            <div>
              {paginationQuery.limit! < waitingHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Planlanmış')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={waitingHomeworks?.data?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>

        <KanbanColumn
          cardsStyle={{ height: 'calc(100vh - 350px)' }}
          title={<KanbanColumnTitle title="Tamamlandı" count={expiredHomeworks?.count} icon={<FileDoneOutlined style={{ color: '#1677FF' }} />} />}
          isLoading={isExpiredHomeworksLoading}
          loadMore={
            <div>
              {paginationQuery.limit! < waitingHomeworks?.count! && (
                <div className="flex justify-center items-center mt-auto">
                  <Button onClick={() => onFilterLoadMore('Tamamlandı')} type="link" icon={<SyncOutlined />}>
                    Daha fazla yükle
                  </Button>
                </div>
              )}
            </div>
          }
          cards={expiredHomeworks?.data?.map((homework) => {
            return {
              title: homework.name,
              content: <TaskCardContent homework={homework} />,
              action: (
                <ActionDropdown
                  isViewAssignment={true}
                  onViewAssignment={() => navigate('/' + LayoutEnums.TEACHER + '/homework-detail' + location.search + '&class_homework_id=' + homework.id)}
                  isEditAssignment={true}
                  onEditAssignment={() => {
                    setHomeworkData(homework);
                    setHomeworkDrawerOpen(true);
                  }}
                  isDeleteAssignment={true}
                  onDeleteAssignment={() => onDeleteHomework(homework)}
                  isDelete={false}
                  isEdit={false}
                  data={{}}
                />
              ),
              footer: (
                <div className="flex justify-between">
                  <div>
                    <CalendarOutlined />
                    <span className="ml-1">
                      {convertDate(homework.startDate)} - {convertDate(homework.dueDate)}
                    </span>
                  </div>
                  <div>
                    <PaperClipOutlined />
                    <span className="ml-2">{homework.files?.length || 0}</span>
                  </div>
                </div>
              ),
            };
          })}
        ></KanbanColumn>
      </div>
      <DraftViewDrawer draftDrawerOpen={draftDrawerOpen} draftDrawerOnClose={draftDrawerOnClose} onRenderColumn={onRenderColumn} />
      <HomeworkEditDrawer homeworkDrawerOpen={homeworkDrawerOpen} homeworkDrawerOnClose={homeworkDrawerOnClose} homeworkData={homeworkData!} />
    </div>
  );
};

TeacherClassHomeWork.roles = [Role.ADMIN];
TeacherClassHomeWork.path = '/' + LayoutEnums.TEACHER + '/class-homework';
TeacherClassHomeWork.title = 'Ödev İşlemleri';
TeacherClassHomeWork.group = 'flat';
TeacherClassHomeWork.layouts = [LayoutEnums.TEACHER];
TeacherClassHomeWork.icon = <IoDocumentTextOutline size={'15'} />;
