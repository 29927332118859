import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { CreateSessionDefinitions } from '@platform/platform_components';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { PaginationQuery, SessionDefinitionsCreateModel, SessionDefinitionsModel } from '@shared/models';
import { sortFields } from '@shared/utils';
import type { TableColumnsType } from 'antd';
import { Button, Drawer, Input, InputRef, notification, Table, TableColumnType, Tag } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import { TbFilter } from 'react-icons/tb';

export const SessionDefinitions = () => {
  useAccessableSeachParams();

  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectData, setSelectData] = useState<SessionDefinitionsModel>({});
  const [sessionData, loading, getSessionData] = API.SESSION_DEFINITIONS.getSessionData({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getSessionData(undefined, paginationQuery);
  }, [paginationQuery]);

  const getColumnSearchProps = (): TableColumnType<SessionDefinitionsModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Filtrele"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          className="block mb-2"
        />
        <div className="flex justify-around gap-3">
          <Button type="primary" onClick={() => confirm()} icon={<TbFilter />} size="small">
            Filtrele
          </Button>

          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            Kapat
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => <TbFilter style={{ color: filtered ? '#1677ff' : undefined, fontSize: '120%' }} />,
  });

  const columns: TableColumnsType<SessionDefinitionsModel> = [
    {
      title: 'Seans Adı',
      dataIndex: 'name',
      align: 'center',
      filterSearch: true,
      sorter: true,
      ...getColumnSearchProps(),
    },
    {
      title: 'Seans Saat Aralığı',
      dataIndex: 'times',
      render: (_, record: SessionDefinitionsModel) => {
        return (
          <>
            {record.seanceItemsStr &&
              record.seanceItemsStr.map((time: string, index) => (
                <Tag key={index} className="ml-2 mb-1">
                  {time}
                </Tag>
              ))}
          </>
        );
      },
    },
    {
      title: 'Toplam Ders Süresi',
      dataIndex: 'durationInMinutes',
      render: (text: string) => <div className="text-success italic">{text} dakika</div>,
    },
    {
      render: (_, record) => (
        <div className="text-center">
          <ActionDropdown
            data={record}
            onDelete={({ id }) => {
              deleteData(id);
            }}
            onEdit={(data) => {
              selectedData(data);
              updateData();
            }}
          />
        </div>
      ),
    },
  ];

  //Drawer show
  const showDrawer = () => {
    setOpen(true);
    setIsUpdate(false);
    setSelectData({});
  };

  //Drawer onClose
  const onClose = () => {
    setOpen(false);
    setIsUpdate(false);
  };

  const deleteData = async (id: string) => {
    try {
      await API.SESSION_DEFINITIONS.delete(id);
      notification.success({ message: 'Seans başarıyla silinmiştir!' });
      setPaginationQuery((prev) => ({ ...prev, page: 1 }));
      onClose();
    } catch (error) {}
  };

  const createOrUpdate = async (value: SessionDefinitionsCreateModel) => {
    if (!isUpdate) {
      const response = await API.SESSION_DEFINITIONS.create(value);
      if (response.ok) {
        notification.success({ message: 'Seans oluşturuldu' });
      }
    } else {
      const response = await API.SESSION_DEFINITIONS.update(value);
      if (response.ok) {
        notification.success({ message: 'Seans güncellendi' });
      }
    }
    getSessionData(undefined, paginationQuery);
    onClose();
  };

  const updateData = () => {
    setOpen(true);
    setIsUpdate(true);
  };

  const selectedData = (value) => {
    setSelectData(value);
  };

  const title = (
    <div className="flex justify-between">
      <div></div>
      <Button type="dashed" icon={<PlusCircleOutlined />} onClick={showDrawer}>
        Yeni seans oluştur
      </Button>
    </div>
  );

  return (
    <div>
      <div className="flex justify-end">
        <Drawer title={isUpdate ? 'Seans Güncelle' : 'Yeni Seans Ekle'} onClose={onClose} open={open}>
          <CreateSessionDefinitions
            isUpdate={isUpdate}
            selectData={selectData}
            onSave={(v) => {
              createOrUpdate(v);
            }}
          />
        </Drawer>
      </div>

      <div>
        <Table
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            showPrevNextJumpers: true,
            current: paginationQuery.page,
            total: sessionData?.count,
            pageSize: paginationQuery.limit,
            position: ['bottomCenter'],
          }}
          loading={loading}
          className="mt-2"
          columns={columns}
          dataSource={sessionData?.data}
          bordered
          title={() => title}
          onChange={(pagination, filters, sorter) => {
            const { current, pageSize } = pagination;
            let newQuery = {
              ...paginationQuery,
              page: current,
              limit: pageSize,
              orders: sortFields(paginationQuery, sorter as SorterResult<any>),
              ...filters,
            };
            Object.keys(newQuery).forEach((key) => {
              if (key === 'orders') {
                return;
              }
              if (Array.isArray(newQuery[key]) && newQuery[key].length === 1) {
                newQuery[key] = newQuery[key][0];
              }
            });

            setPaginationQuery(newQuery);
          }}
        />
      </div>
    </div>
  );
};

SessionDefinitions.path = '/lms/session-definitions';
SessionDefinitions.title = 'Seans Tanımları';
SessionDefinitions.group = 'definitions';
SessionDefinitions.roles = [Role.ADMIN];
SessionDefinitions.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
SessionDefinitions.permissions = ['branch.lms.seance.view'];
